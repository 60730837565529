import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./API";
import { IPaginationQuery, ISwitchCompanyData } from "./responce_types";

export const companiesApi = createApi({
  reducerPath: "companiesApi",
  tagTypes: ["Companies"],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_KEY,
  }),
  endpoints: (builder) => ({
    switchCompany: builder.mutation<any, ISwitchCompanyData>({
      query: (body) => ({
        url: `/users/switch_company`,
        data: body,
        method: "PUT",
      }),
      invalidatesTags: ["Companies"],
    }),
  }),
});

export const {
  useSwitchCompanyMutation,
} = companiesApi;
