import React from 'react'
import { Tree } from 'react-organizational-chart';
import { HierarchyItemTypes, IHierarchyData } from '../../hierarchy-hooks';
import HierarchyRoleItem from './HierarchyRoleItem/hierarchyRoleItem';
import useHierarchyTreeHooks from './hierarchyTree-hooks';
import HierarchySectionItem from './HierarchySectionItem/hierarchySectionItem';

interface IProps {
    data: IHierarchyData,
    onCreateNew: (parentId?: string) => void,
    onDelete: (id: string) => void,
    handleNewTabOpen: (id: string) => void,
}

const HierarchyTree: React.FC<IProps> = (props) => {
    const { data, onCreateNew, onDelete, handleNewTabOpen} = props;
    const { createHierarchyTree } = useHierarchyTreeHooks();
    if (data.children.length > 0) {
        return (
            <Tree
                label={data.type === HierarchyItemTypes.ROLE
                    ?
                    <HierarchyRoleItem data={data} onCreateNew={onCreateNew} onDelete={onDelete} handleNewTabOpen={handleNewTabOpen} />
                    :
                    <HierarchySectionItem data={data} onCreateNew={onCreateNew} onDelete={onDelete} handleNewTabOpen={handleNewTabOpen} />
                }
                lineColor='#e68600'
                lineWidth='3px'
            >
                {createHierarchyTree(data, onCreateNew, onDelete, handleNewTabOpen, data.sectionColor!)}
            </Tree>
        )
    } else {
        return (
            <>
                {data.type === HierarchyItemTypes.ROLE
                    ?
                    <HierarchyRoleItem data={data} onCreateNew={onCreateNew} onDelete={onDelete} handleNewTabOpen={handleNewTabOpen} />
                    :
                    <HierarchySectionItem data={data} onCreateNew={onCreateNew} onDelete={onDelete} handleNewTabOpen={handleNewTabOpen} />
                }
            </>
        )
    }
};

export default HierarchyTree
