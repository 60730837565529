import React from 'react';
import { CustomModal, Loading } from 'Components';
import {
  Chat,
  Window,
  Thread,
  Channel,
  ChannelList,
  MessageList,
  MessageInput,
  ChannelHeader,
  ChannelListContextProvider,
  ChannelPreviewMessenger,
  InfiniteScroll,
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/v2/index.css';
import { useCustomModalHooks } from 'Components/CustomModal/customModal.hooks';
import ResponsiveChat from './ResponsiveChat/responsiveChat';
import useChatHooks from 'Components/GroupChat/chat.hooks';
import { useGeneralHooks } from 'Pages/general.hooks';
import ChatUserAvatar from './Users/chatUserAvatar';
import plusIcon from 'Assets/Icons/plus.svg';
import styles from './chat.module.scss';
import '../../App/App.scss';

const CustomChat: React.FC = () => {
  const { t, largeScreen } = useGeneralHooks();
  const { openModal, handleOpen, handleClose } = useCustomModalHooks();
  const {
    filters,
    onClose,
    channel,
    newClient,
    onChannelSelect,
    CurrentModalComponent,
    channelFilterFunction,
  } = useChatHooks(handleClose);
  if (!newClient) return <Loading />;

  return (
    <>
      {largeScreen ? (
        // @ts-ignore
        <ChannelListContextProvider value={{ channel: channel, newClient }}>
          <div className={styles.container}>
            <div className={styles['chat-page']}>
              <Chat client={newClient}>
                <div className={styles['chat-container']}>
                  <div className={styles['chat-list__container']}>
                    <ChannelList
                      channelRenderFilterFn={channelFilterFunction}
                      filters={filters}
                      Paginator={InfiniteScroll}
                      Preview={(props) => (
                        <ChannelPreviewMessenger
                          {...props}
                          active={props.channel.id === channel?.id}
                          onSelect={() => onChannelSelect(props)}
                          Avatar={() => (
                            <ChatUserAvatar
                              key={props?.channel?.cid!}
                              {...props}
                            />
                          )}
                        />
                      )}
                    />
                    <div
                      className={styles['create-group-chat-btn__cont']}
                      onClick={handleOpen}
                    >
                      <img
                        src={plusIcon}
                        alt='plusIcon'
                        className={styles['plus-icon']}
                      />
                      <span>{t('Chat.cretae_group_chat')}</span>
                    </div>
                  </div>
                  <div className={styles['divider']}></div>
                  <CustomModal
                    buttonTitle={t('Aplication_Page.App_Status.Button')}
                    children={CurrentModalComponent}
                    title={t('Groups.Choose_Button')}
                    handleClose={onClose}
                    open={openModal}
                    dialogClassname={'changeStatusDialog'}
                  />
                  <Channel channel={channel}>
                    <Window>
                      <ChannelHeader />
                      <MessageList
                        messageActions={[
                          'delete',
                          'edit',
                          'pin',
                          'quote',
                          'react',
                          'reply',
                        ]}
                        hideDeletedMessages
                        // @ts-ignore
                        markReadOnScrolledToBottom
                        onlySenderCanEdit
                      />
                      <MessageInput />
                    </Window>
                    <Thread />
                  </Channel>
                </div>
              </Chat>
            </div>
          </div>
        </ChannelListContextProvider>
      ) : (
        <ResponsiveChat />
      )}
    </>
  );
};

export default CustomChat;
