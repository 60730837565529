import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { t } from 'i18next'
import { v4 as uuidv4 } from 'uuid';
import { useGetEmployeesFullListQuery } from "Services/employeesApi";
import { HierarchyItemTypes, HierarchyModalTypes, IHierachyModal, IHierarchyData } from "../../hierarchy-hooks";
import { AutoComplete, InputField } from "Components/AuthComponents/AuthInputs";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import UploadImage from "../UploadImage/uploadImage";
import ColorPicker from "../ColorPicker/colorPicker";
import { Button } from "Components";
import { ButtonTypes } from "Components/interfaces";
import styles from './hierarchyModal.module.scss'

export interface IHierarchyFormValues {
    role: string,
    employeeId: any,
    parentPaths?: string,
    itemType?: HierarchyItemTypes,
    sectionName?: string,
    sectionImg?: string,
    sectionResp?: any,
    sectionColor?: string
};

interface IArgs {
    modal: IHierachyModal,
    onClose: () => void,
    hierarchyData: IHierarchyData,
    setHierarchyData: Dispatch<SetStateAction<IHierarchyData>>
}

const useHierarchyModalHooks = (args: IArgs) => {
    const { modal, hierarchyData, onClose, setHierarchyData } = args;
    const { register, control, handleSubmit, setValue, reset, formState: { errors } } = useForm<IHierarchyFormValues>({ mode: 'all' });
    const [formType, setFormType] = useState<HierarchyItemTypes | undefined>(undefined);
    const { data: Employees } = useGetEmployeesFullListQuery();

    useEffect(() => {
        reset();
        setFormType(undefined);
    }, [modal]);

    const createEmployeesAsOptions = useCallback(() => {
        const data = Employees?.result!.map((el) => {
            return {
                name: `${el.user.name} ${el.user.surname}`,
                id: String(el.userId)
            }
        })
        return data
    }, [Employees]);


    const addChildren = async (obj: IHierarchyData, parentId: string, payload: IHierarchyData) => {
        if (!obj) {
            return;
        }

        if (!obj.children) {
            obj.children = [];
        }

        if (obj.id === parentId) {
            //@ts-ignore
            obj.children.push(payload);
            return;
        }

        if (obj.children && obj.children.length > 0) {
            obj.children.forEach(child => {
                addChildren(child, parentId, payload);
            });
        }
    }
    const onStart: SubmitHandler<IHierarchyFormValues> = (values) => {
        let payload: IHierarchyData
        if (formType === HierarchyItemTypes.ROLE) {
            payload = {
                id: uuidv4(),
                orgName: values.role,
                employee: values.employeeId.id!,
                type: formType,
                children: []
            }
        } else if (formType === HierarchyItemTypes.SECTION) {
            payload = {
                id: uuidv4(),
                sectionName: values.sectionName!,
                sectionImg: values.sectionImg!,
                sectionRespPersonId: values.sectionResp.id!,
                sectionColor: values.sectionColor!,
                type: formType,
                children: []
            }
        }
        localStorage.setItem('hierarchyData',JSON.stringify(payload!))
        setHierarchyData(payload!)
        onClose()
    };

    const onCreateItem: SubmitHandler<IHierarchyFormValues> = async (values) => {
        const data = { ...JSON.parse(localStorage.getItem('hierarchyData')!) };
        let payload: IHierarchyData
        if (formType === HierarchyItemTypes.ROLE) {
            payload = {
                id: uuidv4(),
                orgName: values.role,
                employee: values.employeeId.id!,
                type: formType,
                children: []
            }
        } else if (formType === HierarchyItemTypes.SECTION) {
            payload = {
                id: uuidv4(),
                sectionName: values.sectionName!,
                sectionImg: values.sectionImg!,
                sectionRespPersonId: values.sectionResp.id!,
                sectionColor: values.sectionColor!,
                type: formType,
                children: []
            }
        }
        await addChildren(data, modal.parentId!, payload!);
        localStorage.setItem('hierarchyData',JSON.stringify(data!))
        setHierarchyData(data)
        onClose()
    };

    const onDeleteItem = (id: string) => {
        const deleteNodeById = (data: IHierarchyData, id: string): boolean => {
            if (!data.children) return false;
            const index = data.children.findIndex(child => child.id === id);
            if (index !== -1) {
                data.children.splice(index, 1);
                return true;
            }

            return data.children.some(child => deleteNodeById(child, id));
        };

        const data = { ...hierarchyData };
        if (data.id === id) {
            const init: IHierarchyData = {
                orgName: undefined,
                employee: undefined,
                children: [],
            }
            localStorage.setItem('hierarchyData',JSON.stringify(init))
            setHierarchyData(init);
            onClose();
            return;
        }

        const isDeleted = deleteNodeById(data, id);
        if (isDeleted) {
            localStorage.setItem('hierarchyData',JSON.stringify(data))
            setHierarchyData(data);
        } else {
            console.warn(`Node with id ${id} not found`);
        }

        onClose();
    }


    const MODAL_INNER = {
        [HierarchyModalTypes.START]: {
            title: t('Hierarchy.Top_Position'),
            body: (
                <div className={styles.body}>
                    <FormControl>
                        <FormLabel id='demo-radio-buttons-group-label'>
                            {t('Hierarchy.FT_Label')}
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby='demo-radio-buttons-group-label'
                            name='itemType'
                            onChange={(val) => {
                                setValue(
                                    'itemType',
                                    val.target.value as HierarchyItemTypes
                                )
                                setFormType(val.target.value as HierarchyItemTypes)
                            }
                            }
                        >
                            <FormControlLabel
                                value={HierarchyItemTypes.ROLE}
                                control={<Radio />}
                                label={t('Hierarchy.Role')}
                            />
                            <FormControlLabel
                                value={HierarchyItemTypes.SECTION}
                                control={<Radio />}
                                label={t('Hierarchy.Section')}
                            />
                        </RadioGroup>
                    </FormControl>
                    {formType
                        &&
                        (
                            formType === HierarchyItemTypes.ROLE
                                ?
                                <>
                                    <InputField
                                        register={register}
                                        registerName='role'
                                        label={t('Hierarchy.Top_Position_Name')}
                                        placeholder={t('Hierarchy.PN_Placeholder')}
                                        required
                                        error={errors.role}
                                    />
                                    <Controller
                                        control={control}
                                        name='employeeId'
                                        rules={{
                                            required: t('Input_Errors.Required'),
                                        }}
                                        render={({ field: { onChange, name, value } }) => {
                                            return (
                                                <AutoComplete
                                                    value={value}
                                                    name={name}
                                                    onChange={onChange}
                                                    id='parentId'
                                                    data={createEmployeesAsOptions()}
                                                    label={t('Aplication_Page.Desmissal.Employee_placeholder')}
                                                    placeholder={t('Groups.Choose_Button')}
                                                    error={errors.employeeId}
                                                />
                                            );
                                        }}
                                    />
                                </>
                                :
                                <>
                                    <InputField
                                        register={register}
                                        registerName='sectionName'
                                        label={t('Hierarchy.Section_Name')}
                                        placeholder={t('Hierarchy.PN_Placeholder')}
                                        required
                                        error={errors.sectionName}
                                    />
                                    <Controller
                                        control={control}
                                        name='sectionResp'
                                        rules={{
                                            required: t('Input_Errors.Required'),
                                        }}
                                        render={({ field: { onChange, name, value } }) => {
                                            return (
                                                <AutoComplete
                                                    value={value}
                                                    name={name}
                                                    onChange={onChange}
                                                    id='parentId'
                                                    data={createEmployeesAsOptions()}
                                                    label={t('Hierarchy.Responsible')}
                                                    placeholder={t('Groups.Choose_Button')}
                                                    error={errors.sectionResp}
                                                />
                                            );
                                        }}
                                    />
                                    <UploadImage setValue={setValue} registerName="sectionImg" />
                                    <ColorPicker setValue={setValue} registerName="sectionColor" />
                                </>

                        )
                    }

                    {formType
                        &&
                        <div className={styles.buttonsRow}>
                            <Button
                                buttonType={ButtonTypes.WithoutBg}
                                title={t('Employee_History.Cancele')}
                                buttonStyle={styles.button}
                                onClick={onClose}
                            />
                            <Button
                                buttonType={ButtonTypes.Primery}
                                title={t('Groups.Create')}
                                buttonStyle={styles.button}
                                type='submit'
                            />
                        </div>}
                </div>
            ),
            onSubmit: handleSubmit(onStart)
        },
        [HierarchyModalTypes.ADD]: {
            title: t('Hierarchy.New_Position'),
            body: (
                <div className={styles.body}>
                    <FormControl>
                        <FormLabel id='demo-radio-buttons-group-label'>
                            {t('Hierarchy.FT_Label')}
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby='demo-radio-buttons-group-label'
                            name='itemType'
                            onChange={(val) => {
                                setValue(
                                    'itemType',
                                    val.target.value as HierarchyItemTypes
                                )
                                setFormType(val.target.value as HierarchyItemTypes)
                            }
                            }
                        >
                            <FormControlLabel
                                value={HierarchyItemTypes.ROLE}
                                control={<Radio />}
                                label={t('Hierarchy.Role')}
                            />
                            <FormControlLabel
                                value={HierarchyItemTypes.SECTION}
                                control={<Radio />}
                                label={t('Hierarchy.Section')}
                            />
                        </RadioGroup>
                    </FormControl>
                    {formType
                        &&
                        (
                            formType === HierarchyItemTypes.ROLE
                                ?
                                <>
                                    <InputField
                                        register={register}
                                        registerName='role'
                                        label={t('Hierarchy.NP_Name')}
                                        placeholder={t('Hierarchy.PN_Placeholder')}
                                        required
                                        error={errors.role}
                                    />
                                    <Controller
                                        control={control}
                                        name='employeeId'
                                        rules={{
                                            required: t('Input_Errors.Required'),
                                        }}
                                        render={({ field: { onChange, name, value } }) => {
                                            return (
                                                <AutoComplete
                                                    value={value}
                                                    name={name}
                                                    onChange={onChange}
                                                    id='parentId'
                                                    data={createEmployeesAsOptions()}
                                                    label={t('Aplication_Page.Desmissal.Employee_placeholder')}
                                                    placeholder={t('Groups.Choose_Button')}
                                                    error={errors.employeeId}
                                                />
                                            );
                                        }}
                                    />
                                </>
                                :
                                <>
                                    <InputField
                                        register={register}
                                        registerName='sectionName'
                                        label={t('Hierarchy.Section_Name')}
                                        placeholder={t('Hierarchy.PN_Placeholder')}
                                        required
                                        error={errors.sectionName}
                                    />
                                    <Controller
                                        control={control}
                                        name='sectionResp'
                                        rules={{
                                            required: t('Input_Errors.Required'),
                                        }}
                                        render={({ field: { onChange, name, value } }) => {
                                            return (
                                                <AutoComplete
                                                    value={value}
                                                    name={name}
                                                    onChange={onChange}
                                                    id='parentId'
                                                    data={createEmployeesAsOptions()}
                                                    label={t('Hierarchy.Responsible')}
                                                    placeholder={t('Groups.Choose_Button')}
                                                    error={errors.sectionResp}
                                                />
                                            );
                                        }}
                                    />
                                    <UploadImage setValue={setValue} registerName="sectionImg" />
                                    <ColorPicker setValue={setValue} registerName="sectionColor" />
                                </>

                        )
                    }

                    {formType
                        &&
                        <div className={styles.buttonsRow}>
                            <Button
                                buttonType={ButtonTypes.WithoutBg}
                                title={t('Employee_History.Cancele')}
                                buttonStyle={styles.button}
                                onClick={onClose}
                            />
                            <Button
                                buttonType={ButtonTypes.Primery}
                                title={t('Groups.Create')}
                                buttonStyle={styles.button}
                                type='submit'
                            />
                        </div>}
                </div>
            ),
            onSubmit: handleSubmit(onCreateItem)
        },
        [HierarchyModalTypes.DELETE]: {
            title: t('Hierarchy.Delete'),
            body: (
                <div className={styles.body}>
                    <div className={styles.description}>
                        {t('Hierarchy.Delete_Description')}
                    </div>
                    <div className={styles.buttonsRow}>
                        <Button
                            buttonType={ButtonTypes.WithoutBg}
                            title={t('Employee_History.Cancele')}
                            buttonStyle={styles.button}
                            onClick={onClose}
                        />
                        <Button
                            buttonType={ButtonTypes.Primery}
                            title={t('Hierarchy.Confirm')}
                            buttonStyle={styles.button}
                            type='submit'
                        />
                    </div>
                </div>
            ),
            onSubmit: handleSubmit(values => onDeleteItem(modal.id!))
        }
    }

    return {
        MODAL_INNER,
        handleSubmit
    }
};

export default useHierarchyModalHooks