import ApplicationDetailHistoryList from 'Components/ApplicationDetailHistory/applicationDetailHistoryList';
import { useAppPathsHooks } from 'Components/BreadCrumb/breadCrumb.hooks';
import { ApplicationDetailInfo, CustomModal, Header, Loading } from 'Components';
import { ChangeStatusModal } from 'Components/Application/ApplicationItems';
import { SearchInput } from 'Components/AuthComponents/AuthInputs';
import BreadCrumbs from 'Components/BreadCrumb/breadCrumbs';
import { IVacationByIdResp } from 'Services/responce_types';
import useAbsenceDetailHooks from './absenceDetail.hooks';
import { HeaderTypes } from 'Components/interfaces';
import { useGeneralHooks } from 'Pages/general.hooks';
import styles from './absenceDetail.module.scss';

const AbsenceDetail:React.FC = () => {
  const {
    absenceByIdInfo,
    renderItemData,
    isFetching,
    openModal,
    control,
    errors,
    onSubmit,
    register,
    onClose,
    handleSubmit,
    statuses } = useAbsenceDetailHooks();
  const { largeScreen, navigate, t } = useGeneralHooks();
  const { pathsToRender } = useAppPathsHooks();  

  return (
    <>
      {!largeScreen && (
        <Header
          type={HeaderTypes.Navigation}
          navTitle={t('Aplication_Page.Absence.Title')}
          iconOnClick={() => navigate(-1)}
        />
      )}
      <div className='page'>
        {isFetching ? (
          <Loading />
        ) : (
          <>
            {largeScreen && (
              <div className={styles['breadCrumbBox']}>
                <BreadCrumbs data={pathsToRender} />
                <SearchInput />
              </div>
            )}
            <div className={styles['content']}>
              <div className={styles['absence-detail-page__title']}>
                {t('Aplication_Page.Absence.Title')}
              </div>
              <ApplicationDetailInfo data={renderItemData} />
              {!largeScreen && (
                <div className={styles['absence-detail-page__history-title']}>
                  {t('Aplication_Page.Application_History')}
                </div>
              )}
              <ApplicationDetailHistoryList data={absenceByIdInfo as IVacationByIdResp} />
            </div>
            <div>
              <CustomModal
                onClick={handleSubmit(onSubmit)}
                buttonTitle={t('Aplication_Page.App_Status.Button')}
                children={<ChangeStatusModal control={control} errors={errors} register={register} statuses={statuses} />}
                title={t('Aplication_Page.Vacation.Status')}
                handleClose={onClose}
                open={openModal}
                dialogClassname={'changeStatusDialog'}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AbsenceDetail;
