import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './API';
import {
  IEmployeeResp,
  IEmployeedResp,
  IPaginationQuery,
  IEmployeesFullList,
  IUserRespById,
  IAllUsersResp,
  IGetEmployeeAppHistoryData,
  IEmployeeAppHistoryResp,
  ICreateDirectoryData,
  IDirectoriesResponse,
  IEditDirectory,
  IEditOption,
  IAddOption,
  IEmpPropertyTypesResponse,
  IEmpPropertiesResponse,
  IEditProperty,
  IDirectoryResonse,
} from './responce_types';
import { FieldValues } from 'react-hook-form';
import { IEmployeeProperty, IMakeEmployeeFields } from 'Pages/interfaces';

export const employeesApi = createApi({
  reducerPath: 'employeesApi',
  tagTypes: ['Employees', 'Directories', 'Properties'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_KEY,
  }),
  endpoints: (builder) => ({
    getEmployees: builder.query<IEmployeedResp, IPaginationQuery>({
      query: ({ page, offset }) => ({
        url: `/employee/${page}/${offset}`,
        method: 'GET',
      }),
      providesTags: ['Employees'],
    }),
    getEmployeById: builder.query<IEmployeeResp, string>({
      query: (id) => ({
        url: `/employee/${id}`,
        method: 'GET',
      }),
      providesTags: ['Employees']
    }),
    getEmployeesFullList: builder.query<IEmployeesFullList, void>({
      query: () => ({
        url: `/employee/full_list`,
        method: 'GET',
      }),
      providesTags: ['Employees'],
    }),
    // any must be changed
    deleteEmployee: builder.mutation<any, number>({
      query: (id) => ({
        url: `/employee/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Employees'],
    }),
    getAllUsers: builder.query<IAllUsersResp, IPaginationQuery>({
      query: ({ page, offset }) => ({
        url: `/users/${offset}/${page}`,
        method: 'GET',
      }),
      providesTags: ['Employees'],
    }),
    getUserById: builder.query<IUserRespById, number | string>({
      query: (id) => ({
        url: `/user/${id}`,
        method: 'GET',
      }),
      providesTags: ['Employees'],
    }),
    // any must be changed
    makeEmployee: builder.mutation<any, IMakeEmployeeFields | FieldValues>({
      query: (data: any) => ({
        url: '/employee/',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: ['Employees']
    }),
    getEmployeeAppHistory: builder.query<IEmployeeAppHistoryResp, IGetEmployeeAppHistoryData>({
      query: ({ id, limit, data }) => ({
        url: `/users/app_history/${id}/${limit.offset}/${limit.page}`,
        method: 'GET',
        params: data
      }),
      providesTags: ['Employees'],
    }),
    getDirectoriesList: builder.query<IDirectoriesResponse, IPaginationQuery>({
      query: ({ limit, offset }) => ({
        url: `/directories/`,
        method: 'GET',
        params: {
          limit: limit,
          offset: offset
        }
      }),
      providesTags: ['Directories']
    }),
    getDirectories: builder.query<IDirectoriesResponse, void>({
      query: () => ({
        url: `/directories/all`,
        method: 'GET',
      }),
      providesTags: ['Directories']
    }),
    getDirectoryById: builder.query<IDirectoryResonse, string>({
      query: (id) => ({
        url: `/directories/${id}`,
        method: 'GET',
      }),
    }),

    createDirectory: builder.mutation<any, ICreateDirectoryData>({
      query: (data: ICreateDirectoryData) => ({
        url: '/directories/',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: ['Directories']
    }),
    deleteDirectory: builder.mutation<any, number>({
      query: (id) => ({
        url: `/directories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Directories'],
    }),
    deleteOption: builder.mutation<any, number>({
      query: (id) => ({
        url: `/directory_value/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Directories'],
    }),
    editDirectory: builder.mutation<any, IEditDirectory>({
      query: (data: IEditDirectory) => ({
        url: `/directories/${data.id}`,
        method: 'PUT',
        data: data.data,
      }),
      invalidatesTags: ['Directories']
    }),
    editOption: builder.mutation<any, IEditOption>({
      query: (data: IEditOption) => ({
        url: `/directory_value/${data.id}`,
        method: 'PUT',
        data: data.data,
      }),
      invalidatesTags: ['Directories']
    }),
    addOption: builder.mutation<any, IAddOption>({
      query: (data: IAddOption) => ({
        url: `/directory_value/`,
        method: 'POST',
        data: data,
      }),
      invalidatesTags: ['Directories']
    }),
    getEmpPropertyTypes: builder.query<IEmpPropertyTypesResponse, void>({
      query: () => ({
        url: `/property_type/`,
        method: 'GET',
      }),
    }),
    getEmpProperties: builder.query<IEmpPropertiesResponse, void>({
      query: () => ({
        url: `/property/`,
        method: 'GET',
      }),
      providesTags: ['Properties']
    }),
    getEmpPropertiesList: builder.query<IEmpPropertiesResponse, IPaginationQuery>({
      query: ({ limit, offset }) => ({
        url: `/property/`,
        method: 'GET',
        params: {
          limit: limit,
          offset: offset
        }
      }),
      providesTags: ['Properties']
    }),
    addEmpProperty: builder.mutation<any, IEmployeeProperty>({
      query: (data: IEmployeeProperty) => ({
        url: `/property/`,
        method: 'POST',
        data: data,
      }),
      invalidatesTags: ['Properties']
    }),
    editProperty: builder.mutation<any, IEditProperty>({
      query: (data: IEditProperty) => ({
        url: `/property/${data.id}`,
        method: 'PUT',
        data: data.data,
      }),
      invalidatesTags: ['Properties']
    }),
    deleteProperty: builder.mutation<any, string>({
      query: (id) => ({
        url: `/property/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Properties'],
    }),
  }),

});

export const {
  useGetEmployeesQuery,
  useGetEmployeByIdQuery,
  useGetUserByIdQuery,
  useGetAllUsersQuery,
  useGetEmployeesFullListQuery,
  useDeleteEmployeeMutation,
  useMakeEmployeeMutation,
  useGetEmployeeAppHistoryQuery,
  useGetDirectoriesListQuery,
  useGetDirectoriesQuery,
  useGetDirectoryByIdQuery,
  useCreateDirectoryMutation,
  useDeleteDirectoryMutation,
  useDeleteOptionMutation,
  useEditDirectoryMutation,
  useEditOptionMutation,
  useAddOptionMutation,
  useGetEmpPropertyTypesQuery,
  useGetEmpPropertiesQuery,
  useGetEmpPropertiesListQuery,
  useAddEmpPropertyMutation,
  useEditPropertyMutation,
  useDeletePropertyMutation
} = employeesApi;
