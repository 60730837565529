import React from 'react';
import { useTranslation } from 'react-i18next';
import { MuiOtpInput } from 'mui-one-time-password-input';
import Button from 'Components/Button/button';
import AuthFormTitle from '../AuthForms/authFormTitle';
import BottomLink from '../BottomLink/bottomLink';
import styles from './forgetPassword.module.scss';
import { ButtonTypes } from 'Components/interfaces';

interface IOtpProps {
  otp: string;
  otpChange: (newValue: string) => void;
  sendCode: () => void;
  userEmail: string;
  sendEmail: () => void
}

const OtpInput: React.FC<IOtpProps> = (props) => {
  const { otp, userEmail, otpChange, sendCode, sendEmail } = props;
  const { t } = useTranslation();

  const validateChar = (value: any) => {
    return /^\d+$/.test(value);
  };

  return (
    <>
      <div className={styles.formContainer}>
        <AuthFormTitle
          email={userEmail}
          title={t('Forget_Password.Second_page.Title')}
          description={t('Forget_Password.Second_page.Description')}
        />
        <form className={`${styles.forgetPasswordForm} ${styles.otpForm}`}>
          <MuiOtpInput
            value={otp}
            onChange={otpChange}
            autoFocus={true}
            validateChar={validateChar}
          />
          <Button
            buttonType={
              otp.length === 4 ? ButtonTypes.Primery : ButtonTypes.Disabled
            }
            title={t('Forget_Password.Second_page.Send')}
            type='button'
            onClick={() => {
              sendCode();
            }}
            disable={otp.length !== 4}
          />
        </form>
      </div>
      <div className={styles.bottomBox}>
        <BottomLink
          text={t('Forget_Password.Second_page.otpText')}
          linkTitle={t('Forget_Password.Second_page.resend')}
          onClick={() => sendEmail()}
        />
      </div>
    </>
  );
};
export default OtpInput;
