import React, { Dispatch, SetStateAction } from 'react';
import { NavigateFunction } from 'react-router';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { FormTypes, IEmployeeProperty, IEmployeeTPropartyTemplate, IPropertyModal } from 'Pages/interfaces';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Button, CustomModal, Header, Loading, MobileNavDrawer } from 'Components';
import { ButtonTypes, EmployeePropertyTypes, HeaderTypes } from 'Components/interfaces';
import NoData from 'Components/NoData/noData';
import CustomPagination from 'Components/Pagination/pagination';
import useMobileNavDrawerHooks from 'Components/MobileNavDrawer/mobileNavDrawer-hooks';
import PropertyTypesDrawer from '../Drawer/propertyTypesDrawer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BlueTrashSVG } from 'Assets';
import styles from '../employeeProperties.module.scss';

interface IProps {
    properties: IEmployeeProperty[],
    total: number,
    activePage:number,
    setActivePage:Dispatch<SetStateAction<number>>,
    loading: boolean,
    expanded: string | boolean,
    currentTemplate: IEmployeeProperty,
    modal: IPropertyModal,
    DeleteLoading: boolean,
    createTemlateConfigForm: any,
    propertyTypes: IEmployeeTPropartyTemplate[],
    checkedType: number | undefined
    openDrawer: boolean,
    isFormHidden:boolean,
    onCloseModal: () => void,
    onOpenModal: (title: string, id: string) => void,
    handleAccordion: (panel: string) => (event: React.SyntheticEvent<Element, Event>, isExpanded: boolean) => void,
    onTemplateDelete: (id: string) => Promise<void>,
    onTypeSelect: (type: EmployeePropertyTypes, index: number) => void,
    onOpenDrawer: () => void,
    onCloseDrawer: () => void,
    navigate: NavigateFunction
}

const EmployeePropertiesMobile: React.FC<IProps> = (props) => {
    const {
        properties,
        total,
        activePage,
        loading,
        expanded,
        modal,
        DeleteLoading,
        createTemlateConfigForm,
        currentTemplate,
        propertyTypes,
        checkedType,
        openDrawer,
        isFormHidden,
        setActivePage,
        handleAccordion,
        onOpenModal,
        onCloseModal,
        onTemplateDelete,
        onTypeSelect,
        onOpenDrawer,
        onCloseDrawer,
        navigate
    } = props;
    const { open, onDrawerClose } = useMobileNavDrawerHooks();

    return (
        <div className={styles.page}>
            <div className={styles.mobileContainer}>
                <div>
                    <Header
                        type={HeaderTypes.Navigation}
                        navTitle={t('Employee_Settings.Employees')}
                        iconOnClick={() => navigate(-1)}
                    />
                    <MobileNavDrawer open={open} onClose={onDrawerClose} />
                </div>
                <div className={styles.body}>
                    <div className={styles.header}>
                        <div className={styles.title}>
                            {t(`Employee_Settings.Employee_Info`)}
                        </div>
                        <div className={styles.describtiom}>
                            {t(`Employee_Settings.Describtion`)}
                        </div>
                    </div>
                    {loading
                        ?
                        <div>
                            <Loading />
                        </div>
                        :
                        (
                            total > 0
                                ?
                                <div className={styles.configList}>
                                    {properties.map((property, index) => {
                                        return (
                                            <Accordion
                                                key={property.key! + index}
                                                expanded={expanded === property.title!}
                                                onChange={handleAccordion(property.title!)}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={property.title}
                                                    id={property.title}
                                                >
                                                    <div className={styles.summary} >
                                                        <div className={styles.directoryTittle}>{property.title}</div>
                                                        <div className={styles.inputIcon}>
                                                            <img
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    onOpenModal(property.title!, property._id!)
                                                                }}
                                                                src={BlueTrashSVG}
                                                                alt='deleteIcon'
                                                                className={styles.inputIcon}
                                                            />
                                                        </div>
                                                    </div>

                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {expanded === property.title! && createTemlateConfigForm(property, FormTypes.EDIT)}
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    })}
                                    {currentTemplate.type && createTemlateConfigForm(currentTemplate, FormTypes.CREATE)}
                                    <CustomModal
                                        open={modal.open}
                                        title={t('Employee_Settings.Delete_Template')}
                                        handleClose={onCloseModal}
                                        dialogClassname={"dyrectoryModal"}
                                        loading={DeleteLoading}
                                        children={
                                            <div className={styles.modalInner}>
                                                <div className={styles.description}>
                                                    <Trans
                                                        components={{ span: <span className={styles.styledText} /> }}
                                                    >
                                                        {t('Employee_Settings.Delete_Template_Text', { templateName: modal.title! })}
                                                    </Trans>
                                                </div>
                                                <div className={styles.buttonRow}>
                                                    <Button
                                                        buttonType={ButtonTypes.WithoutBg}
                                                        buttonStyle={styles.button}
                                                        title={t('Employee_History.Cancele')}
                                                    />
                                                    <Button
                                                        buttonType={ButtonTypes.Primery}
                                                        buttonStyle={styles.button}
                                                        title={t('Edit_User.Change')}
                                                        onClick={() => onTemplateDelete(modal.id!)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                    <div className={styles.createButtonRow}>
                                        {isFormHidden
                                            &&
                                            <Button
                                                 onClick={onOpenDrawer}
                                                title={t('Groups.Create')}
                                                buttonType={ButtonTypes.Primery}
                                                buttonStyle={styles.createButton}
                                            />
                                        }
                                    </div>
                                    {total! > 10 && isFormHidden 
                                    &&
                                     (
                                        <div className={styles.paginationBox}>
                                <CustomPagination
                                    count={Math.ceil(
                                        total! / 10
                                    )}
                                    page={activePage}
                                    onChange={(_, page) => {
                                        setActivePage(page);
                                        window.scrollTo(0, 0);
                                    }}
                                />
                                </div>
                            )}  
                                                          </div>
                                :
                                (currentTemplate.type
                                    ?
                                    createTemlateConfigForm(currentTemplate, FormTypes.CREATE)
                                    :
                                    <NoData
                                        withButton={false}
                                        title={t('Employee_Settings.No_Template')}
                                    />
                                )
                        )
                    }

                <PropertyTypesDrawer
                    open={openDrawer}
                    checkedType={checkedType}
                    propertyTypes={propertyTypes}
                    onClose={onCloseDrawer}
                    onTypeSelect={onTypeSelect}
                />
                </div>
            </div>
        </div>
    )
}

export default EmployeePropertiesMobile
