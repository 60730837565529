import React, { Dispatch, SetStateAction } from 'react';
import { IHierachyModal, IHierarchyData } from '../../hierarchy-hooks';
import { CustomModal } from 'Components';
import useHierarchyModalHooks from './hierarchyModal-hooks';
import styles from './hierarchyModal.module.scss';

interface IProps {
  modal: IHierachyModal,
  onClose: () => void,
  hierarchyData: IHierarchyData,
  setHierarchyData: Dispatch<SetStateAction<IHierarchyData>>

}

const HierarchyModal: React.FC<IProps> = (props) => {
  const { modal, hierarchyData, onClose, setHierarchyData } = props;
  const { MODAL_INNER } = useHierarchyModalHooks({
    hierarchyData: hierarchyData,
    modal: modal,
    onClose: onClose,
    setHierarchyData: setHierarchyData
  });

  return (
    <div>
      <CustomModal
        open={modal.open}
        title={MODAL_INNER[`${modal.type!}`]?.title!}
        handleClose={onClose}
        dialogClassname={"hierarchyModal"}
        children={
          <form onSubmit={MODAL_INNER[`${modal.type!}`]?.onSubmit!} className={styles.form}>
            {MODAL_INNER[`${modal.type!}`]?.body}
          </form>
        }
      />
    </div>
  )
}

export default HierarchyModal
