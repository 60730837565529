import { t } from "i18next";
import editIcon from "Assets/Images/edit-3-svgrepo-com.svg";
import styles from "./groupDetails.module.scss";
import { useCustomModalHooks } from "Components/CustomModal/customModal.hooks";
import CustomModal from "Components/CustomModal/customModal";
import { useEffect, useState } from "react";
import { useEditGroupMutation } from "Services/groupsApi";
import { toast } from "react-toastify";

interface IProps {
  name: string | undefined;
  groupMembersCount: number | undefined;
  id: number
}

const GroupDetails: React.FC<IProps> = (props) => {
  const { name, groupMembersCount, id } = props;
  const { openModal, handleOpen, handleClose } = useCustomModalHooks();
  const [groupName, setGroupName] = useState(name ? name : "");
  const [editGroup, { isLoading, isSuccess }] = useEditGroupMutation();
  useEffect(() => {
    if (isSuccess) {
      handleClose();
      toast.success(t("Groups.Group_Edited_Successfully"))
    }
  }, [isSuccess]);  

  return (
    <div className={styles["information"]}>
      <div className={styles["info-item"]}>
        <span className={styles["info-label"]}>{t("Groups.Group_Name")}</span>
        <span className={styles["info-value"]}>
          {name}{" "}
          <img
            src={editIcon}
            className={styles["edit-icon"]}
            onClick={handleOpen}
          />
        </span>
      </div>
      <div className={styles["info-item"]}>
        <span className={styles["info-label"]}>{t("Groups.Team_Members")}</span>
        <span className={styles["info-value"]}>{groupMembersCount}</span>
      </div>
      <CustomModal
        open={openModal}
        title={t("Groups.Edit_Modal_Title")}
        loading={isLoading}
        buttonTitle={t("Aplication_Page.App_Status.Button")}
        onClick={() => {
          editGroup({id: id, payload: {name: groupName}});
        }}
        handleClose={handleClose}
        dialogClassname={"delete-group__modal"}
      >
        <div className={styles["delete-modal__content"]}>
          <input
            placeholder="Group name"
            className={styles["group-name__input"]}
            defaultValue={groupName}
            onChange={(e: any) => {
              setGroupName(e?.target?.value);
            }}
          />
        </div>
      </CustomModal>
    </div>
  );
};

export default GroupDetails;
