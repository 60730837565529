import React from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { IHierarchyData } from 'Pages/CompanyHierarchy/hierarchy-hooks';
import { BlueTrashSVG, CloseHierarchyIcon, HierarchyIcon, NewWindowSVG, PlusSVG } from 'Assets';
import { TeamImg } from 'Assets';
import styles from './hierarchsectionItem.module.scss';

interface IProps {
    data: IHierarchyData,
    onCreateNew: (pareentId?: string) => void,
    expanded?: string[],
    onDelete: (id: string) => void,
    addAsExpanded?: (id: string) => void,
    removeExpanded?: (id: string) => void,
    handleNewTabOpen: (id: string) => void,
}

const HierarchySectionItem: React.FC<IProps> = (props) => {
    const { data, expanded, addAsExpanded, removeExpanded, onCreateNew, onDelete, handleNewTabOpen } = props;
    const backgroundColor = data.sectionColor ?? `#006667`;
    const isExpanded = expanded ? expanded?.some(id => id === data.id!) : true;

    return (
        <div className={styles.container} >
            <div className={styles.body}>
                <div className={styles.item} style={{ backgroundColor: backgroundColor }}>
                    <div className={styles.imageBlock}>
                        <img src={data.sectionImg ? `${process.env.REACT_APP_API_KEY}${data.sectionImg}` : TeamImg} alt='img' className={styles.image} />
                    </div>
                    <div className={styles.info} style={{ boxShadow: `inset 1px 1px 10px  ${backgroundColor}` }}>
                        <div className={styles.infoData}>
                            <div className={styles.sectionName}>
                                {data.sectionName!}
                            </div>
                            <div className={styles.sectionMembers}>
                                <Trans
                                    components={{ span: <span className={styles.memberCount} /> }}
                                >
                                    {t('Hierarchy.Members', { count: data.children.length! })}
                                </Trans>
                            </div>
                        </div>
                        {data.children.length > 0
                            &&
                            <div className={styles.expanded} style={{ borderColor: `${backgroundColor}` }} onClick={isExpanded ? () => (expanded && removeExpanded!(data.id!)) : () => addAsExpanded!(data.id!)}>
                                <img src={isExpanded ? CloseHierarchyIcon : HierarchyIcon} alt='hierarchyIcon' />
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.optionsBox}>
                    <img src={BlueTrashSVG} className={styles.optionIcon} alt='trashIcon' onClick={() => onDelete(data.id!)} />
                    <img src={NewWindowSVG} className={styles.optionIcon} alt='newWindowIcon' onClick={() => handleNewTabOpen(data.id!)} />
                </div>
            </div>
            <div className={styles.addBox}>
                <img src={PlusSVG} alt='plusIcon' onClick={() => onCreateNew(data.id!)} className={styles.plusIcon} />
            </div>
        </div>
    )
}

export default HierarchySectionItem
