import React from 'react';
import { LoginSocialFacebook, IResolveParams } from 'reactjs-social-login';
import { GoogleLogin } from '@react-oauth/google';
import { keepAccessToken, keepPlatform } from 'Store/Slices/socialLoginSlice';
import { useGeneralHooks } from 'Pages/general.hooks';
import UseSocialLoginHooks from './socialLogin.hooks';
import FacebookLogo from 'Assets/Images/Facebook.png';
import styles from './socialLogin.module.scss';

const SocialLogin: React.FC = () => {
  const { signInWithGoogle } = UseSocialLoginHooks();
  const { dispatch,t } = useGeneralHooks();
  return (
    <div className={styles.container}>
      <div className={styles.linePart}>
        <div className={styles.line} />
        <div className={styles.text}>{t('Login_Form.Social_Title')}</div>
        <div className={styles.line} />
      </div>
      <div className={styles.social}>
        <LoginSocialFacebook
          isOnlyGetToken
          appId={process.env.REACT_APP_FACEBOOK_APP_ID || ''}
          onResolve={({ data }: IResolveParams) => {
            signInWithGoogle({
              platform: 'facebook',
              access_token: data?.accessToken,
            });
            dispatch(keepPlatform('facebook'));
            dispatch(keepAccessToken(data?.accessToken));
          }}
          onReject={(err) => {
            console.log(err);
          }}
        >
          <img
            src={FacebookLogo}
            alt='Facebook'
            className={styles.socialIcon}
          />
        </LoginSocialFacebook>
        <GoogleLogin
        shape='circle'
        type='icon'
        theme='outline'
        size='large'
        onSuccess={(credentialResponse) => {
            signInWithGoogle({
              access_token: credentialResponse?.credential!,
              platform: 'google',
            });
            dispatch(keepAccessToken(credentialResponse?.credential));
            dispatch(keepPlatform('google'));
          }}
          onError={() => {
            console.log('Login Failed');
          }}
        />
      </div>
    </div>
  );
};

export default SocialLogin;
