import { useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMakeEmployeeMutation } from 'Services/employeesApi';
import { useGetDocumentTypesQuery } from 'Services/generalApi';
import { IMakeEmployeeFields } from 'Pages/interfaces';
import { useGeneralHooks } from 'Pages/general.hooks';
import { RootState, useAppSelector } from 'Store/store';
import moment from 'moment';
import { keepMekumUser } from 'Store/Slices/mekumSlice';

const UseMakeEmployeeHooks = () => {
  const mekumUserInfo = useSelector(
    (state: RootState) => state?.mekum?.connectMekumUser
  );

  const { data: documentTypeList } = useGetDocumentTypesQuery();

  const defaulDocumentTypeValue = documentTypeList?.data.filter(
    (item) => item.id === mekumUserInfo?.otherInformation?.documentTypeId
  );

  const defaultDate = mekumUserInfo?.otherInformation?.dueDate;
  const minDate = new Date().toISOString().split('T')[0];

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<IMakeEmployeeFields | FieldValues>({
    mode: 'all',
  });

  const [makeEmployeeRequest, { isLoading, isSuccess }] =
    useMakeEmployeeMutation();

  const currentUser = useAppSelector(
    (state: RootState) => state?.auth?.currentUser
  );
  const { params, navigate, dispatch } = useGeneralHooks();
  const mekumId = currentUser?.data?.activeCompany?.mekumId;
  const mekumEmployeeId = mekumUserInfo?.id;

  const onSubmit: SubmitHandler<IMakeEmployeeFields | FieldValues> = (data) => {
    const payload = {
      user_id: params?.id,
      document_type_id: data.document_type_id.id,
      passport_number: data.passport_number,
      date_of_issue: moment(data.date_of_issue).format('YYYY-MM-DD'),
      authority: data.authority,
      mekum_id: mekumEmployeeId ?? null,
    };
    makeEmployeeRequest(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(`/main/employees/${params?.id}`);
    }
  }, [isSuccess]);

  useEffect(() => {
    setValue(
      'document_type_id',
      mekumUserInfo ? defaulDocumentTypeValue?.[0] : null
    );
    setValue(
      'passport_number',
      mekumUserInfo && documentTypeList
        ? mekumUserInfo?.otherInformation?.passportNumber
        : null
    );
    setValue(
      'date_of_issue',
      mekumUserInfo ? mekumUserInfo?.otherInformation?.dueDate : null
    );
    setValue(
      'authority',
      mekumUserInfo ? mekumUserInfo?.otherInformation?.byWhom : null
    );
  }, [mekumUserInfo, documentTypeList]);

  useEffect(() => {
    dispatch(keepMekumUser({}));
    reset();
  }, [navigate, params.id]);

  return {
    errors,
    control,
    minDate,
    mekumId,
    isLoading,
    defaultDate,
    documentTypeList,
    defaulDocumentTypeValue,
    handleSubmit,
    onSubmit,
    register,
  };
};

export default UseMakeEmployeeHooks;
