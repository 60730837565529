import { FieldValues, UseFormRegister } from 'react-hook-form';
import { EmployeeTypes } from './EmployeeProfile/employeeProfile';
import { ReactNode } from 'react';
import { EmployeePropertyTypes } from 'Components/interfaces';
import { IResponseDataItem } from 'Services/responce_types';

export interface CreateCompanyFormValues {
  name: string;
  address: string;
  oltId: string;
  cityId: string;
  sphereOfActivityId: string;
  typeOfTaxId: string;
  tin: string;
  contactPerson?: null;
  contactPersonEmail?: null;
  contactPersonPhone?: null;
  avatar?: Object | null;
  roleId: string;
}

export interface IForgetPassword {
  email: string;
  password: string;
  confirmPassword: string;
}

export interface ILoginRespData {
  email: string;
  password: string;
  id: number;
  name: string;
  surname: string;
  avatar: string | null;
  birthDate: string | null;
  emailValidationToken: string | null;
  emailVerified: boolean;
  forgetPasswordValidationToken: string | null;
  gender: string | null;
  number: string | null;
  socialSecurityCard: string | null;
  token: string;
}

export interface LoginFormValues {
  result: ILoginRespData;
  error: null | string;
  email: string;
  password: string;
}

export type RegisterFormValues = {
  name: string;
  surname: string;
  email: string;
  password: string;
};
export interface RegisterResponse {
  error: null | string;
  result: null | string;
}

export interface SearchInputValues {
  search: string;
}

export type InputRegisterTypes = UseFormRegister<
  FieldValues | LoginFormValues | SearchInputValues
>;

export interface ICreateGroupForm {
  name: string;
}

export interface IMekumToken {
  mekum_token: string;
}

export interface IVacationStatusChangeValues {
  status_id: number;
  description: string;
}

export interface IMakeEmployeeFields {
  document_type_id: string;
  passport_number: string;
  date_of_issue: string;
  authority: string;
}

export enum AppScreenType {
  HISTORY = 'history',
  FORM = 'form',
}

export interface IEmployeeProfile {
  type?: EmployeeTypes;
  image?: string | null;
  role?: string;
  email?: string;
  name?: string;
}

export interface IGroupChatValues {
  image: FormData;
  groupName: string;
}

export enum ChatScreenTypes {
  CHANNEL_LIST = 'ChannelList',
  CHAT = 'Chat',
}

export interface IInitialEdge {
  id: string;
  source: string;
  target: string;
}

export interface IAnswerObj {
  output: string;
  answer: { value: string };
}

export interface ICollectInfoObj {
  required: boolean;
  output: string;
  fieldName: string;
  minValue: number | Date;
  maxValue: number | Date;
  fieldType: string;
  key: string;
}

export interface IInputOutputValues {
  parentBlockId: { name: string; id: string };
  inputValue: { name: string; id: string };
}

export interface IGeneralBlockValues {
  blockName: string;
  assign: number;
  inputOutputFields: IInputOutputValues[] | [];
  blockType: { id: number; name: string };
}

export interface IStartBlockValues {
  // blockType: { id: number; name: string };
  // blockName: string;
  // key: string;
  // accFields: ICollectInfoObj[] | [];
  // inputOutputFields: IInputOutputValues[] | [],
  // assign: number;
}

export interface IQuestionAnswerFormValues {
  key: string;
  question: string;
  answersArr: IAnswerObj[] | [];
}

export interface INameId {
  name: string;
  id: number;
}

export interface IEmailBlock {
  emailTo: string;
  emailSubject: string;
  emailContent: string;
  emailRedactor: string;
}

export interface ICollectInfoBlock {
  accFields: ICollectInfoObj[] | []
}

export enum EmployeeDetailScreeenTypes {
  HISTORY = 'HISTORY',
  MORE_INFO = 'MORE_INFO',
}

export interface ICreateTemplateValues {
  templateName: string;
}

export interface IElements {
  data: { label: Element | JSX.Element };
  id: string;
  inputs: string[];
  outputs: string[];
  position: { x: number; y: number };
}

export interface IEmpConfigType {
  _id: string;
  title: EmployeePropertyTypes;
}

export interface IEmployeeProperty {
  title?: string;
  _id?: string;
  key?: string;
  type?: IEmpConfigType;
  max?: string;
  min?: string;
  required?: boolean;
  directory?: IResponseDataItem;
  filter?: boolean;
}

export interface IEmployeeTPropartyTemplate {
  type: EmployeePropertyTypes;
  name: string;
  icon: ReactNode;
}

export enum FormTypes {
  CREATE = 'Create',
  EDIT = 'Edit',
}

export interface IDirectoryFormValues {
  name?: string;
  options: string[];
  parentId?: any;
}

export interface IDirectoryEditFormValues {
  newOption: string;
  editOption: string;
}

export interface IEditBlockData {
  data: {
    name: string;
    type: string;
    processTemplate: string | undefined;
    data: any;
    inputs: string[];
  };
  id: string | undefined;
}

export enum TaskStatus {
  TODO = 'todo',
  DOING = 'in progress',
  DONE = 'done',
}

export enum DirectoryModalTypes {
  ADD_OPTION = 'AddOption',
  DELETE_OPTION = 'DeleteOption',
  EDIT_OPTION = 'EditOption',
  DELETE_DIRECTORY = 'DeleteDyrectory',
}

export interface IDirectoryModal {
  open: boolean;
  dyrectoryid?: number;
  type?: DirectoryModalTypes;
  data?: IDirectoryModalData;
}

export interface IPropertyModal {
  open: boolean;
  title?: string;
  id?: string;
}

export interface IDirectoryModalData {
  dyrectoryid?: number;
  directoryName?: string;
  optionId?: number;
  optionName?: string;
}

export interface IGetVacationDays {
  status: number,
  result: {
    lastVacationAvailableDays: number,
    currentVacationAvailableDays: number,
    availableCurrentVacationDays: number
  }
}