import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  VacationDetailPage,
  CreateCompanyPage,
  InviteRequestPage,
  CompanyHierarchy,
  AddMemberToGroup,
  EmployeeSettings,
  EmployeeProfile,
  CreateGroupPage,
  DismissalDetail,
  ReferenceDetail,
  ConfirmIdentity,
  ForgetPassword,
  AbsenceDetail,
  PensionDetail,
  Notifications,
  MyInvitations,
  MarkSuperior,
  Applications,
  MakeEmployee,
  Directories,
  UserProfile,
  PrivatePage,
  AddEmployee,
  SignUpPage,
  Employees,
  LogInPage,
  GroupById,
  Settings,
  MainPage,
  PaySheet,
  Groups,
  Orders,
  Tabel,
  Chat,
  CompanyHierarchyItemPage,
} from 'Pages';
import { ApplicationTypes } from 'Components/interfaces';

const AuthNavigation: React.FC = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <PrivatePage>
            <LogInPage />
          </PrivatePage>
        }
      />
      <Route path='/login' element={<LogInPage />} />
      <Route path='/registration' element={<SignUpPage />} />
      <Route path='/forgetPassword' element={<ForgetPassword />} />
      <Route path='/confirm-identity' element={<ConfirmIdentity />} />
      <Route
        path='/createCompany'
        element={
          <PrivatePage>
            <CreateCompanyPage />
          </PrivatePage>
        }
      />
      <Route
        path='/invite'
        element={
          <PrivatePage>
            <InviteRequestPage />
          </PrivatePage>
        }
      />
      <Route
        path='/chat'
        element={
          <PrivatePage>
            <Chat />
          </PrivatePage>
        }
      />
      <Route
        path='/main'
        element={
          <PrivatePage>
            <MainPage />
          </PrivatePage>
        }
      >
        <Route path='documents'>
          <Route
            path='applications'
            element={
              <PrivatePage>
                <Applications type={ApplicationTypes.Company_Applications} />
              </PrivatePage>
            }
          >
            <Route
              path='vacation'
              element={
                <PrivatePage>
                  <Applications type={ApplicationTypes.Company_Applications} />
                </PrivatePage>
              }
            />
            <Route
              path='absence'
              element={
                <PrivatePage>
                  <Applications type={ApplicationTypes.Company_Applications} />
                </PrivatePage>
              }
            />
            <Route
              path='pension'
              element={
                <PrivatePage>
                  <Applications type={ApplicationTypes.Company_Applications} />
                </PrivatePage>
              }
            />
            <Route
              path='dismissal'
              element={
                <PrivatePage>
                  <Applications type={ApplicationTypes.Company_Applications} />
                </PrivatePage>
              }
            />
            <Route
              path='references'
              element={
                <PrivatePage>
                  <Applications type={ApplicationTypes.Company_Applications} />
                </PrivatePage>
              }
            />
          </Route>
          <Route
            path='my_applications'
            element={
              <PrivatePage>
                <Applications type={ApplicationTypes.User_Applications} />
              </PrivatePage>
            }
          >
            <Route
              path='vacation'
              element={
                <PrivatePage>
                  <Applications type={ApplicationTypes.User_Applications} />
                </PrivatePage>
              }
            />
            <Route
              path='absence'
              element={
                <PrivatePage>
                  <Applications type={ApplicationTypes.User_Applications} />
                </PrivatePage>
              }
            />
            <Route
              path='pension'
              element={
                <PrivatePage>
                  <Applications type={ApplicationTypes.User_Applications} />
                </PrivatePage>
              }
            />
            <Route
              path='dismissal'
              element={
                <PrivatePage>
                  <Applications type={ApplicationTypes.User_Applications} />
                </PrivatePage>
              }
            />
            <Route
              path='references'
              element={
                <PrivatePage>
                  <Applications type={ApplicationTypes.User_Applications} />
                </PrivatePage>
              }
            />
          </Route>
          <Route
            path='paySheet'
            element={
              <PrivatePage>
                <PaySheet />
              </PrivatePage>
            }
          />
          <Route
            path='orders'
            element={
              <PrivatePage>
                <Orders />
              </PrivatePage>
            }
          />
          <Route
            path='tabel'
            element={
              <PrivatePage>
                <Tabel />
              </PrivatePage>
            }
          />
        </Route>
        <Route
          path='notifications'
          element={
            <PrivatePage>
              <Notifications />
            </PrivatePage>
          }
        />
        <Route
          path='my-invitations'
          element={
            <PrivatePage>
              <MyInvitations />
            </PrivatePage>
          }
        />
        <Route
          path='settings'
          element={
            <PrivatePage>
              <Settings />
            </PrivatePage>
          }
        />
        <Route
          path='/main/settings/directories'
          element={
            <PrivatePage>
              <Directories />
            </PrivatePage>
          }
        />
        <Route
          path='/main/settings/employeeSettings'
          element={
            <PrivatePage>
              <EmployeeSettings />
            </PrivatePage>
          }
        />
         <Route
          path='/main/settings/companyHierarchy'
          element={
            <PrivatePage>
              <CompanyHierarchy />
            </PrivatePage>
          }
        />
        <Route
          path='/main/settings/companyHierarchy/:id'
          element={
            <PrivatePage>
              <CompanyHierarchyItemPage/>
            </PrivatePage>
          }
        />
        <Route
          path='employees'
          element={
            <PrivatePage>
              <Employees />
            </PrivatePage>
          }
        />
        <Route
          path='employees/:id'
          element={
            <PrivatePage>
              <EmployeeProfile />
            </PrivatePage>
          }
        />
        <Route
          path='employees/:id/make-employee'
          element={
            <PrivatePage>
              <MakeEmployee />
            </PrivatePage>
          }
        />
        <Route
          path='employees/:id/markSuperior'
          element={
            <PrivatePage>
              <MarkSuperior />
            </PrivatePage>
          }
        />
        <Route
          path='userProfile'
          element={
            <PrivatePage>
              <UserProfile />
            </PrivatePage>
          }
        />
        <Route
          path='employees/addEmployee'
          element={
            <PrivatePage>
              <AddEmployee />
            </PrivatePage>
          }
        />
        <Route
          path='groups/:id'
          element={
            <PrivatePage>
              <GroupById />
            </PrivatePage>
          }
        />
        <Route
          path='groups/:id/addMember'
          element={
            <PrivatePage>
              <AddMemberToGroup />
            </PrivatePage>
          }
        />
        <Route
          path='groups'
          element={
            <PrivatePage>
              <Groups />
            </PrivatePage>
          }
        />
        <Route
          path='groups/createGroup'
          element={
            <PrivatePage>
              <CreateGroupPage />
            </PrivatePage>
          }
        />
        <Route
          path='/main/documents/applications/vacation/:id'
          element={
            <PrivatePage>
              <VacationDetailPage />
            </PrivatePage>
          }
        />
        <Route
          path='/main/documents/applications/absence/:id'
          element={
            <PrivatePage>
              <AbsenceDetail />
            </PrivatePage>
          }
        />
        <Route
          path='/main/documents/applications/dismissal/:id'
          element={
            <PrivatePage>
              <DismissalDetail />
            </PrivatePage>
          }
        />
        <Route
          path='/main/documents/applications/reference/:id'
          element={
            <PrivatePage>
              <ReferenceDetail />
            </PrivatePage>
          }
        />
        <Route
          path='/main/documents/applications/pension/:id'
          element={
            <PrivatePage>
              <PensionDetail />
            </PrivatePage>
          }
        />
      </Route>
    </Routes>
  );
};
export default AuthNavigation;
