import { useState } from "react";
import { useUploadFileMutation } from "Services/applicationsApi";

const useUploadImageHooks = ()=>{
    const [uploadFile, { data: ImageResponce }] = useUploadFileMutation();
    const [selectedImage, setSelectedImage] = useState<string | undefined>(
        undefined
      );
      const handleFileInputChange = async (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        try {
          const file = event.target.files?.[0];
          if (file) {
            const formData = new FormData();
            formData.append('image', file);
            const imageUrl = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
            await uploadFile(formData);
          }
        } catch (error) {
           console.error('Error selecting an image:', error);
        }
      };

      return {
          selectedImage,
          ImageResponce,
          handleFileInputChange,
      }
};

export default useUploadImageHooks