import React from 'react';
import { UserImageUpload } from 'Components';
import { IEmployeeProfile } from 'Pages/interfaces';
import { useGeneralHooks } from 'Pages/general.hooks';
import EmployeeImage from 'Assets/Images/Employee.png';
import styles from './employeeProfile.module.scss';

export const EmployeeInfo: React.FC<IEmployeeProfile> = (props) => {
  const { image, role, email, name, type } = props;
  const { t } = useGeneralHooks();

  return (
    <div
      className={
        type === 'user' ? styles.userInfoContainer : styles.infoContainer
      }
    >
      {type === 'user' ? (
        <div className={styles.imageUpload}>
          <UserImageUpload id='userImage' image={image} />
        </div>
      ) : (
        <div className={styles.profileImage}>
          <img
            src={
              image ? `${process.env.REACT_APP_API_KEY}${image}` : EmployeeImage
            }
            className={styles.image}
            alt='Employee'
          />
        </div>
      )}
      <div
        className={
          type === 'user' ? styles.userProfileInfo : styles.profileDescriptions
        }
      >
        <h4 className={styles.profileName}>{t('Employees.Personal_Info')}</h4>
        <div className={styles.descriptionBox}>
          <p className={styles.typography}>{t('Register.First_name')}</p>
          <h4 className={styles.employeeInfo}>{name}</h4>
        </div>
        <div className={styles.descriptionBox}>
          <p className={styles.typography}>{t('Employees.Occupation')}</p>
          <h4 className={styles.employeeInfo}>
            {role ? role : t('Employees.user')}
          </h4>
        </div>
        <div className={styles.descriptionBox}>
          <p className={styles.typography}>{t('Register.Email_label')}</p>
          <h4 className={styles.employeeInfo}>{email}</h4>
        </div>
      </div>
    </div>
  );
};
export default EmployeeInfo;
