import React from 'react';
import { IHierarchyData } from 'Pages/CompanyHierarchy/hierarchy-hooks';
import { useGetUserByIdQuery } from 'Services/employeesApi';
import EmployeeImage from 'Assets/Images/Employee.png';
import { BlueTrashSVG, NewWindowSVG, PlusSVG } from 'Assets';
import styles from './hierarchyRoleItem.module.scss';

interface IProps {
    data: IHierarchyData,
    onCreateNew: (pareentId?: string) => void,
    onDelete: (id: string) => void,
    bgColor?: string,
    handleNewTabOpen: (id: string) => void
}

const HierarchyRoleItem: React.FC<IProps> = (props) => {
    const { data, onCreateNew, onDelete, bgColor = '#006884', handleNewTabOpen, } = props;
    const { data: employee } = useGetUserByIdQuery(data.employee!);

    return (
        <div className={styles.container}>
            <div className={styles.body}>
                <div className={styles.item} style={{ backgroundColor: bgColor }}>
                    <div className={styles.imageBlock}>
                        <img src={employee?.data.avatar ? `${process.env.REACT_APP_API_KEY}${employee?.data.avatar}` : EmployeeImage} alt='img' className={styles.image} />
                    </div>
                    <div className={styles.info}>
                        <div className={styles.orgName}>
                            {data.orgName!}
                        </div>
                        <div className={styles.employee}>
                            {`${employee?.data.name!} ${employee?.data.surname!}`}
                        </div>
                    </div>
                </div>
                <div className={styles.optionsBox}>
                    <img src={BlueTrashSVG} className={styles.optionIcon} alt='trashIcon' onClick={() => onDelete(data.id!)} />
                    <img src={NewWindowSVG} className={styles.optionIcon} alt='newWindowIcon' onClick={() => handleNewTabOpen(data.id!)} />
                </div>
            </div>
            <div className={styles.addBox}>
                <img src={PlusSVG} alt='plusIcon' onClick={() => onCreateNew(data.id!)} className={styles.plusIcon} />
            </div>
        </div>
    )
}

export default HierarchyRoleItem
