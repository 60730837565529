import ApplicationDetailHistoryList from 'Components/ApplicationDetailHistory/applicationDetailHistoryList';
import { useAppPathsHooks } from 'Components/BreadCrumb/breadCrumb.hooks';
import { ApplicationDetailInfo, CustomModal, Header, Loading } from 'Components';
import { SearchInput } from 'Components/AuthComponents/AuthInputs';
import BreadCrumbs from 'Components/BreadCrumb/breadCrumbs';
import { useGeneralHooks } from 'Pages/general.hooks';
import { HeaderTypes } from 'Components/interfaces';
import usePensionDetailHooks from './pensionDetail.hooks';
import styles from './pensionDetail.module.scss'
import { IVacationByIdResp } from 'Services/responce_types';
import { ChangeStatusModal } from 'Components/Application/ApplicationItems';

const PensionDetail:React.FC = () => {
  const {
    pensionByIdInfo,
    isFetching,
    renderItemData,
    statuses,
    control,
    errors,
    openModal,
    onSubmit,
    handleSubmit,
    onClose,
    register } = usePensionDetailHooks();
  const { largeScreen, navigate, t } = useGeneralHooks();
  const { pathsToRender } = useAppPathsHooks();

  return (
    <>
      {!largeScreen && (
        <Header
          type={HeaderTypes.Navigation}
          navTitle={t('Render_path_Names.Benefits')}
          iconOnClick={() => navigate(-1)}
        />
      )}
      <div className='page'>
        {isFetching ? (
          <Loading />
        ) : (
          <>
            {largeScreen && (
              <div className={styles['breadCrumbBox']}>
                <BreadCrumbs data={pathsToRender} />
                <SearchInput />
              </div>
            )}
            <div className={styles['content']}>
              <div className={styles['pension-detail-page__title']}>
                {t('Aplication_Page.Tab_Labels.Pension')}
              </div>
              {largeScreen ? <ApplicationDetailInfo data={renderItemData} /> : <ApplicationDetailInfo data={renderItemData} dismissal />}
              <ApplicationDetailHistoryList data={pensionByIdInfo as IVacationByIdResp} />
            </div>
            <div>
              <CustomModal
                onClick={handleSubmit(onSubmit)}
                buttonTitle={t('Aplication_Page.App_Status.Button')}
                children={<ChangeStatusModal control={control} errors={errors} register={register} statuses={statuses} />}
                title={t('Aplication_Page.Vacation.Status')}
                handleClose={onClose}
                open={openModal}
                dialogClassname={'changeStatusDialog'}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PensionDetail;
