import { t } from "i18next";
import useRoleHooks from "Routers/role-hooks";
import Button from "Components/Button/button";
import { useGeneralHooks } from "Pages/general.hooks";
import { useDeleteGroupMutation, useGetGroupByIdQuery } from "Services/groupsApi";
import { CustomModal, EmployeeItem, Header, Loading } from "Components";
import BreadCrumbs from "Components/BreadCrumb/breadCrumbs";
import GroupDetails from "Components/GroupDetails/groupDetails";
import { ButtonTypes, HeaderTypes } from "Components/interfaces";
import { SearchInput } from "Components/AuthComponents/AuthInputs";
import { useAppPathsHooks } from "Components/BreadCrumb/breadCrumb.hooks";
import styles from "./groupById.module.scss";
import { useCustomModalHooks } from "Components/CustomModal/customModal.hooks";
import { useEffect } from "react";
import { toast } from "react-toastify";

const GroupById: React.FC = () => {
  const { pathsToRender } = useAppPathsHooks();
  const { params, largeScreen, navigate } = useGeneralHooks();
  const { data: groupById, isLoading } = useGetGroupByIdQuery(params.id);
  const { currentRole } = useRoleHooks();
  const { openModal, handleOpen, handleClose } = useCustomModalHooks();
  const [deleteGroupReq, {isLoading: deleteGroupLoading, isSuccess: groupDeletedSuccessfully}] = useDeleteGroupMutation()

  useEffect(() => {
    if(groupDeletedSuccessfully){
      handleClose();
      navigate("/main/groups");
      toast.success(t("Groups.Group_Delted"))
    }
  }, [groupDeletedSuccessfully])

  return (
    <>
      {!largeScreen ? (
        <>
          <Header
            type={HeaderTypes.Navigation}
            navTitle={t("Groups.Groups")}
            iconOnClick={() => navigate(-1)}
            headerStyle={styles.header}
          />
          {isLoading ? (
            <Loading />
          ) : (
            <div className={styles["mobile-group-content"]}>
              <div className={styles["group-info-container"]}>
                <p className={styles["title"]}>
                  {t("Groups.Group_By_Id_Title")}
                </p>
                <GroupDetails
                  name={groupById?.result?.name}
                  groupMembersCount={groupById?.result?.groupMembers?.length}
                  id={+groupById?.result?.id!}
                />
                <div className={styles["group-users-title"]}>
                  {t("Groups.Team_Members")}
                </div>
                <div className={styles["group-members-cont"]}>
                  {groupById?.result?.groupMembers?.map((item, i) => {
                    return (
                      <EmployeeItem
                        src={item.user.avatar}
                        key={item.i}
                        name={`${item.user.name} ${item.user.surname}`}
                        onClick={() =>
                          navigate(`/main/employees/${item.userId}`)
                        }
                      />
                    );
                  })}
                </div>
              </div>
              {currentRole === "HR" ||
                (currentRole === "CEO" && (
                  <Button
                    buttonType={ButtonTypes.Primery}
                    buttonStyle={styles.button}
                    title={t("Groups.Add_Member")}
                    type="submit"
                    onClick={() => navigate("addMember")}
                  />
                ))}
            </div>
          )}
        </>
      ) : (
        <div className={styles["group-page"]}>
          {isLoading ? (
            <Loading />
          ) : (
            <div>
              <div className={styles["searchContainer"]}>
                <BreadCrumbs data={pathsToRender} />
                <SearchInput />
              </div>
              <div className={styles["heading"]}>
                <p className={styles["title"]}>
                  {t("Groups.Group_By_Id_Title")}
                </p>
                <div>
                  {currentRole === "HR" ||
                    (currentRole === "CEO" && (
                      <>
                        <Button
                          buttonType={ButtonTypes.Primery}
                          buttonStyle="width: 28px;"
                          title={t("Groups.Add_Member")}
                          type="submit"
                          onClick={() => navigate("addMember")}
                        />
                        <div className={styles["group-actions"]}>
                          <Button
                            type="button"
                            buttonType={ButtonTypes.WithoutBg}
                            title={t("Employee_Settings.Delete")}
                            onClick={handleOpen}
                            buttonTitleStyle={styles["delete-btn__title"]}
                            buttonStyle={styles["delete-btn"]}
                          />
                        </div>
                        <CustomModal
                            open={openModal}
                            title={t("Groups.Delete_Group_Title")}
                            loading={deleteGroupLoading}
                            buttonTitle={t("Employee_Settings.Delete")}
                            onClick={() => {deleteGroupReq(+params.id!)}}
                            handleClose={handleClose}
                            dialogClassname={"delete-group__modal"}
                          >
                            <div className={styles["delete-modal__content"]}>{t("Groups.Delete_Group_Content")}</div>
                          </CustomModal>
                      </>
                    ))}
                </div>
              </div>
              <div className={styles["group-info-container"]}>
                <GroupDetails
                  name={groupById?.result?.name}
                  groupMembersCount={groupById?.result?.groupMembers?.length}
                  id={+groupById?.result?.id!}
                />
                <div className={styles["group-members-cont"]}>
                  {groupById?.result?.groupMembers?.map((item) => {
                    return (
                      <EmployeeItem
                        key={item.id}
                        src={item.user.avatar}
                        name={`${item.user.name} ${item.user.surname}`}
                        onClick={() =>
                          navigate(`/main/employees/${item.userId}`)
                        }
                        deletable
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default GroupById;
