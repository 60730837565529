import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import eye from 'Assets/Icons/eye.svg';
import hidden from 'Assets/Icons/hidden.svg';
import Button from 'Components/Button/button';
import Employee from 'Assets/Images/Employee.png';
import { ButtonTypes } from 'Components/interfaces';
import { useGeneralHooks } from 'Pages/general.hooks';
import { InputField } from 'Components/AuthComponents/AuthInputs';
import { usePassword } from 'Components/AuthComponents/AuthInputs/authInput-hooks';
import UseSocialLoginHooks from 'Components/AuthComponents/SocialLogin/socialLogin.hooks';
import styles from './socialLogin.module.scss';

interface IProps {
  src?: string;
  name?: string;
  email?: string;
}

const NeedPassword: React.FC<IProps> = (props) => {
  const { handleSubmit, register, onSubmit, errors, isLoading, socialUser } =
    UseSocialLoginHooks();
  const { password, changeType } = usePassword();
  const { navigate } = useGeneralHooks();
  const { src, name, email } = props;

  return (
    <div className={styles['need-password__container']}>
      <div className={styles['hr-loop']}>HR Loop</div>
      <div>
        <div className={styles['profile-img__container']}>
          <img
            alt='profile-img'
            className={styles['profile-img']}
            src={src ? `${process.env.REACT_APP_API_KEY}${src}` : Employee}
          />
        </div>
        <div className={styles['name-surname__container']}>
          {t('Social_Login.loginAs')} <span>{name}</span>
        </div>
        {socialUser?.result?.message === 'need email' && (
          <div className={styles['email-container']}>
            {email}{' '}
            <span
              onClick={() => {
                toast.error(t('Social_Login.duplicate_email_err'));
                navigate('/login');
              }}
            >
              {t('Social_Login.not_you')}
            </span>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          {socialUser?.result?.message === 'need password' && (
            <InputField
              type={password ? 'password' : 'text'}
              error={errors.password}
              id='password'
              placeholder={t('Login_Form.Password_placeholder')}
              label={t('Login_Form.Password_label')}
              register={register}
              registerName='password'
              message={t('Login_Form.Passwor_Error')}
              patternValue={/(?=.{8,})/gim}
            >
              <img
                className={styles.inputIcon}
                src={password ? hidden : eye}
                alt='show'
                onClick={changeType}
              />
            </InputField>
          )}
          {socialUser?.result?.message === 'need email' && (
            <InputField
              type='text'
              id='email'
              placeholder={t('Login_Form.Login_placeholder')}
              label={t('Login_Form.Login_label')}
              register={register}
              registerName='email'
              message={t('Login_Form.Login_Error')}
              patternValue={
                /^\s*[A-ZA-z0-9._%+-]+@[A-Za-z0-9.-]+[A-Za-z]{2,}\s*$/i
              }
              error={errors.email}
            />
          )}
          <div className={styles.forgotPassword}>
            <Link to='/forgetPassword'>{t('Login_Form.Forgot')}</Link>
          </div>
          <Button
            buttonType={ButtonTypes.Primery}
            buttonStyle={styles.loginButton}
            type='submit'
            title={t('Forget_Password.First_page.Send')}
            isLoading={isLoading}
          />
        </form>
      </div>
    </div>
  );
};

export default NeedPassword;
