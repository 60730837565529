import React from 'react';
import { t } from 'i18next';
import { AppScreenType } from 'Pages/interfaces';
import CustomPagination from 'Components/Pagination/pagination';
import { ApplicationTypes, ButtonTypes, CardTypes } from 'Components/interfaces';
import { ResponsiveVactionCard } from 'Components';
import Button from 'Components/Button/button';
import useHistory from 'Components/Application/AppHistory/history-hooks';
import { usePensionHooks } from 'Components/Application/ApplicationItems/Pension/pension-hooks';
import NoData from 'Components/NoData/noData';
import PensionForm from 'Components/Application/ApplicationItems/Pension/pensionForm';
import styles from './responsivePage.module.scss';
import { useGeneralHooks } from 'Pages/general.hooks';
import { IApplicationComponentProps } from 'Components/Application/ApplicationItems/Vacation/vacationComponent';

const ResponsivePagePension: React.FC<IApplicationComponentProps> = (props) => {
  const { ordered_by, screenType, setScreenType } = props;
  const { onSubmit } = usePensionHooks();
  const { RESPONSIVE_ITEM_DATA, activePage, setActivePage } = useHistory();
  const { navigate } = useGeneralHooks()

  return (
    <>
      {screenType === AppScreenType.HISTORY ?
        (
          RESPONSIVE_ITEM_DATA.pension[`${ordered_by}`]?.result.length
            ?
            (<div className={styles.container}>
              <div className={styles.cardContent}>
                {RESPONSIVE_ITEM_DATA.pension[`${ordered_by}`]?.result?.map((card: any) => (
                  <ResponsiveVactionCard
                    type={CardTypes.WithEndDate}
                    title={`${card.user.surname} ${card.user.name}`}
                    key={card.id}
                    startDate={card.startDate}
                    endDate={card.endDate}
                    onPress={() => navigate(`/main/documents/applications/pension/${card.id}`)}
                    text={card.status.name}
                    bgColor={card.status.color}
                  />
                ))}
              </div>
              <div className={styles.buttonContent}>
                {RESPONSIVE_ITEM_DATA.pension[`${ordered_by}`]?.count! > 10 && (
                  <CustomPagination
                    count={Math.ceil(+RESPONSIVE_ITEM_DATA.pension[`${ordered_by}`]?.count! / 10)}
                    page={activePage}
                    onChange={(_, page) => {
                      setActivePage(page);
                      window.scrollTo(0, 0);
                    }}
                  />
                )}
                {ordered_by === ApplicationTypes.User_Applications
                  &&
                  <Button
                    buttonStyle={styles.button}
                    onClick={() => {
                      setScreenType && setScreenType(AppScreenType.FORM)
                    }}
                    type='submit'
                    title={t('ResponsivePageAbsence.Button_Apply')}
                    buttonType={ButtonTypes.ButtonWithIcon}
                  />
                }
              </div>
            </div>)
            :
            (<NoData
              withButton={ordered_by === ApplicationTypes.User_Applications}
              title={t('No_Data.Title')}
              description={t('No_Data.Description')}
              btnText={t('No_Data.Btn_Text')}
              btnOnclick={() => {
                setScreenType && setScreenType(AppScreenType.FORM)
              }}
            />)
        )
        :
        (
          <div className={styles.form}>
            <PensionForm onSubmit={onSubmit} />
          </div>
        )
      }

    </>
  );
};

export default ResponsivePagePension;



