import React from 'react';
import {t} from 'i18next';
import styles from '../styles.module.scss';
import { UseFormSetValue } from 'react-hook-form';
import { IHierarchyFormValues } from 'Pages/CompanyHierarchy/Components/Modal/hierarchyModal-hooks';

interface IProps {
    setValue:UseFormSetValue<IHierarchyFormValues>
     registerName:'sectionColor'
}

const ColorPicker:React.FC<IProps> = (props) => {
    const {setValue,registerName} = props
  return (
    <div className={styles.container}>
      <div className={styles.label}>
                    {t('Hierarchy.Upload_Image')}
                </div>
                <input type='color' className={styles.colorPicker} onChange={(e)=>setValue(`${registerName}`,e.target.value!)}/>
    </div>
  )
}

export default ColorPicker
