import ApplicationDetailHistoryList from 'Components/ApplicationDetailHistory/applicationDetailHistoryList';
import { useAppPathsHooks } from 'Components/BreadCrumb/breadCrumb.hooks';
import { ApplicationDetailInfo, CustomModal, Header, Loading } from 'Components';
import { SearchInput } from 'Components/AuthComponents/AuthInputs';
import useDismissalDetailHooks from './dismissalDetail-hooks';
import BreadCrumbs from 'Components/BreadCrumb/breadCrumbs';
import { useGeneralHooks } from 'Pages/general.hooks';
import { HeaderTypes } from 'Components/interfaces';
import { ChangeStatusModal } from 'Components/Application/ApplicationItems';
import { IDismissalByIdResp } from 'Services/responce_types';
import styles from './dismissal.module.scss';

const DismissalDetail:React.FC = () => {
  const {
    isFetching,
    renderItemData,
    dismissalByIdInfo,
    onClose,
    onSubmit,
    control,
    openModal,
    errors,
    register,
    handleSubmit,
    statuses } = useDismissalDetailHooks();
  const { largeScreen, navigate, t } = useGeneralHooks();
  const { pathsToRender } = useAppPathsHooks();

  return (
    <>
      {!largeScreen && (
        <Header
          type={HeaderTypes.Navigation}
          navTitle={t('Aplication_Page.Tab_Labels.Release')}
          iconOnClick={() => navigate(-1)}
        />
      )}
      <div className='page'>
        {isFetching ? (
          <Loading />
        ) : (
          <>
            {largeScreen && (
              <div className={styles['breadCrumbBox']}>
                <BreadCrumbs data={pathsToRender} />
                <SearchInput />
              </div>
            )}
            <div className={styles['content']}>
              <div className={styles['dismissal-detail-page__title']}>
                {t('Aplication_Page.Tab_Labels.Release')}
              </div>
              <ApplicationDetailInfo data={renderItemData} dismissal />
              {!largeScreen && (
                <div className={styles['dismissal-detail-page__history-title']}>
                  {t('Aplication_Page.Application_History')}
                </div>
              )}
              <ApplicationDetailHistoryList data={dismissalByIdInfo as IDismissalByIdResp}  />
            </div>
            <div>
              <CustomModal
                onClick={handleSubmit(onSubmit)}
                buttonTitle={t('Aplication_Page.App_Status.Button')}
                children={<ChangeStatusModal control={control} errors={errors} register={register} statuses={statuses}/>}
                title={t('Aplication_Page.Vacation.Status')}
                handleClose={onClose}
                open={openModal}
                dialogClassname={'changeStatusDialog'}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DismissalDetail;
