import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useAddEmpPropertyMutation, useDeletePropertyMutation, useEditPropertyMutation, useGetEmpPropertiesListQuery, useGetEmpPropertyTypesQuery } from "Services/employeesApi";
import { FormTypes, IEmployeeProperty, IEmployeeTPropartyTemplate, IPropertyModal } from "Pages/interfaces";
import { useGeneralHooks } from "Pages/general.hooks";
import { OrdinaryForm, BooleanForm, DateForm, SelectForm, IPropertyFormProps } from './employeePropertyForms';
import { EmployeePropertyTypes } from "Components/interfaces";
import { AbcSVG, Calendar, FileSVG, NumberSVG, SelectSVG, YesOrNoSVG } from "Assets";
import { IEditProperty } from "Services/responce_types";

export interface IFormValues extends IEmployeeProperty { }

const initialTemplate: IEmployeeProperty = {
    title: undefined,
    key: undefined,
    type: undefined,
    required: undefined,
    max: undefined,
    min: undefined,
    directory: undefined
};

const initialModal: IPropertyModal = { open: false }

const useEmployeeSettigsHooks = () => {
    const { register, control, reset, handleSubmit, watch, formState: { errors, isValid }, setError } = useForm<IEmployeeProperty>({ defaultValues: initialTemplate, mode: "all" })
    const { t, largeScreen, navigate } = useGeneralHooks();
    const [activePage, setActivePage] = useState<number>(1);
    const { data: Properties, isLoading: getPropertiesLoading } = useGetEmpPropertiesListQuery({ offset: activePage, limit: 10 });
    const { data: PropertyTypes } = useGetEmpPropertyTypesQuery();
    const [postNewProperty, { isLoading: postLoading }] = useAddEmpPropertyMutation();
    const [editProperty, { isLoading: editLoading }] = useEditPropertyMutation()
    const [deleteProperty, { isLoading: DeleteLoading }] = useDeletePropertyMutation();
    const properties: IEmployeeProperty[] | [] = Properties?.result!;
    const total = Properties?.total!;
    const [checkedType, setCheckedType] = useState<number | undefined>(undefined);
    const [currentTemplate, setCurrentTemplate] = useState<IEmployeeProperty>(initialTemplate);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [modal, setModal] = useState<IPropertyModal>(initialModal);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false)
    const loading = getPropertiesLoading || postLoading || DeleteLoading || editLoading;
    const isFormHidden:boolean = !currentTemplate.type && !expanded;

    useEffect(() => {
        !modal.open && !expanded && window.scrollTo({ top: 0 })
    }, [Properties, expanded, modal.open]);

    useEffect(() => {
        if (expanded) {
            setCheckedType(undefined)
            setCurrentTemplate(initialTemplate)
        }
        currentTemplate.type && setExpanded(false)
    }, [expanded, currentTemplate]);

    const propertyTypes: IEmployeeTPropartyTemplate[] = useMemo(() => [
        {
            type: EmployeePropertyTypes.NUMBER,
            name: t('Employee_Settings.Number'),
            icon: <img src={NumberSVG} alt='numberSvg' />
        },
        {
            type: EmployeePropertyTypes.STRING,
            name: t('Employee_Settings.String'),
            icon: <img src={AbcSVG} alt='numberSvg' />
        },
        {
            type: EmployeePropertyTypes.FILE,
            name: t('Employee_Settings.File'),
            icon: <img src={FileSVG} alt='numberSvg' />
        },
        {
            type: EmployeePropertyTypes.BOOLEAN,
            name: t('Employee_Settings.Boolean'),
            icon: <img src={YesOrNoSVG} alt='numberSvg' />
        },
        {
            type: EmployeePropertyTypes.DATE,
            name: t('Employee_Settings.Date'),
            icon: <img src={Calendar} alt='numberSvg' />
        },
        {
            type: EmployeePropertyTypes.SELECT,
            name: t('Employee_Settings.Select'),
            icon: <img src={SelectSVG} alt='numberSvg' />
        }
    ], [t]);

    const onTypeSelect = (type: EmployeePropertyTypes, index: number) => {
        const selectedType = PropertyTypes?.result.filter((item) => item.title === type)[0]
        reset(initialTemplate);
        setExpanded(false);
        const tempalte = { ...initialTemplate, type: selectedType };
        setCurrentTemplate(tempalte);
        setCheckedType(index);
        openDrawer && setOpenDrawer(false)
    };

    const checkName = useCallback((array: IEmployeeProperty[], name: string) => {
        const exist = array.some((item) => item.title === name);
        return exist
    }, []);
    const checkKey = useCallback((array: IEmployeeProperty[], key: string) => {
        const exist = array.some((item) => item.key === key);
        return exist
    }, []);

    const onCreate: SubmitHandler<IEmployeeProperty | FieldValues> = async (values) => {
        const name: string = (values.title).trim();
        const isNameAlreadyExist = checkName(properties!, name);
        const isKeyAlreadyExist = checkKey(properties!, values.key!)
        if (isNameAlreadyExist || isKeyAlreadyExist) {
            isNameAlreadyExist && setError("title", { message: t('Employee_Settings.Errors.Name') })
            isKeyAlreadyExist && setError("key", { message: t('Employee_Settings.Errors.Key') })
        } else if (isValid) {
            const payload: IEmployeeProperty = {
                key: values.key!,
                title: values.title,
                type: currentTemplate.type,
                min: values.min,
                max: values.max,
                required: values.required,
                directory: values.directory?.id!,
                filter: values.filter || false
            };
            await postNewProperty(payload)
            setCurrentTemplate(initialTemplate);
            setCheckedType(undefined)
            reset(initialTemplate)
        }
    };

    const onEdit = async (values: IEmployeeProperty, config: IEmployeeProperty) => {
        if (isValid) {
            const name: string = values.title!.trim();
            const isNameAlreadyExist = name !== config.title! && checkName(properties, name);
            if (isNameAlreadyExist) {
                setError("title", { message: t('Employee_Settings.Errors.Name') })
                return
            } else {
                const data: IEmployeeProperty = {
                    key: name.replaceAll(" ", "_") ?? config.title!,
                    title: name ?? config.title!,
                    type: config.type,
                    min: values.min ?? config.min,
                    max: values.max ?? config.max,
                    required: values.required ?? config.required,
                    //@ts-ignore
                    directory: values.directory?.id ?? config.directory?._id,
                    filter: values.filter ?? config.filter
                };
                const payload: IEditProperty = {
                    id: config._id!,
                    data: data
                }
                await editProperty(payload)
                setExpanded(false)
            }
        }
    };

    const onTamplateCancel = useCallback(() => {
        setExpanded(false)
        setCurrentTemplate(initialTemplate)
        setCheckedType(undefined);
    }, []);

    const onTemplateDelete = async (id: string) => {
        await deleteProperty(id);
        setModal(initialModal)
    }


    const handleAccordion = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
        reset(initialTemplate)
    };

    const onOpenModal = (title: string, id: string) => {
        const tmp: IPropertyModal = {
            open: true,
            title: title,
            id: id
        }
        setModal(tmp)
    };


    const onCloseModal = useCallback(() => {
        setModal(initialModal)
    }, []);

    const onOpenDrawer = useCallback(() => {
        setOpenDrawer(true)
    }, []);

    const onCloseDrawer = useCallback(() => {
        setOpenDrawer(false)
    }, []);



    const createTemlateConfigForm = useCallback((config: IEmployeeProperty, formType: FormTypes, index?: number) => {
        const configType = PropertyTypes?.result.filter((item) => item._id! === config.type?._id!)[0].title;
        const props: IPropertyFormProps = {
            property: config,
            formType: formType,
            register: register,
            control: control,
            errors: errors,
            watch: watch,
            onCreate: onCreate,
            onTamplateCancel: onTamplateCancel,
            onEdit: onEdit,
            handleSubmit: handleSubmit,
        };
        switch (configType) {
            case EmployeePropertyTypes.NUMBER:
            case EmployeePropertyTypes.STRING:
            case EmployeePropertyTypes.FILE:
                return (
                    <OrdinaryForm
                        {...props}
                    />
                )
            case EmployeePropertyTypes.BOOLEAN:
                return (
                    <BooleanForm
                        {...props}
                    />
                );
            case EmployeePropertyTypes.DATE:
                return (
                    <DateForm
                        {...props}
                    />
                );
            case EmployeePropertyTypes.SELECT:
                return (
                    <SelectForm
                        {...props}
                    />
                );
            default:
                break
        };
    }, [expanded, onCreate, onTemplateDelete, handleSubmit, control, register, errors, onEdit]);


    return {
        total,
        activePage,
        propertyTypes,
        currentTemplate,
        properties,
        checkedType,
        expanded,
        modal,
        loading,
        DeleteLoading,
        largeScreen,
        openDrawer,
        isFormHidden,
        navigate,
        setActivePage,
        onTemplateDelete,
        onOpenModal,
        onCloseModal,
        onTypeSelect,
        createTemlateConfigForm,
        handleAccordion,
        onOpenDrawer,
        onCloseDrawer
    }
};

export default useEmployeeSettigsHooks;

