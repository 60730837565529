import { useEffect, useState } from 'react';
import { IChangeUserAvatar } from 'Components/interfaces';
import { useUploadFileMutation } from 'Services/applicationsApi';
import { useChangeUserAvatarMutation } from 'Services/authApi';
import { RootState, useAppSelector } from 'Store/store';

const useUserImageHooks = () => {
  const currentUser = useAppSelector(
    (state: RootState) => state?.auth?.currentUser
  );
  const [changeAvatar] = useChangeUserAvatarMutation();
  const [selectedImage, setSelectedImage] = useState<string | undefined>(
    undefined
  );
  const [allowUpload, setAllowUpload] = useState<boolean>(false);
  const [uploadFile, { data: ImageResponce }] = useUploadFileMutation();
  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const file = event.target.files?.[0];
      if (file) {
        const formData = new FormData();
        formData.append('image', file);
        const imageUrl = URL.createObjectURL(file);
        setSelectedImage(imageUrl);
        await uploadFile(formData);
        setAllowUpload(true);
      }
    } catch (error) {
      // console.error('Error selecting an image:', error);
    }
  };
  useEffect(() => {
    if (allowUpload) {
      const changeImage = async () => {
        try {
          const payload: IChangeUserAvatar = {
            avatar: ImageResponce?.result as string,
            id: currentUser?.data.id as number,
          };
          await changeAvatar(payload);
          setAllowUpload(false);
        } catch (error) {
          // console.error('Error selecting an image:', error);
        }
      };
      changeImage();
    }
  }, [allowUpload]);
  return {
    handleFileInputChange,
    selectedImage,
    ImageResponce
  };
};

export default useUserImageHooks;
