import React from 'react'
import { HeaderTypes } from 'Components/interfaces';
import useHeaderHooks from './header-hooks';
import { GoBack, LogoHeader } from 'Assets';
import styles from "./header.module.scss";
import { useGeneralHooks } from 'Pages/general.hooks';

export interface IHeaderProps {
    type: HeaderTypes,
    icon?:string,
    iconOnClick?: () => void,
    navTitle?: string,
    headerStyle?: string,
}
const Header: React.FC<IHeaderProps> = (props) => {
    const { type,icon, iconOnClick, navTitle,headerStyle } = props;
    const { AUTH_HEADER_STYLES } = useHeaderHooks();
    const {navigate} = useGeneralHooks();
    return (
        <div className={styles.box}>
            <div className={`${AUTH_HEADER_STYLES[type].container} ${headerStyle}` }>
                {type === HeaderTypes.Navigation &&
                    <div className={styles.primeryHeaderNav}>
                        <div className={styles.iconContainer} onClick={iconOnClick}>
                            <img src={ icon || GoBack} alt='arrowBack' />
                        </div>
                        <div className={styles.navTitle}>{navTitle}</div>
                        <div className={styles.iconContainer} />
                    </div>
                }
                {type === HeaderTypes.Logo && 
                <div className={styles.logoContainer} onClick={()=>navigate('/')}>
                <img src={LogoHeader} alt='logo' />
                </div>
                }
            </div>
        </div>
    )
}

export default Header
