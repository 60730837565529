import React, { useCallback } from "react";
import { Control, Controller, FieldErrors, FieldValues, SubmitHandler, UseFormHandleSubmit, UseFormRegister, UseFormWatch } from "react-hook-form";
import { t } from 'i18next';
import { Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useGetDirectoriesQuery } from "Services/employeesApi";
import { FormTypes, IEmployeeProperty } from "Pages/interfaces";
import { ButtonTypes, EmployeePropertyTypes } from "Components/interfaces";
import { AutoComplete, InputField } from "Components/AuthComponents/AuthInputs";
import AuthError from "Components/AuthComponents/AuthError/authError";
import Button from "Components/Button/button";
import styles from './employeeProperties.module.scss';

const createConfigFormTitle = (type: EmployeePropertyTypes) => {
    switch (type) {
        case EmployeePropertyTypes.NUMBER:
            return t('Employee_Settings.Number');
        case EmployeePropertyTypes.STRING:
            return t('Employee_Settings.String');
        case EmployeePropertyTypes.DATE:
            return t('Employee_Settings.Date');
        case EmployeePropertyTypes.FILE:
            return t('Employee_Settings.File');
        case EmployeePropertyTypes.BOOLEAN:
            return t('Employee_Settings.Boolean');
        case EmployeePropertyTypes.SELECT:
            return t('Employee_Settings.Select')
    }
};

export interface IPropertyFormProps {
    property: IEmployeeProperty,
    errors: FieldErrors<IEmployeeProperty>,
    control: Control<IEmployeeProperty, any>,
    formType: FormTypes,
    handleSubmit: UseFormHandleSubmit<IEmployeeProperty>,
    onCreate: SubmitHandler<any | FieldValues>,
    register: UseFormRegister<IEmployeeProperty>,
    onTamplateCancel: () => void,
    onEdit: (values: IEmployeeProperty, config: IEmployeeProperty) => void,
    watch: UseFormWatch<IEmployeeProperty>
};

const positiveNumberPattern = new RegExp('^[1-9]\\d*$');
const keyPattern = /^[a-z]+$/;
export const OrdinaryForm: React.FC<IPropertyFormProps> = (props) => {
    const {
        property,
        errors,
        control,
        formType,
        handleSubmit,
        onCreate,
        register,
        onTamplateCancel,
        onEdit,
        watch
    } = props;

    return (
        <div className={`${styles.formContainer} ${"employeeSettingForm"}`}>
            <div className={styles.formTitle}>{createConfigFormTitle(property.type?.title!)}</div>
            <form
                onSubmit={formType === FormTypes.EDIT ? handleSubmit(values => onEdit(values, property)) : handleSubmit(onCreate)}
                className={styles.form}>
                <InputField
                    register={register}
                    registerName={"title"}
                    label={t('Employee_Settings.Labels.Name')}
                    placeholder={t('Employee_Settings.Placeholders.Name')}
                    required
                    id={property.title}
                    defaultValue={property.title}
                    error={errors.title!}
                    inputStyle={styles.input}
                />
                <InputField
                    register={register}
                    registerName={"key"}
                    label={t('Employee_Settings.Labels.Key')}
                    placeholder={t('Employee_Settings.Placeholders.Key')}
                    required
                    id={property.key}
                    defaultValue={property.key}
                    error={errors.key!}
                    inputStyle={styles.input}
                    validation={(value) => keyPattern.test(value) || value === "" || t('Input_Errors.Invalid_Key')}
                />
                <div className={styles.radioButton}>
                    <div className={styles.radioLabel}>
                        {t('Employee_Settings.Required')}
                    </div>
                    <Controller
                        control={control}
                        name='required'
                        render={({ field }) => (
                            <RadioGroup
                                {...field}
                                row
                                defaultValue={property.required}
                                defaultChecked={property.required}
                            >
                                <FormControlLabel value={true} control={<Radio />} label={t(`Radio_Buttons.Yes`)} />
                                <FormControlLabel value={false} control={<Radio />} label={t(`Radio_Buttons.No`)} />
                            </RadioGroup>
                        )}
                    />
                    <AuthError text={errors.required?.message} />
                </div>
                <div className={styles.inputRow}>
                    <InputField
                        type='number'
                        register={register}
                        registerName='min'
                        defaultValue={property.min}
                        required={false}
                        label={t('Employee_Settings.Labels.Min')}
                        validation={(value) => positiveNumberPattern.test(value) || value === "" || t('Input_Errors.Positive_Number')}
                        error={errors.min!}
                    />
                    <InputField
                        type='number'
                        register={register}
                        registerName='max'
                        defaultValue={property.max}
                        required={false}
                        label={t('Employee_Settings.Labels.Max')}
                        validation={(value) => +value < +(watch('min')!) ? t('Input_Errors.MinMax') : positiveNumberPattern.test(value) || value === "" || t('Input_Errors.Positive_Number')}
                        error={errors.max}
                    />
                </div>
                <>
                    {property.type?.title! !== "file"
                        &&
                        <Controller
                            control={control}
                            name='filter'
                            defaultValue={property.filter}
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    label={t('Employee_Settings.Labels.Filter')}
                                    control={
                                        <Checkbox checked={value} onChange={onChange} />
                                    }
                                />
                            )}
                        />
                    }
                </>
                <div className={styles.buttonRow}>
                    <Button
                        buttonType={ButtonTypes.WithoutBg}
                        title={formType === FormTypes.CREATE ? t('Employee_History.Cancele') : t('Employee_Settings.Close')}
                        buttonStyle={styles.button}
                        onClick={onTamplateCancel}
                    />
                    <Button
                        buttonType={ButtonTypes.Primery}
                        type="submit"
                        title={formType === FormTypes.CREATE ? t('Groups.Create') : t('Employee_History.Save')}
                        buttonStyle={styles.button}
                    />
                </div>
            </form>
        </div>
    )
};

export const BooleanForm: React.FC<IPropertyFormProps> = (props) => {
    const {
        property,
        errors,
        control,
        formType,
        handleSubmit,
        onCreate,
        register,
        onTamplateCancel,
        onEdit
    } = props;

    return (
        <div className={`${styles.formContainer} ${"employeeSettingForm"}`}>
            <form
                onSubmit={formType === FormTypes.EDIT ? handleSubmit(values => onEdit(values, property)) : handleSubmit(onCreate)}
                className={styles.form}>
                <div className={styles.formTitle}>{createConfigFormTitle(property.type?.title!)}</div>
                <InputField
                    register={register}
                    registerName={"title"}
                    label={t('Employee_Settings.Labels.Name')}
                    placeholder={t('Employee_Settings.Placeholders.Name')}
                    required
                    id={property.title}
                    defaultValue={property.title}
                    error={errors.title!}
                    inputStyle={styles.input}
                />
                <InputField
                    register={register}
                    registerName={"key"}
                    label={t('Employee_Settings.Labels.Key')}
                    placeholder={t('Employee_Settings.Placeholders.Key')}
                    required
                    id={property.key}
                    defaultValue={property.key}
                    error={errors.key!}
                    inputStyle={styles.input}
                    validation={(value) => keyPattern.test(value) || value === "" || t('Input_Errors.Invalid_Key')}
                />
                <div className={styles.radioButton}>
                    <div className={styles.radioLabel}>
                        {t('Employee_Settings.Required')}
                    </div>
                    <Controller
                        control={control}
                        name='required'
                        render={({ field }) => (
                            <RadioGroup
                                {...field}
                                row
                                defaultValue={property.required}
                                defaultChecked={property.required}
                            >
                                <FormControlLabel value={true} control={<Radio />} label={t(`Radio_Buttons.Yes`)} />
                                <FormControlLabel value={false} control={<Radio />} label={t(`Radio_Buttons.No`)} />
                            </RadioGroup>
                        )}
                    />
                    <AuthError text={errors.required?.message} />
                    <Controller
                        control={control}
                        name='filter'
                        defaultValue={property.filter}
                        render={({ field: { onChange, value } }) => (
                            <FormControlLabel
                                label={t('Employee_Settings.Labels.Filter')}
                                control={
                                    <Checkbox checked={value} onChange={onChange} />
                                }
                            />
                        )}
                    />
                </div>
                <div className={styles.buttonRow}>
                    <Button
                        buttonType={ButtonTypes.WithoutBg}
                        title={formType === FormTypes.CREATE ? t('Employee_History.Cancele') : t('Employee_Settings.Close')}
                        buttonStyle={styles.button}
                        onClick={onTamplateCancel}
                    />
                    <Button
                        buttonType={ButtonTypes.Primery}
                        type="submit"
                        title={formType === FormTypes.CREATE ? t('Groups.Create') : t('Employee_History.Save')}
                        buttonStyle={styles.button}
                    />
                </div>
            </form>
        </div>
    )
};

export const DateForm: React.FC<IPropertyFormProps> = (props) => {
    const {
        property,
        errors,
        control,
        formType,
        handleSubmit,
        onCreate,
        register,
        onTamplateCancel,
        onEdit,
        watch
    } = props;
    console.log(typeof(new Date(watch('min')!).getTime()))
    return (
        <div className={`${styles.formContainer} ${"employeeSettingForm"}`}>
            <form
                onSubmit={formType === FormTypes.EDIT ? handleSubmit(values => onEdit(values, property)) : handleSubmit(onCreate)}
                className={styles.form}>
                <div className={styles.formTitle}>{createConfigFormTitle(property.type?.title!)}</div>
                <InputField
                    register={register}
                    registerName={"title"}
                    label={t('Employee_Settings.Labels.Name')}
                    placeholder={t('Employee_Settings.Placeholders.Name')}
                    defaultValue={property.title}
                    required
                    id={property.title}
                    error={errors.title!}
                    inputStyle={styles.input}
                />
                <InputField
                    register={register}
                    registerName={"key"}
                    label={t('Employee_Settings.Labels.Key')}
                    placeholder={t('Employee_Settings.Placeholders.Key')}
                    required
                    id={property.key}
                    defaultValue={property.key}
                    error={errors.key!}
                    inputStyle={styles.input}
                    validation={(value) => keyPattern.test(value) || value === "" || t('Input_Errors.Invalid_Key')}
                />
                <div className={styles.radioButton}>
                    <div className={styles.radioLabel}>
                        {t('Employee_Settings.Required')}
                    </div>
                    <Controller
                        control={control}
                        name='required'
                        render={({ field }) => (
                            <RadioGroup
                                {...field}
                                row
                                defaultValue={property.required}
                                defaultChecked={property.required}
                            >
                                <FormControlLabel value={"true"} control={<Radio />} label={t(`Radio_Buttons.Yes`)} />
                                <FormControlLabel value={"false"} control={<Radio />} label={t(`Radio_Buttons.No`)} />
                            </RadioGroup>
                        )}
                    />
                    <AuthError text={errors.required?.message} />
                </div>
                <div className={styles.inputRow}>
                    <InputField
                        type='date'
                        register={register}
                        registerName='min'
                        defaultValue={property.min}
                        label={t('Employee_Settings.Labels.Min')}
                        required={false}
                        error={errors.min!}
                    />
                    <InputField
                        type='date'
                        register={register}
                        registerName='max'
                        defaultValue={property.max}
                        label={t('Employee_Settings.Labels.Max')}
                        required={false}
                        validation={(value) => new Date(value).getTime() >= new Date(watch('min')!).getTime() || t('Input_Errors.MinMax')}
                        error={errors.max!}
                    />
                </div>
                <Controller
                    control={control}
                    name='filter'
                    defaultValue={property.filter}
                    render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                            label={t('Employee_Settings.Labels.Filter')}
                            control={
                                <Checkbox checked={value} onChange={onChange} />
                            }
                        />
                    )}
                />
                <div className={styles.buttonRow}>
                    <Button
                        buttonType={ButtonTypes.WithoutBg}
                        title={formType === FormTypes.CREATE ? t('Employee_History.Cancele') : t('Employee_Settings.Close')}
                        buttonStyle={styles.button}
                        onClick={onTamplateCancel}
                    />
                    <Button
                        buttonType={ButtonTypes.Primery}
                        type="submit"
                        title={formType === FormTypes.CREATE ? t('Groups.Create') : t('Employee_History.Save')}
                        buttonStyle={styles.button}
                    />
                </div>
            </form>
        </div>
    )
};

export const SelectForm: React.FC<IPropertyFormProps> = (props) => {
    const {
        property,
        errors,
        control,
        formType,
        handleSubmit,
        onCreate,
        register,
        onTamplateCancel,
        onEdit
    } = props;
    const { data: DirectoriesAsOptions } = useGetDirectoriesQuery()
    const createDirectoriesAsOption = useCallback(() => {
        const options = DirectoriesAsOptions?.result.map((el) => {
            return {
                name: el.name,
                id: el._id
            }
        });
        return options
    }, [DirectoriesAsOptions])
    return (
        <div className={`${styles.formContainer} ${"employeeSettingForm"}`}>
            <form
                onSubmit={formType === FormTypes.EDIT ? handleSubmit(values => onEdit(values, property)) : handleSubmit(onCreate)}
                className={styles.form}>
                <div className={styles.formTitle}>{createConfigFormTitle(property.type?.title!)}</div>
                <InputField
                    register={register}
                    registerName={"title"}
                    label={t('Employee_Settings.Labels.Name')}
                    placeholder={t('Employee_Settings.Placeholders.Name')}
                    defaultValue={property.title}
                    required
                    id={property.title}
                    error={errors.title!}
                    inputStyle={styles.input}
                />
                <InputField
                    register={register}
                    registerName={"key"}
                    label={t('Employee_Settings.Labels.Key')}
                    placeholder={t('Employee_Settings.Placeholders.Key')}
                    required
                    id={property.key}
                    defaultValue={property.key}
                    error={errors.key!}
                    inputStyle={styles.input}
                    validation={(value) => keyPattern.test(value) || value === "" || t('Input_Errors.Invalid_Key')}
                />
                <Controller
                    control={control}
                    name='required'
                    render={({ field }) => (
                        <RadioGroup
                            {...field}
                            row
                            defaultValue={property.required!}
                            defaultChecked={property.required!}
                        >
                            <FormControlLabel value={"true"} control={<Radio />} label={t(`Radio_Buttons.Yes`)} />
                            <FormControlLabel value={"false"} control={<Radio />} label={t(`Radio_Buttons.No`)} />
                        </RadioGroup>
                    )}
                />
                <Controller
                    control={control}
                    name='directory'
                    rules={{
                        required: t('Input_Errors.Required'),
                    }}
                    defaultValue={property.directory!}
                    render={({ field: { onChange, name, value } }) => {
                        return (
                            <AutoComplete
                                name={name}
                                value={value!}
                                onChange={onChange}
                                id='status_id'
                                data={createDirectoriesAsOption()}
                                label={t('Directories.ParentId_PlaceHolder')}
                                placeholder={t('Employee_Settings.Select')}
                                error={errors.directory}
                            />
                        );
                    }}
                />
                <Controller
                    control={control}
                    name='filter'
                    defaultValue={property.filter}
                    render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                            label={t('Employee_Settings.Labels.Filter')}
                            control={
                                <Checkbox checked={value} onChange={onChange} />
                            }
                        />
                    )}
                />
                <div className={styles.buttonRow}>
                    <Button
                        buttonType={ButtonTypes.WithoutBg}
                        title={formType === FormTypes.CREATE ? t('Employee_History.Cancele') : t('Employee_Settings.Close')}
                        buttonStyle={styles.button}
                        onClick={onTamplateCancel}
                    />
                    <Button
                        buttonType={ButtonTypes.Primery}
                        type="submit"
                        title={formType === FormTypes.CREATE ? t('Groups.Create') : t('Employee_History.Save')}
                        buttonStyle={styles.button}
                    />
                </div>
            </form>
        </div>
    )
};





