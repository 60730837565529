import React from 'react';
import CustomPagination from 'Components/Pagination/pagination';
import { ApplicationTypes, ButtonTypes, CardTypes } from 'Components/interfaces';
import { ResponsiveVactionCard } from 'Components';
import Button from 'Components/Button/button';
import { IApplicationComponentProps } from 'Components/Application/ApplicationItems/Vacation/vacationComponent';
import { useGeneralHooks } from 'Pages/general.hooks';
import useHistory from 'Components/Application/AppHistory/history-hooks';
import { useProvideReferenceHooks } from 'Components/Application/ApplicationItems/References/provideReference-hooks';
import RefernceProfideForm from 'Components/Application/ApplicationItems/References/refernceProfideForm';
import NoData from 'Components/NoData/noData';
import styles from './responsivePage.module.scss';
import { AppScreenType } from 'Pages/interfaces';

const ResponsivePageReference: React.FC<IApplicationComponentProps> = (props) => {
  const { ordered_by,screenType,setScreenType } = props;
  const { t, navigate } = useGeneralHooks();
  const { RESPONSIVE_ITEM_DATA, activePage, setActivePage } = useHistory();
  const {
    errors,
    employeeList,
    control,
    currentRole,
    register,
    onSubmit,
    handleSubmit,
    setShowDataTable,
  } = useProvideReferenceHooks();

  return (
    <>
      {
        screenType === AppScreenType.HISTORY ?
          (
            RESPONSIVE_ITEM_DATA.reference[`${ordered_by}`]?.result.length
              ?
              (
                <div className={styles.container}>
                  <div className={styles.cardContent}>
                    {RESPONSIVE_ITEM_DATA.reference[`${ordered_by}`]?.result?.map((card: any) => {
                      return (
                        <ResponsiveVactionCard
                          key={card.id}
                          type={CardTypes.WithoutEndDate}
                          title={card.place}
                          text={card.status.name}
                          bgColor={card.status.color}
                          startDate={card.date}
                          onPress={() => navigate(`/main/documents/applications/reference/${card.id}`)}
                        />
                      );
                    })}
                  </div>
                  <div className={styles.buttonContent}>
                    {RESPONSIVE_ITEM_DATA.reference[`${ordered_by}`]?.count! > 10 && (
                      <CustomPagination
                        count={Math.ceil(+RESPONSIVE_ITEM_DATA.reference[`${ordered_by}`]?.count! / 10)}
                        page={activePage}
                        onChange={(_, page) => {
                          setActivePage(page);
                          window.scrollTo(0, 0);
                        }}
                      />
                    )}
                    {ordered_by === ApplicationTypes.User_Applications
                    &&
                    <Button
                      buttonStyle={styles.button}
                      onClick={() => setScreenType && setScreenType(AppScreenType.FORM)}
                      type='submit'
                      title={t('ResponsivePageAbsence.Button_Apply')}
                      buttonType={ButtonTypes.ButtonWithIcon}
                    />
                    }
                  </div>
                </div>
              )
              :
              (
                <NoData
                  withButton={ordered_by === ApplicationTypes.User_Applications}
                  title={t('No_Data.Title')}
                  description={t('No_Data.Description')}
                  btnText={t('No_Data.Btn_Text')}
                  btnOnclick={() => {
                    setScreenType && setScreenType(AppScreenType.FORM)
                  }}
                />
              )
          )
          :
          (
            <div className={styles.form}>
              <RefernceProfideForm
                register={register}
                onSubmit={handleSubmit(onSubmit)}
                errors={errors}
                employeeList={employeeList}
                control={control}
                role={currentRole}
              />
            </div>
          )
      }
    </>
  );
};

export default ResponsivePageReference;

