import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useInviteRequestMutation } from 'Services/authApi';
import { RequestFormValues } from 'Components/interfaces';
import { toast } from 'react-toastify';
import { useGeneralHooks } from 'Pages/general.hooks';
import { RootState, useAppSelector } from 'Store/store';

const useInviteRequest = () => {
  const getMeData = useAppSelector(
    (state: RootState) => state?.auth?.currentUser
  );

  const { navigate, t } = useGeneralHooks();
  const [inviteRequest, { isSuccess, isLoading }] = useInviteRequestMutation();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<RequestFormValues>({
    defaultValues: {
      inviteRequests: [
        {
          email: null,
          role: { value: 'role' },
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray<RequestFormValues>({
    name: 'inviteRequests',
    control,
  });

  const addNewRequest = useCallback(() => {
    append({ email: null, role: { value: 'role' } });
  }, [append]);

  const onsubmit = (data: RequestFormValues) => {
    let companiId: number | string;
    if (getMeData?.data?.activeCompanyId) {
      companiId = getMeData?.data?.activeCompanyId;
    } else {
      toast.error(t('Toastify_error.noCompany'));
      return;
    }
    const inviteArray = data.inviteRequests.map((item) => {
      const obj = {
        email: item?.email?.value.trim(),
        roleId: item?.role?.value && +item?.role?.value,
        companyId: companiId,
      };
      return obj;
    });

    const emailValues = inviteArray.map((field) => field?.email);
    const hasDuplicates = new Set(emailValues).size !== emailValues.length;
    const lastIndex = fields.length - 1;
    if (hasDuplicates) {
      setError(`inviteRequests.${lastIndex}.email.value`, {
        type: 'custom',
        message: t('Invite_Request.Same_Emails_Not_Allowed'),
      });
    } else {
      inviteRequest(inviteArray);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      navigate('/main/employees');
      toast.success(t('Forget_Password.Fourth_page.Description'));
    }
  }, [isSuccess, navigate]);

  return {
    fields,
    errors,
    control,
    isLoading,
    append,
    remove,
    register,
    onsubmit,
    handleSubmit,
    addNewRequest,
  };
};

export default useInviteRequest;
