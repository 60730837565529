import React from 'react';
import { FormTypes } from 'Pages/interfaces';
import useDirectoriesHooks from './directories-hooks';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useGeneralHooks } from 'Pages/general.hooks';
import { ButtonTypes, HeaderTypes } from 'Components/interfaces';
import NoData from 'Components/NoData/noData';
import Button from 'Components/Button/button';
import { Header, Loading, MobileNavDrawer } from 'Components';
import CustomPagination from 'Components/Pagination/pagination';
import DirectoryModal from './Modals/directoryModal';
import useMobileNavDrawerHooks from 'Components/MobileNavDrawer/mobileNavDrawer-hooks';
import { BlueTrashSVG } from 'Assets';
import styles from './directories.module.scss';

const Directories: React.FC = () => {
    const {
        directories,
        create,
        expanded,
        total,
        loading,
        activePage,
        modal,
        createDirectoryForm,
        onCreateNew,
        handleAccordion,
        setActivePage,
        onCloseModal,
        onOpenDirectoryDeleteModal
    } = useDirectoriesHooks();
    const { t, largeScreen, navigate } = useGeneralHooks();
    const { open, onDrawerClose } = useMobileNavDrawerHooks();
    return (
        <div className={styles.page}>
            <div className={largeScreen ? styles.body : styles.mobileContainer}>
                {largeScreen
                    ?
                    <div className={styles.header}>
                        <div className={styles.title}>
                            {t('Directories.Title')}
                        </div>
                        <div className={styles.describtion}>
                            {t('Directories.Describtion')}
                        </div>
                    </div>
                    :
                    <div>
                        <Header
                            type={HeaderTypes.Navigation}
                            navTitle={t('Directories.Title')}
                            iconOnClick={() => navigate(-1)}
                        />
                        <MobileNavDrawer open={open} onClose={onDrawerClose} />
                    </div>
                }
                {loading ? <Loading />
                    :
                    (total! > 0
                        ?
                        <div className={styles.directoryList}>
                            {directories?.map((directory, index) => {
                                return (
                                    <Accordion
                                        key={directory._id! + index}
                                        expanded={expanded === directory.name!}
                                        onChange={handleAccordion(directory.name!)}
                                        className={styles.accordion}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={directory.name}
                                            id={String(directory._id)}
                                        >
                                            <div className={styles.summary} >
                                                <div className={styles.directoryTittle}>{directory.name}</div>
                                                <select
                                                    onClick={(e) => { e.stopPropagation(); }}
                                                    className={styles.accordionSelect}
                                                >
                                                    <option hidden>{t('Directories.Options')}</option>
                                                    {directory.options.map((option) => {
                                                        return (
                                                            <option key={option._id} disabled>{option.name}</option>
                                                        )
                                                    })}
                                                </select>
                                                <div className={styles.inputIcon}>
                                                    <img
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            onOpenDirectoryDeleteModal(directory._id, directory.name)
                                                        }}
                                                        src={BlueTrashSVG}
                                                        alt='deleteIcon'
                                                        className={styles.inputIcon}
                                                    />
                                                </div>
                                            </div>

                                        </AccordionSummary >
                                        <AccordionDetails>
                                            {expanded === directory.name! && createDirectoryForm(FormTypes.EDIT, directory)}
                                        </AccordionDetails>
                                    </Accordion>)
                            })}
                            {create && createDirectoryForm(FormTypes.CREATE)}
                            {total! > 10 && (
                                <CustomPagination
                                    count={Math.ceil(
                                        Directories.length! / 10
                                    )}
                                    page={activePage}
                                    onChange={(_, page) => {
                                        setActivePage(page);
                                        window.scrollTo(0, 0);
                                    }}
                                />
                            )}
                            <div className={styles.buttonRow}>
                                {!create && !expanded
                                    &&
                                    <Button
                                        onClick={onCreateNew}
                                        title={t('Groups.Create')}
                                        buttonType={ButtonTypes.Primery}
                                        buttonStyle={styles.createButton}
                                    />
                                }
                            </div>
                        </div>
                        :
                        (
                            create
                                ?
                                <div className={styles.directoryList}>
                                    {createDirectoryForm(FormTypes.CREATE)}
                                </div>
                                :
                                <div className={styles.directoryList}>
                                    < NoData
                                        withButton
                                        title={t('Directories.Nodata_Title')}
                                        btnText={t('Groups.Create')}
                                        btnOnclick={onCreateNew}
                                    />
                                </div>
                        )
                    )}
                <DirectoryModal
                    modal={modal}
                    onClose={onCloseModal}
                />
            </div >
        </div>
    )
}

export default Directories;