import { CustomModal, Header, Loading } from 'Components';
import {
  Chat,
  Window,
  Channel,
  ChannelList,
  MessageList,
  MessageInput,
  ChannelHeader,
  ChannelListContextProvider,
  ChannelPreviewMessenger,
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/v2/index.css';
import useChatHooks from 'Components/GroupChat/chat.hooks';
import { useCustomModalHooks } from 'Components/CustomModal/customModal.hooks';
import { useGeneralHooks } from 'Pages/general.hooks';
import ChatUserAvatar from '../Users/chatUserAvatar';
import { HeaderTypes } from 'Components/interfaces';
import { ChatScreenTypes } from 'Pages/interfaces';
import '../../../App/App.scss';
import plusIcon from 'Assets/Icons/plus.svg';
import styles from './responsiveChat.module.scss';

const ResponsiveChat: React.FC = () => {
  const { t, navigate } = useGeneralHooks();
  const { openModal, handleOpen, handleClose } = useCustomModalHooks();
  const {
    filters,
    newClient,
    channel,
    screenType,
    setScreenType,
    onChannelSelect,
    channelFilterFunction,
    CurrentModalComponent,
    onClose,
  } = useChatHooks(handleClose);
  const scrollToBottom = () =>{
    screenType! === ChatScreenTypes.CHAT && window.scrollTo({top:1000})
  };
  scrollToBottom();
  
  if (!newClient) return <Loading />;

  return (
    //@ts-ignore
    <ChannelListContextProvider value={{ channels: channel, newClient }}>
      <Header
        type={HeaderTypes.Navigation}
        iconOnClick={
          screenType === ChatScreenTypes.CHAT
            ? () => setScreenType(ChatScreenTypes.CHANNEL_LIST)
            : () => navigate(-1)
        }
        navTitle={t('LayoutMenu.Chat')}
        headerStyle={styles.header}
      />
      <div
        className={
          screenType === ChatScreenTypes.CHANNEL_LIST
            ? styles.chatListContainer
            : styles.chatContainer
        }
      >
        <div className={styles['chat-page']}>
          <Chat client={newClient}>
            <div className={styles['chat-container']}>
              {screenType === ChatScreenTypes.CHANNEL_LIST ? (
                <>
                  <div className={styles['chat-list__container']}>
                    <ChannelList
                      channelRenderFilterFn={channelFilterFunction}
                      filters={filters}
                      Preview={(props) => (
                        <ChannelPreviewMessenger
                          {...props}
                          onSelect={() => onChannelSelect(props)}
                          Avatar={() => (
                            <ChatUserAvatar
                              key={props?.channel?.cid!}
                              {...props}
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                  <div
                    className={styles['create-group-chat-btn__cont']}
                    onClick={handleOpen}
                  >
                    <img
                      src={plusIcon}
                      alt='plusIcon'
                      className={styles['plus-icon']}
                    />
                    <span>{t('Chat.cretae_group_chat')}</span>
                  </div>
                  <CustomModal
                    buttonTitle={t('Aplication_Page.App_Status.Button')}
                    children={CurrentModalComponent}
                    title={t('Groups.Choose_Button')}
                    handleClose={onClose}
                    open={openModal}
                    dialogClassname={'changeStatusDialog'}
                  />
                </>
              ) : (
                <Channel channel={channel}>
                  <Window>
                    <ChannelHeader />
                    <MessageList
                      messageActions={[
                        'delete',
                        'edit',
                        'pin',
                        'quote',
                        'react',
                      ]}
                      hideDeletedMessages
                      // @ts-ignore
                      markReadOnScrolledToBottom
                      onlySenderCanEdit
                    />
                    <MessageInput />
                  </Window>
                </Channel>
              )}
            </div>
          </Chat>
        </div>
      </div>
    </ChannelListContextProvider>
  );
};

export default ResponsiveChat;
