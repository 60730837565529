import React from 'react'
import { useParams } from 'react-router';
import { t } from 'i18next';
import useHierarchyHooks from '../hierarchy-hooks';
import HierarchyTree from '../Components/HierarchyTree/hierarchyTree';
import NoData from 'Components/NoData/noData';
import HierarchyModal from '../Components/Modal/hierarchyModal';
import { HierarcyImg } from 'Assets';
import styles from '../hierarchy.module.scss';
import { Slider } from '@mui/material';

const CompanyHierarchyItemPage: React.FC = () => {
    const { id } = useParams();
    const { hierarchyData, modal, onStart, onAdd, onDelete, onCloseModal, setHierarchyData, onGetHierarchyItem, handleNewTabOpen, onToggleBodySize, sliderMarks,scaleRatio } = useHierarchyHooks();
    const data = onGetHierarchyItem(id!);

    return (
        <div className={styles.page}>
            <div className={styles.header}>
                <div className={styles.title}>
                    {data?.orgName ?? data?.sectionName}
                </div>
                <div className={styles.describtion}>
                    {t('Hierarchy.Discription')}
                </div>
            </div>
                <div className={styles.sliderBox}>
                    <div className={styles.slider}>
                        <Slider defaultValue={100} aria-label="Custom marks" valueLabelDisplay="auto" min={10} onChange={(_, value) => onToggleBodySize(value as number)} marks={sliderMarks} />

                    </div>
                </div>
            <div className={styles.body} style={{ transform: `scale(${scaleRatio})` }}>
                {data
                    ?
                    <HierarchyTree
                        data={data}
                        onCreateNew={onAdd}
                        onDelete={onDelete}
                        handleNewTabOpen={handleNewTabOpen}
                    />
                    :
                    <NoData
                        image={HierarcyImg}
                        withButton
                        btnText={t('Hierarchy.Start')}
                        btnOnclick={onStart}
                        description={t('Hierarchy.No_Hierarchy')}
                    />
                }
                <HierarchyModal
                    modal={modal}
                    onClose={onCloseModal}
                    hierarchyData={hierarchyData}
                    setHierarchyData={setHierarchyData}
                />
            </div>
        </div>
    )
}

export default CompanyHierarchyItemPage
