import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./API";
import { IGetMembersToJoinGroupData, IGetMembersToJoinGroupResp, IGroupByIdResp, IGroupsResp, IMembersInGroup, IPaginationQuery, IRespMembersInGroup } from "./responce_types";
import { FieldValues } from "react-hook-form";
import { ICreateGroupForm } from "Pages/interfaces";

export const groupsApi = createApi({
  reducerPath: "groupsApi",
  tagTypes: ['Groups'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_KEY,
  }),
  endpoints: (builder) => ({
    getGroups: builder.query<IGroupsResp, IPaginationQuery>({
      query: ({ page, offset }) => ({
        url: `/groups/${offset}/${page}`,
        method: "GET",
      }),
      providesTags: ['Groups']
    }),
    getGroupById: builder.query<IGroupByIdResp, any>({
      query: (id) => ({
        url: `/group/${id}`,
        method: "GET",
      }),
      providesTags: ['Groups']
    }),
    createGroup: builder.mutation<any, FieldValues>({
      query: (data: ICreateGroupForm) => ({
        url: '/group',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: ['Groups'],
    }),
    addMemberToGroup: builder.mutation<IRespMembersInGroup, IMembersInGroup>({
      query: (memberIds) => ({
        url: '/group/add-member',
        method: 'POST',
        data: memberIds,
      }),
      invalidatesTags: ['Groups']
    }),
    getMembersToJoinGroup: builder.query<IGetMembersToJoinGroupResp, IGetMembersToJoinGroupData>({
      query: (data) => ({
        url: `/users/out_of_group/${data.id}/${data.limit}/${data.page}`,
        method: "GET",
      }),
      providesTags: ['Groups']
    }),
    deleteGroup: builder.mutation<any, number>({
      query: (id) => ({
        url: `/group/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Groups'],
    }),
    deleteUserFromGroup: builder.mutation<any, number>({
      query: (id) => ({
        url: `/group/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Groups'],
    }),
    editGroup: builder.mutation<any, any>({
      query: ({id, payload}) => ({
        url: `/group/${id}`,
        data: payload,
        method: 'PUT',
      }),
      invalidatesTags: ['Groups'],
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useEditGroupMutation,
  useGetGroupByIdQuery,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useAddMemberToGroupMutation,
  useGetMembersToJoinGroupQuery,
  useDeleteUserFromGroupMutation,
} = groupsApi;
