import React from 'react';
import InviteForm from 'Components/AuthComponents/AuthForms/InviteForm/inviteForm'
import { useGetRolesQuery } from 'Services/authApi';
import { useGeneralHooks } from 'Pages/general.hooks';
import BreadCrumbs from 'Components/BreadCrumb/breadCrumbs';
import { SearchInput } from 'Components/AuthComponents/AuthInputs';
import { useAppPathsHooks } from 'Components/BreadCrumb/breadCrumb.hooks';
import mainStyles from "../Employees/employees.module.scss";
import { Header } from 'Components';
import { HeaderTypes } from 'Components/interfaces';
import styles from './addEmployee.module.scss';

const AddEmployee: React.FC = () => {
  const { data: rolesData } = useGetRolesQuery();
  const { t, largeScreen,mobileScreen, navigate } = useGeneralHooks();
  const { pathsToRender } = useAppPathsHooks();

  return (
    <div className={mainStyles.container}>
      {
        largeScreen ? <div className={mainStyles.searchContainer}>
          <BreadCrumbs data={pathsToRender} />
          <SearchInput />
        </div>
          :
          <Header
            type={HeaderTypes.Navigation}
            navTitle={mobileScreen ?t("Employees.Short_Title") :t("Employees.Add_Employee_Title")}
            iconOnClick={() => navigate("/main/employees")}
          />
      }
      <div className={styles.container}>
          <InviteForm
            roles={rolesData?.data}
            title={largeScreen ? t("Employees.Add_Employee_Title") : ""}
            containerStyle={styles.form}
          />
      </div>
    </div>
  )
}

export default AddEmployee
