import React, { useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { t } from 'i18next';
import { IHierarchyFormValues } from 'Pages/CompanyHierarchy/Components/Modal/hierarchyModal-hooks';
import useUploadImageHooks from './uploadImage-hooks';
import EmployeeImage from 'Assets/Images/Employee.png';
import Camera from "Assets/Images/Camera.png";
import styles from '../styles.module.scss';

interface IProps {
    setValue:UseFormSetValue<IHierarchyFormValues>,
    registerName:'sectionImg'
}

const UploadImage: React.FC<IProps> = (props) => {
    const {setValue,registerName} = props;
    const { handleFileInputChange, selectedImage, ImageResponce } = useUploadImageHooks();
    useEffect(()=>{
      selectedImage &&  setValue(`${registerName}`,ImageResponce?.result!)
    },[selectedImage,ImageResponce?.result,setValue,registerName]);
    
    return (
        <>
            <div className={styles.container}>
                <div className={styles.label}>
                    {t('Hierarchy.Upload_Image')}
                </div>
                <input
                    id='imageUpload'
                    type="file"
                    accept="image/*"
                    onChange={handleFileInputChange}
                    style={{ display: "none" }}
                />
                <div className={styles.imageBox}>
                    <label htmlFor={'imageUpload'}>
                        {selectedImage ?
                            <div className={styles.image}>
                                <img src={selectedImage ? selectedImage : EmployeeImage} className={styles.image} alt="userAvatar" />
                            </div>
                            :
                            <div
                                className={styles.image}
                                style={{
                                    backgroundImage: selectedImage
                                        ? `url("${selectedImage}")`
                                        :
                                        `url("${EmployeeImage}")`,
                                }}
                            />
                        }

                        <div className={styles.iconDiv}>
                            <img src={Camera} alt="Camera" />
                        </div>
                    </label>
                </div>
            </div>
        </>
    )
}

export default UploadImage
