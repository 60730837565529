import React from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import EmployeeInfo from "./employeeInfo";
import { RootState, useAppSelector } from "Store/store";
import useRoleHooks from "Routers/role-hooks";
import useChatHooks from "Components/GroupChat/chat.hooks";
import { useGeneralHooks } from "Pages/general.hooks";
import useMobileNavDrawerHooks from "Components/MobileNavDrawer/mobileNavDrawer-hooks";
import { useAppPathsHooks } from "Components/BreadCrumb/breadCrumb.hooks";
import { IEmployeeProfile } from "Pages/interfaces";
import { HeaderTypes } from "Components/interfaces";
import { useGetUserByIdQuery } from "Services/employeesApi";
import BreadCrumbs from "Components/BreadCrumb/breadCrumbs";
import EmployeeProfileButtons from "./employeeProfileButtons";
import { Header, Loading, MobileNavDrawer } from "Components";
import { SearchInput } from "Components/AuthComponents/AuthInputs";
import plusIcon from "Assets/Icons/plus.svg";
import styles from "./employeeProfile.module.scss";

export type EmployeeTypes = "employee" | "user";

const EmployeeProfile: React.FC<IEmployeeProfile> = () => {
  const { id } = useParams();
  const { newClient } = useChatHooks();
  const { hasHighAccess } = useRoleHooks();
  const { pathsToRender } = useAppPathsHooks();
  const currentUser = useAppSelector(
    (state: RootState) => state?.auth?.currentUser
  );
  const { largeScreen, navigate, t } = useGeneralHooks();
  const { data: user, isLoading } = useGetUserByIdQuery(id!);
  const { open, onDrawerClose } = useMobileNavDrawerHooks();

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {largeScreen ? (
            <div className={styles.headerContainer}>
              <div className={styles.breadCrumbBox}>
                <BreadCrumbs data={pathsToRender} />
                <SearchInput />
              </div>
              <h2 className={styles.title}>
                {`${user?.data?.name} ${user?.data?.surname}`}
              </h2>
            </div>
          ) : (
            <div className={styles.mobileBox}>
              <Header
                type={HeaderTypes.Navigation}
                navTitle={`${user?.data?.name} ${user?.data?.surname}`}
                iconOnClick={() => navigate(-1)}
                headerStyle={styles.header}
              />
              <MobileNavDrawer open={open} onClose={onDrawerClose} />
            </div>
          )}
          <div className={styles.employeProfile}>
            <div>
              <EmployeeInfo
                image={user?.data.avatar}
                role={user?.data?.userRoleInCompany[0]?.role?.name}
                name={`${user?.data.name} ${user?.data.surname}`}
                email={user?.data?.email}
              />
              {user?.data?.employee?.mekumId && (
                <div className={styles["already-connected"]}>
                  {t("Mekum.Already_Connected")}
                </div>
              )}
            </div>
            {+id! !== currentUser?.data?.id && (
              <div
                className={styles["send-message__container"]}
                onClick={async () => {
                  try {
                    if (id) {
                      const members = {
                        members: [
                          currentUser?.data?.id?.toString(),
                          user?.data?.id?.toString(),
                        ],
                      };
                      const channele = newClient?.channel("messaging", members);
                      await channele.watch({ presence: true });
                      navigate(`/chat?id=${channele?.id}`);
                    } else {
                      console.log("ID is missing");
                    }
                  } catch (error) {
                    toast.error("The cuurent user is not connected to stream");
                  }
                }}
              >
                <img
                  src={plusIcon}
                  alt="plusIcon"
                  className={styles["plus-icon"]}
                />
                <span>{t("Chat.send_message")}</span>
              </div>
            )}
            <div className={styles.buttons}>
              {hasHighAccess && (
                <EmployeeProfileButtons
                  employeeId={user?.data?.employee?.id!}
                  employeeInfo={user?.data}
                  id={user?.data?.id}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default EmployeeProfile;
