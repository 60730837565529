import { useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { VacationFormValues } from 'Components/interfaces';
import { useCreateAbsenceMutation } from 'Services/Applications/absenceApi';
import { useGetEmployeesFullListQuery } from 'Services/employeesApi';
import { calculateDateDifference } from 'Utils';
import useRoleHooks from 'Routers/role-hooks';
import { RootState, useAppSelector } from 'Store/store';

export const useAbsenceHooks = () => {
  const currentUser = useAppSelector(
    (state: RootState) => state?.auth?.currentUser
  );

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors, isValid },
    setValue,
  } = useForm<VacationFormValues | FieldValues>({
    mode: 'all',
    defaultValues: {
      user_id:
        currentUser && currentUser?.data?.employee
          ? {
              name: `${currentUser?.data.name} ${currentUser?.data.surname}`,
              value: currentUser.data.id,
            }
          : undefined,
    },
  });

  const [absenceQuery, { isLoading, isSuccess }] = useCreateAbsenceMutation();
  const { data: employees } = useGetEmployeesFullListQuery();
  const { currentRole, hasHighAccess } = useRoleHooks();
  const total = calculateDateDifference(watch('start_date'), watch('end_date'));
  const [showDataTable, setShowDataTable] = useState<boolean>(true);

  const onSubmit: SubmitHandler<VacationFormValues | FieldValues> = (data) => {
    absenceQuery({
      ...data,
      total,
      user_id: data.user_id.id ?? currentUser?.data?.id,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setShowDataTable(true);
      toast.success(t('Aplication_Page.Successfully_Created'));
      reset();
    }
  }, [isSuccess]);

  const employeeList = employees?.result?.map((item) => {
    return {
      id: item.userId,
      name: `${item?.user?.name} ${item?.user?.surname}`,
    };
  });

  useEffect(() => {
    if (currentUser && currentUser?.data?.employee) {
      if (hasHighAccess) {
        setValue(
          'user_id',
          currentUser && currentUser?.data?.employee
            ? {
                name: `${currentUser?.data.name} ${currentUser?.data.surname}`,
                value: currentUser.data.id,
              }
            : undefined
        );
      } else {
        setValue(
          'user_id',
          currentUser && currentUser?.data?.employee
            ? `${currentUser?.data.name} ${currentUser?.data.surname}`
            : undefined
        );
      }
    }
  }, [currentUser]); 

  return {
    showDataTable,
    employeeList,
    isLoading,
    isSuccess,
    isValid,
    control,
    errors,
    currentRole,
    total,
    watch,
    onSubmit,
    register,
    handleSubmit,
    setShowDataTable,
  };
};
