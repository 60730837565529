import { useEffect } from 'react';
import { useSwitchCompanyMutation } from 'Services/companiesApi';
import { RootState, useAppSelector } from 'Store/store';

const useDrawerCompaniesHooks = () => {
  const currentUser = useAppSelector(
    (state: RootState) => state?.auth?.currentUser
  );
  const [setActiveCompany, { isSuccess }] = useSwitchCompanyMutation();

  useEffect(() => {
    if (isSuccess) {
      window.location.reload();
    }
  }, [isSuccess]);

  useEffect(() => {
    const companyExists = currentUser?.data?.userRoleInCompany.some(
      (item: any) => item.id === +localStorage.getItem('active-company-id')!
    );
    if (!companyExists) {
      localStorage.removeItem('active-company-id');
    }
  }, [currentUser]);

  return {
    currentUser,
    setActiveCompany,
  };
};

export default useDrawerCompaniesHooks;
