import { useGeneralHooks } from 'Pages/general.hooks';

export const useAppPathsHooks = () => {
  const { t, location } = useGeneralHooks();
  const tmp = location.pathname.split('/');
  const links = tmp.filter(
    (path: string) => path.length > 2 && path !== 'main'
  );

  function setRenderPath(path: string) {
    switch (path) {
      // case "main":
      //     return "";
      case 'userProfile':
        return t('Employees.user');
      case 'documents':
        return t('Render_path_Names.Documents');
      case 'vacation':
        return t('Render_path_Names.Vacation');
      case 'addEmployee':
        return t('Render_path_Names.Documents');
      case 'employees':
        return t('Employees.Add_Employee_Title');
      case 'applications':
        return t('Render_path_Names.Appliacations');
      case 'my_applications':
        return t('LayoutMenu.NavList.My_Applications');
      case 'absence':
        return t('Render_path_Names.Absences');
      case 'pension':
        return t('Render_path_Names.Benefits');
      case 'dismissal':
        return t('Render_path_Names.Dismissal');
      case 'references':
        return t('Render_path_Names.References');
      case 'groups':
        return t('Render_path_Names.Groups');
      case 'createGroup':
        return t('Render_path_Names.Create_Group');
      case 'addMember':
        return t('Render_path_Names.Add_Member');
      case 'markSuperior':
        return t('Render_path_Names.Mark_Superior');
      case 'settings':
        return t('Render_path_Names.Settings');
      case 'make-employee':
        return t('Render_path_Names.Make_Employee');
      case 'my-invitations':
        return t('Render_path_Names.My_Invitations');
      case 'notifications':
        return t('Render_path_Names.Notifications');
      case 'processes':
        return t('Render_path_Names.Processes');
      case 'orders':
        return t('LayoutMenu.NavList.Orders');
      case 'my-tasks':
        return t('LayoutMenu.NavList.My_Tasks');
      default:
        return ' ';
    }
  }
  const pathsToRender = links.map((path: string) => setRenderPath(path));

  return {
    pathsToRender,
  };
};
