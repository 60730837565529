import { ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useGeneralHooks } from 'Pages/general.hooks';
import { AutoComplete, InputField } from 'Components/AuthComponents/AuthInputs';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useCreateDirectoryMutation, useEditDirectoryMutation, useGetDirectoriesListQuery, useGetDirectoriesQuery } from 'Services/employeesApi';
import { ICreateDirectoryData, IDirectory, IDirectoryOption, IEditDirectory } from 'Services/responce_types';
import { DirectoryModalTypes, FormTypes, IDirectoryFormValues, IDirectoryModal } from 'Pages/interfaces';
import { ButtonTypes } from 'Components/interfaces';
import Button from 'Components/Button/button';
import { CloseSVG, DeleteSVG, PencilSVG, RedTrashSVG, PlusSVG } from 'Assets';
import styles from './directories.module.scss';

const initOptionsCount: any[] = ["defaultOption"];
const initModal: IDirectoryModal = { open: false }
const useDirectoriesHooks = () => {
    const { t } = useGeneralHooks();
    const [activePage, setActivePage] = useState<number>(1);
    const { data: DirectoryList, isLoading: getDirectoriesLoading } = useGetDirectoriesListQuery({ offset: activePage, limit: 10 });
    const { data: DirectoriesAsOptions } = useGetDirectoriesQuery();
    const [createDirectory, { isLoading: isCreateLoading }] = useCreateDirectoryMutation();
    const [editDirectory, { isLoading: isEditLoading }] = useEditDirectoryMutation();
    const directories = DirectoryList?.result;
    const total = DirectoryList?.total;
    const [create, setCreate] = useState<boolean>(false);
    const { register, unregister, control, reset, handleSubmit, formState: { errors } } = useForm<IDirectoryFormValues>({ defaultValues: { name: undefined, options: [] } });
    const [optionsCount, setOptionsCount] = useState<any[]>(initOptionsCount)
    const [expanded, setExpanded] = useState<string | false>(false);
    const [hasParentId, setHasParentId] = useState<boolean>(false);
    const [modal, setModal] = useState<IDirectoryModal>(initModal);
    const loading = isCreateLoading || isEditLoading || getDirectoriesLoading;

    useEffect(() => {
        !modal.open && !expanded && window.scrollTo({ top: 0 })
    }, [directories, expanded, modal.open]);

    const createDirectoriesAsOption = useCallback(() => {
        const options = DirectoriesAsOptions?.result.map((el, index) => {
            return {
                name: el.name,
                id: el._id
            }
        });
        return options
    }, [DirectoriesAsOptions])

    const onOptionDelete = (optionNumber: number) => {
        const tmp = [...optionsCount];
        tmp.splice(tmp.indexOf(optionNumber), 1)
        setOptionsCount(tmp)
        unregister(`options.${optionNumber}`)
    };

    const onCreateNew = () => {
        reset();
        setExpanded(false);
        setCreate(true);
        setOptionsCount(initOptionsCount)
    }

    const onClose = () => {
        reset()
        setCreate(false)
        setExpanded(false)
        setHasParentId(false)
        setOptionsCount(initOptionsCount)
    };

    const handleAccordion = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
        reset();
        setHasParentId(false)
        setCreate(false)
        setExpanded(isExpanded ? panel : false);
        setOptionsCount(initOptionsCount)
    };

    const addOption = () => {
        const tmp = [...optionsCount];
        tmp.push("option")
        setOptionsCount(tmp)
    };

    const onOpenAddModal = useCallback((dyrectoryId: number) => {
        const tmp: IDirectoryModal = {
            open: true,
            type: DirectoryModalTypes.ADD_OPTION,
            data: {
                dyrectoryid: dyrectoryId
            }
        }
        setModal(tmp)
    }, []);

    const onOpenDeleteModal = useCallback((dyrectoryId: number, optionId: number, optionName: string) => {
        const tmp: IDirectoryModal = {
            open: true,
            type: DirectoryModalTypes.DELETE_OPTION,
            data: {
                dyrectoryid: dyrectoryId,
                optionId: optionId,
                optionName: optionName
            }
        };
        setModal(tmp)
    }, []);

    const onOpenDirectoryDeleteModal = useCallback((dyrectoryId: number, directoryName: string) => {
        const tmp: IDirectoryModal = {
            open: true,
            type: DirectoryModalTypes.DELETE_DIRECTORY,
            data: {
                dyrectoryid: dyrectoryId,
                directoryName: directoryName
            }
        };
        setModal(tmp)
    }, []);

    const onOpenEditModal = useCallback((dyrectoryId: number, optionName: string, optionId: number) => {
        const tmp: IDirectoryModal = {
            open: true,
            type: DirectoryModalTypes.EDIT_OPTION,
            data: {
                dyrectoryid: dyrectoryId,
                optionName: optionName,
                optionId: optionId
            }
        };
        setModal(tmp)
    }, [])

    const onCloseModal = useCallback(() => {
        setModal(initModal)
    }, []);

    const renderDirectoryOptions = (dyrectoryId: number, defaultOptions: IDirectoryOption[]) => {
        let defaultOptionsCount: any[] = defaultOptions?.map((option) => option._id);
        const options = defaultOptionsCount.map((item, index) => {
            return (
                <div className={styles.directoryOpton} key={item + index}>
                    <div>{defaultOptions?.[index]?.name!}</div>
                    <div className={styles.inputIcons}>
                        <div className={styles.inputIcon}>
                            <img onClick={() => onOpenEditModal(dyrectoryId!, defaultOptions?.[index]?.name!, item)} src={PencilSVG} alt='EditIcon' className={styles.inputIcon} />
                        </div>
                        {index !== 0 &&
                            <div className={styles.inputIcon}>
                                <img onClick={() => onOpenDeleteModal(dyrectoryId!, item, defaultOptions?.[index]?.name!)} src={RedTrashSVG} alt='deleteIcon' className={styles.inputIcon} />
                            </div>
                        }
                    </div>
                </div>
            )
        })
        return options
    }

    const renderOptionInputs = (optionCount: any[]): ReactNode => {
        const options = optionCount.map((item, index) => {
            return (
                <InputField
                    key={item + index}
                    register={register}
                    registerName={`options[${index}]`}
                    label={`${t('Directories.Option')} ${index + 1}`}
                    placeholder={t('Directories.Placeholder')}
                    inputStyle={styles.input}
                    error={errors.options?.[`${+item + index}`]}
                    children={index !== 0
                        &&
                        <img onClick={() => onOptionDelete(item)} src={DeleteSVG} alt='deleteIcon' />
                    }
                />
            );
        });
        return options
    };

    const onCreate: SubmitHandler<IDirectoryFormValues | FieldValues> = async (values) => {
        const revisedOptions: string[] = values.options!.filter((option: string) => option && option);
        const options = revisedOptions.map((option) => { return { name: option } })
        const payload: ICreateDirectoryData = {
            name: values.name!,
            options: options as IDirectoryOption[],
            parent_id: values.parentId?.id!
        }
        await createDirectory({ ...payload })
        setOptionsCount(initOptionsCount)
        reset()
        setCreate(false)
        setHasParentId(false)
        window.scrollTo({ top: 0 })
    };

    const onEdit = async (values: IDirectoryFormValues, directory: IDirectory) => {
        if (values.name !== directory.name || values.parentId?.id! !== directory.parent?.name!) {
            const payload: IEditDirectory = {
                id: String(directory._id),
                data: {
                    name: values.name!,
                    parent_id: values.parentId?.id!
                }
            }
            editDirectory(payload)
        }
        setOptionsCount(initOptionsCount)
        reset()
        setCreate(false)
        setHasParentId(false)
        setExpanded(false)
        window.scrollTo({ top: 0 })
    };


    const createDirectoryForm = (type: FormTypes, data?: IDirectory) => {
        return (
            <div className={type === FormTypes.EDIT ? styles.editForm : styles.formContainer}>
                {type === FormTypes.CREATE &&
                    <div className={styles.formHeader}>
                        <div className={styles.formTitle}>{t('Groups.Create')}</div>
                        <img onClick={onClose} src={CloseSVG} alt='closeIcon' className={styles.closeIcon} />
                    </div>}
                <form className={styles.form} onSubmit={type === FormTypes.CREATE ? handleSubmit(onCreate) : handleSubmit(values => onEdit(values, data!))}>
                    <InputField
                        register={register}
                        registerName={"name"}
                        defaultValue={data?.name!}
                        label={t('Directories.Label')}
                        placeholder={t('Employee_Settings.Placeholders.Name')}
                        required
                        inputStyle={styles.input}
                    />

                    <div className={styles.radioButtons}>
                        <label htmlFor="parentId" className={styles.radioLabel}>{t('Directories.Radio_Label')}</label>

                        <RadioGroup
                            row
                            aria-labelledby="parentId"
                            defaultValue={type === FormTypes.EDIT ? (data?.parent?._id! ? true : false) : undefined}
                        >
                            <FormControlLabel value="true" onChange={() => setHasParentId(true)} control={<Radio />} label={t(`Radio_Buttons.Yes`)} />
                            <FormControlLabel value="false" onChange={() => setHasParentId(false)} control={<Radio />} label={t(`Radio_Buttons.No`)} />
                        </RadioGroup>
                    </div>
                    {(hasParentId || data?.parent?._id!)
                        &&
                        <>
                            <Controller
                                control={control}
                                name='parentId'
                                rules={{
                                    required: t('Input_Errors.Required'),
                                }}
                                render={({ field: { onChange, name, value } }) => {
                                    return (
                                        <AutoComplete
                                            value={hasParentId ? null : data?.parent}
                                            name={name}
                                            onChange={onChange}
                                            id='parentId'
                                            data={createDirectoriesAsOption()}
                                            label={t('Directories.ParentId_Label')}
                                            placeholder={t('Directories.ParentId_PlaceHolder')}
                                            error={errors.parentId}
                                        />
                                    );
                                }}
                            />
                        </>
                    }
                    <div className={styles.optionList}>
                        <div className={styles.optionsLabel}>
                            {type === FormTypes.CREATE ? t('Directories.Options_label') : t('Directories.Options')}
                        </div>
                        {type === FormTypes.CREATE ? renderOptionInputs(optionsCount) : renderDirectoryOptions(data?._id!, data?.options!)}

                    </div>
                    <div className={styles.addIconRow}>
                        <div className={styles.addIcon} onClick={data ? () => onOpenAddModal(data._id) : addOption}>
                            <img src={PlusSVG} alt='editSvg' />
                            <div className={styles.addText}>{t('Groups.Add_Member_Mobile')}</div>
                        </div>
                    </div>
                    <div className={styles.buttonRow} >
                        <Button
                            type='button'
                            title={type === FormTypes.CREATE ? t('Employee_History.Cancele') : t('Employee_Settings.Delete')}
                            buttonType={ButtonTypes.WithoutBg}
                            onClick={type === FormTypes.CREATE ? onClose : () => onOpenDirectoryDeleteModal(data?._id!, data?.name!)}
                            buttonStyle={styles.button}
                        />
                        <Button
                            type='submit'
                            title={type === "Edit" ? t('Employee_History.Save') : t('Groups.Create')}
                            buttonType={ButtonTypes.Primery}
                            buttonStyle={styles.button}
                        />
                    </div>
                </form>
            </div>)
    }



    return {
        directories,
        create,
        expanded,
        optionsCount,
        errors,
        loading,
        activePage,
        modal,
        total,
        handleAccordion,
        handleSubmit,
        register,
        renderOptionInputs,
        createDirectoryForm,
        addOption,
        setCreate,
        onClose,
        onCreateNew,
        setActivePage,
        onCloseModal,
        onOpenDirectoryDeleteModal
    }
}

export default useDirectoriesHooks
