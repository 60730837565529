import { useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  useChangeDismissalStatusMutation,
  useGetDismissalAvailableStatusesQuery,
  useGetDismissalByIdQuery,
} from 'Services/Applications/dismissalApi';
import { useApplicationItem } from 'Components/Application/ApplicationItems/applicationItem.hooks';
import { useCustomModalHooks } from 'Components/CustomModal/customModal.hooks';
import { CurrentStatus } from 'Components/Application/ApplicationItems';
import { IVacationStatusChangeValues } from 'Pages/interfaces';
import {
  IAvailableStatusesRespResult,
  IChangeStatusData,
} from 'Services/responce_types';
import { IAppDetailInfo } from 'Components/interfaces';
import { useGeneralHooks } from 'Pages/general.hooks';
import useRoleHooks from 'Routers/role-hooks';
import { Link } from 'react-router-dom';

const useDismissalDetailHooks = () => {
  const { t, params } = useGeneralHooks();
  const { formatDate } = useApplicationItem();
  const { data: dismissalByIdInfo, isFetching } = useGetDismissalByIdQuery(
    +params.id!
  );

  const { currentRole } = useRoleHooks();
  const [changeDismissalStatus, { isSuccess }] =
    useChangeDismissalStatusMutation();
  const { data: vacationAvailableStatuses } =
    useGetDismissalAvailableStatusesQuery(
      +dismissalByIdInfo?.result?.status?.id!
    );
  const { openModal, handleOpen, handleClose } = useCustomModalHooks();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<IVacationStatusChangeValues | FieldValues>({ mode: 'all' });

  const onSubmit: SubmitHandler<IVacationStatusChangeValues | FieldValues> = (
    data
  ) => {
    const payload: IChangeStatusData = {
      id: +params.id!,
      description: data.description,
      status_to_id: data.status_id.id,
      status_from_id: dismissalByIdInfo?.result?.status?.id!,
      userId: dismissalByIdInfo?.result?.user?.id,
    };
    changeDismissalStatus(payload);
  };

  const statuses = vacationAvailableStatuses?.result?.map(
    (item: IAvailableStatusesRespResult) => ({
      name: t(`Statuses_From_Server.${item.statusTo.name}`),
      id: item.statusTo.id,
    })
  );

  const onClose = () => {
    reset({
      description: null,
      status_to_id: null
    });
    handleClose()
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        t('Aplication_Page.App_Status.Status_Successfully_Changed')
      );
      reset();
      handleClose();
    }
  }, [isSuccess]);

  const renderItemData: IAppDetailInfo[] = [
    {
      label: t('Aplication_Page.AppHistory.Name'),
      value:
        <Link to={`/main/employees/${dismissalByIdInfo?.result?.user?.id}`}>
          {dismissalByIdInfo?.result?.user?.name} {dismissalByIdInfo?.result?.user?.surname}
        </Link>,
    },
    {
      label: t('Aplication_Page.Vacation.Start'),
      value: formatDate(dismissalByIdInfo?.result?.startDate!),
    },
    {
      label: t('Aplication_Page.Vacation.End'),
      value: formatDate(dismissalByIdInfo?.result?.endDate!),
    },
    {
      label: t('Aplication_Page.Vacation.Status'),
      value: (
        <CurrentStatus
          onClick={currentRole !== 'EMP' ? handleOpen : () => { }}
          statusName={t(
            `Statuses_From_Server.${dismissalByIdInfo?.result?.status?.name}`
          )}
          bgColor={dismissalByIdInfo?.result?.status?.color!}
        />
      ),
    },
    {
      label: t('Aplication_Page.Desmissal.Reason'),
      value: `${dismissalByIdInfo?.result?.grounds}`,
    },
  ];

  return {
    vacationAvailableStatuses,
    dismissalByIdInfo,
    renderItemData,
    isFetching,
    openModal,
    statuses,
    control,
    params,
    errors,
    onSubmit,
    register,
    onClose,
    handleSubmit,
  };
};

export default useDismissalDetailHooks;
