import React, { Dispatch, SetStateAction } from 'react';
import { Trans } from 'react-i18next';
import {t} from 'i18next';
import { FormTypes, IEmployeeProperty, IEmployeeTPropartyTemplate, IPropertyModal } from 'Pages/interfaces';
import { Button, CustomModal, Loading } from 'Components';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ButtonTypes, EmployeePropertyTypes } from 'Components/interfaces';
import NoData from 'Components/NoData/noData';
import CustomPagination from 'Components/Pagination/pagination';
import { BlueTrashSVG } from 'Assets';
import styles from '../employeeProperties.module.scss';

interface IProps {
    properties: IEmployeeProperty[],
    total:number,
    activePage:number,
    setActivePage:Dispatch<SetStateAction<number>>,
    loading:boolean,
    expanded:string | boolean,
    currentTemplate: IEmployeeProperty,
    modal: IPropertyModal,
    DeleteLoading: boolean,
    createTemlateConfigForm: any,
    propertyTypes: IEmployeeTPropartyTemplate[],
    checkedType: number | undefined
    onCloseModal: () => void,
    onOpenModal: (title: string, id: string) => void,
    handleAccordion: (panel: string) => (event: React.SyntheticEvent<Element, Event>, isExpanded: boolean) => void,
    onTemplateDelete: (id: string) => Promise<void>,
    onTypeSelect: (type: EmployeePropertyTypes, index: number) => void
};

const EmployeePropertiesLarge:React.FC<IProps> = (props) => {
    const {
        properties,
        total,
        activePage,
        setActivePage,
        loading,
        expanded,
        modal,
        DeleteLoading,
        createTemlateConfigForm,
        currentTemplate,
        propertyTypes,
        checkedType,
        handleAccordion,
        onOpenModal,
        onCloseModal,
        onTemplateDelete,
        onTypeSelect
    } = props;

  return (
    <div className={styles.page}>
    <div className={styles.body}>
        <div className={styles.header}>
            <div className={styles.title}>
                {t(`Employee_Settings.Employee_Info`)}
            </div>
            <div className={styles.describtiom}>
                {t(`Employee_Settings.Describtion`)}
            </div>
        </div>
        {loading
            ?
            <div>
                <Loading />
            </div>
            :
            (
                total > 0
                    ?
                    <div className={styles.configList}>
                        {properties.map((property, index) => {
                            return (
                                <Accordion
                                    key={property.key! + index}
                                    expanded={expanded === property.title!}
                                    onChange={handleAccordion(property.title!)}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={property.title}
                                        id={property.title}
                                    >
                                        <div className={styles.summary} >
                                            <div className={styles.directoryTittle}>{property.title}</div>
                                            <div className={styles.inputIcon}>
                                                <img
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onOpenModal(property.title!, property._id!)
                                                    }}
                                                    src={BlueTrashSVG}
                                                    alt='deleteIcon'
                                                    className={styles.inputIcon}
                                                />
                                            </div>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {expanded === property.title! && createTemlateConfigForm(property, FormTypes.EDIT)}
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                        {currentTemplate.type && createTemlateConfigForm(currentTemplate, FormTypes.CREATE)}
                        <CustomModal
                            open={modal.open}
                            title={t('Employee_Settings.Delete_Template')}
                            handleClose={onCloseModal}
                            dialogClassname={"dyrectoryModal"}
                            loading={DeleteLoading}
                            children={
                                <div className={styles.modalInner}>
                                    <div className={styles.description}>
                                        <Trans
                                            components={{ span: <span className={styles.styledText} /> }}
                                        >
                                            {t('Employee_Settings.Delete_Template_Text', { templateName: modal.title! })}
                                        </Trans>
                                    </div>
                                    <div className={styles.buttonRow}>
                                        <Button
                                            buttonType={ButtonTypes.WithoutBg}
                                            buttonStyle={styles.button}
                                            title={t('Employee_History.Cancele')}
                                        />
                                        <Button
                                            buttonType={ButtonTypes.Primery}
                                            buttonStyle={styles.button}
                                            title={t('Edit_User.Change')}
                                            onClick={() => onTemplateDelete(modal.id!)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                          {total! > 10 && (
                                <CustomPagination
                                    count={Math.ceil(
                                        total! / 10
                                    )}
                                    page={activePage}
                                    onChange={(_, page) => {
                                        setActivePage(page);
                                        window.scrollTo(0, 0);
                                    }}
                                />
                            )}
                    </div>
                    :
                    (currentTemplate.type
                        ?
                        createTemlateConfigForm(currentTemplate, FormTypes.CREATE)
                        :
                        <NoData
                            withButton={false}
                            title={t('Employee_Settings.No_Template')}
                        />
                    )
            )
        }

    </div>
    <div className={styles.rightSideContainer}>
        <div className={styles.types}>
            {propertyTypes.map((item, index) => {
                return (
                    <div className={checkedType! === index ? styles.checkedTypeItem : styles.typeItem} key={item.name + index} onClick={() => onTypeSelect(item.type, index)}>
                        <div>{item.icon}</div>
                        <div>
                            <span>
                                {item.name}
                            </span>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>
</div>
  )
}

export default EmployeePropertiesLarge
