import { useState } from "react";

export interface IHierarchyData {
    id?: string,
    orgName?: string;
    employee?: any;
    children: IHierarchyData[] | [],
    parentId?: string,
    type?: HierarchyItemTypes,
    sectionName?: string,
    sectionImg?: string,
    sectionRespPersonId?: string,
    sectionColor?: string
};

export enum HierarchyModalTypes {
    START = "start",
    ADD = "add",
    DELETE = 'delete'
};

export enum HierarchyItemTypes {
    SECTION = "section",
    ROLE = 'role'
}

export interface IHierachyModal {
    open: boolean,
    type?: HierarchyModalTypes,
    parentId?: string,
    id?: string
};


const initHierarchyData: IHierarchyData = {
    orgName: undefined,
    employee: undefined,
    children: [],
};

const initModal: IHierachyModal = { open: false }
const useHierarchyHooks = () => {
    const [hierarchyData, setHierarchyData] = useState<IHierarchyData>(initHierarchyData);
    const [modal, setModal] = useState<IHierachyModal>(initModal);
    const [scaleRatio, setScaleRatio] = useState<number>(1);
    const sliderMarks = [
        {
            value: 10,
            label: '10%'
        },
        {
            value: 50,
            label: '50%'
        },
        {
            value: 100,
            label: '100%'
        }
    ]
    const onStart = () => {
        const tmp: IHierachyModal = {
            open: true,
            type: HierarchyModalTypes.START
        };
        setModal(tmp)
    };

    const onAdd = (parentID?: string) => {
        const tmp: IHierachyModal = {
            open: true,
            type: HierarchyModalTypes.ADD,
            parentId: parentID!
        };
        setModal(tmp)
    };

    const onDelete = (id: string) => {
        const tmp: IHierachyModal = {
            open: true,
            type: HierarchyModalTypes.DELETE,
            id: id
        }
        setModal(tmp)
    };

    const onToggleBodySize = (value: number) => {
        const ratio = value / 100;
        setScaleRatio(ratio)
    }

    const onGetHierarchyItem = (id: string): IHierarchyData | null => {
        const findNodeById = (data: IHierarchyData, id: string): IHierarchyData | null => {
            if (data.id === id) {
                return data;
            }
            if (data.children) {
                for (let child of data.children) {
                    const result = findNodeById(child, id);
                    if (result) {
                        return result;
                    }
                }
            }
            return null;
        };
        const data = JSON.parse(localStorage.getItem('hierarchyData')!)

        return findNodeById(data, id);
    };

    const onCloseModal = () => {
        setModal(initModal)
    };

    const handleNewTabOpen = (id: string) => {
        const url = `${window.location.href}/${id}`
        const newTab = window.open(url, '_blank');

        if (newTab) {
            newTab.focus();
        } else {
            console.error('Failed to open new tab');
        }
    };

    return {
        hierarchyData,
        scaleRatio,
        sliderMarks,
        modal,
        onStart,
        onAdd,
        onDelete,
        onCloseModal,
        setHierarchyData,
        onGetHierarchyItem,
        handleNewTabOpen,
        onToggleBodySize,
    }
};

export default useHierarchyHooks