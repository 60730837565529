import { t } from 'i18next';
import Button from 'Components/Button/button';
import Employee from 'Assets/Images/Employee.png';
import { ButtonTypes } from 'Components/interfaces';
import useChatHooks from 'Components/GroupChat/chat.hooks';
import UseChooseChatMembersHooks from './choseChatMembers.hooks';
import { IEmployeeRespDataEmployees } from 'Services/responce_types';
import styles from '../groupChat.module.scss';

interface IProps {
  setShow: (val: string) => void;
}

const ChoseChatMembers: React.FC<IProps> = (props) => {
  const { choosen, employees, currentUser, setChoosen } = useChatHooks();
  const { chosenMembers, checkMembers } = UseChooseChatMembersHooks();

  return (
    <div>
      {employees?.result
        ?.filter(
          (item: IEmployeeRespDataEmployees) =>
            item?.userId !== currentUser?.data?.id
        )
        ?.map((item: IEmployeeRespDataEmployees) => {
          return (
            <div
              className={
                chosenMembers?.includes(item?.userId?.toString())
                  ? styles['user-container-active']
                  : styles['user-container-inactive']
              }
              onClick={() => {
                setChoosen(!choosen);
                checkMembers(item?.userId?.toString());
              }}
              key={item?.id}
            >
              <img
                src={
                  item?.user?.avatar
                    ? `${process.env.REACT_APP_API_KEY}${item?.user?.avatar}`
                    : Employee
                }
                alt='userAvatar'
              />
              <div className={styles['name']}>
                {item?.user?.name} {item?.user?.surname}
              </div>
            </div>
          );
        })}
      <Button
        title={t('Employee_History.Save')}
        onClick={() => {
          props.setShow('groupName');
        }}
        buttonType={ButtonTypes.Primery}
        disable={chosenMembers.length > 0 ? false : true}
      />
    </div>
  );
};

export default ChoseChatMembers;
