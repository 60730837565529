import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { InvitationsStatus } from 'Components/interfaces';
import {
  useAcceptOrRejectInvitationMutation,
  useGetMyInvitationsQuery,
} from 'Services/generalApi';
import styles from './myInvitations.module.scss';
import InviteButtons from './inviteButtons';
import { useGeneralHooks } from 'Pages/general.hooks';
import { getMe } from 'Store/Slices/authSlice';

const UseMyInvitationsHooks = (id?: number) => {
  const [page, setPage] = useState<number>(1);
  const [acceptOrDeclineInvitationReq, { isSuccess }] =
    useAcceptOrRejectInvitationMutation();
  const { data, isLoading, refetch } = useGetMyInvitationsQuery({
    page,
    offset: 10,
  });
  const { dispatch } = useGeneralHooks();

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('Forget_Password.Fourth_page.Description'));
      dispatch(getMe());
      refetch();
    }
  }, [isSuccess]);

  const InvitationStatuses = useMemo(() => {
    return {
      [InvitationsStatus.Pending]: <InviteButtons invitationId={id} />,
      [InvitationsStatus.Accepted]: (
        <div className={styles['accepted_text']}>
          {t('InvitationStatuses.accepted')}
        </div>
      ),
      [InvitationsStatus.Rejected]: (
        <div className={styles['rejected_text']}>
          {t('InvitationStatuses.rejected')}
        </div>
      ),
      [InvitationsStatus.Canceled]: (
        <div className={styles['canceled_text']}>
          {t('InvitationStatuses.canceled')}
        </div>
      ),
    };
  }, [data?.result]);

  return {
    page,
    isLoading,
    data,
    InvitationStatuses,
    acceptOrDeclineInvitationReq,
    setPage,
  };
};

export default UseMyInvitationsHooks;
