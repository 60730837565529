import React from "react";
import { Link } from "react-router-dom";
import { useAppPathsHooks } from "Components/BreadCrumb/breadCrumb.hooks";
import useMobileNavDrawerHooks from "Components/MobileNavDrawer/mobileNavDrawer-hooks";
import { SearchInput } from "Components/AuthComponents/AuthInputs";
import BreadCrumbs from "Components/BreadCrumb/breadCrumbs";
import { GroupList } from "Components/Groups/groupList";
import { useGeneralHooks } from "Pages/general.hooks";
import { HeaderTypes } from "Components/interfaces";
import useRoleHooks from "Routers/role-hooks";
import { Header, MobileNavDrawer } from "Components";
import { MenuSVG } from "Assets";
import styles from "./groups.module.scss";

const Groups: React.FC = () => {
  const { pathsToRender } = useAppPathsHooks();
  const { t } = useGeneralHooks();
  const { open, setOpen, onDrawerClose } = useMobileNavDrawerHooks();
  const { currentRole } = useRoleHooks();

  return (
    <>
      {window.innerWidth < 920 ? (
        <>
          <Header
            type={HeaderTypes.Navigation}
            navTitle={t("Groups.Groups")}
            icon={MenuSVG}
            iconOnClick={() => setOpen(!open)}
          />
          <MobileNavDrawer open={open} onClose={onDrawerClose} />
          <div className={styles["mobile-group-content"]}>
            <GroupList />
            {(currentRole === "HR" || currentRole === "CEO") && (
                <Link 
                  to="createGroup"
                  className={styles["create-new-group-btn"]}
                >
                  {t("Groups.Create_New_Group_Btn")}
                </Link>
              )}
          </div>
        </>
      ) : (
        <div className={styles["groups-page"]}>
          <div className={styles["searchContainer"]}>
            <BreadCrumbs data={pathsToRender} />
            <SearchInput />
          </div>
          <div className={styles["heading"]}>
            <p className={styles["title"]}>{t("Groups.Groups")}</p>
            {currentRole === "HR" ||
              (currentRole === "CEO" && (
                <Link
                  to="createGroup"
                  className={styles["create-new-group-btn"]}
                >
                  {t("Groups.Create_New_Group_Btn")}
                </Link>
              ))}
          </div>
          <GroupList />
        </div>
      )}
    </>
  );
};

export default Groups;
