import { useEffect, useMemo, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { Channel, ChannelFilters, StreamChat } from 'stream-chat';
import { ChatScreenTypes, IGroupChatValues } from 'Pages/interfaces';
import { RootState, useAppSelector } from 'Store/store';
import { useGetEmployeesFullListQuery } from 'Services/employeesApi';
import ChoseChatMembers from './ChoseMembers/choseChatMembers';
import CreateGroupChat from './createGroupChat';
import { ChannelPreviewUIComponentProps, DefaultStreamChatGenerics } from 'stream-chat-react';
import { useSearchParams } from 'react-router-dom';

const useChatHooks = (cb?: () => void) => {
  const currentUser = useAppSelector((state: RootState) => state?.auth?.currentUser);
  const chosenMembers = useAppSelector((state: RootState) => state?.chat?.usersToConnectGroupChat);
  const [screenType, setScreenType] = useState<ChatScreenTypes>(ChatScreenTypes.CHANNEL_LIST);
  const [searchParams, setSearchParams] = useSearchParams();
  const messagingMemberID = searchParams!.get("id");
  const newClient = StreamChat.getInstance('upshy6pu477x');
  const [channel, setChanel] = useState<any>(undefined);
  const onChannelSelect = (props: ChannelPreviewUIComponentProps<DefaultStreamChatGenerics>) => {
    setScreenType(ChatScreenTypes.CHAT);
    setChanel(props?.channel);
    if (messagingMemberID) {
      setSearchParams(undefined)
    }
  };
  const { data: employees } = useGetEmployeesFullListQuery();

  // getStream chat logic
  const filters: ChannelFilters<DefaultStreamChatGenerics> = {
    members: {
      $in: [currentUser?.data?.id?.toString()!],
    },
  };

  useEffect(() => {
    const handleConnectionChange = ({ online = false }) => {
      if (!online) return console.log('connection lost');
    };
    newClient.on('connection.changed', handleConnectionChange);
  }, [currentUser,newClient]);

  useEffect(() => {
    if (messagingMemberID) {
      const currentChannel = newClient.activeChannels[`messaging:${messagingMemberID}`]
      setScreenType(ChatScreenTypes.CHAT)
      setChanel(currentChannel)
    }
  }, [messagingMemberID])
  
  const channelFilterFunction = (channelList: Channel<DefaultStreamChatGenerics>[]) => {
    const currentChannelList =  channelList.filter((channel) => {
      return channel?.data?.activeCompanyId! === currentUser?.data?.activeCompanyId
        ||
        channel?.type! === "messaging"
    });
    // if(!messagingMemberID){
    //   setChanel(currentChannelList[0])
    // };
    return currentChannelList
  }
  const [show, setShow] = useState<string>('chatMembers');
  const [choosen, setChoosen] = useState<boolean>(false);

  const onClose = () => {
    reset({
      image: null,
      groupName: '',
    });
    setShow('chatMembers');
    cb && cb();
  };

  const { register, handleSubmit, reset, control } = useForm<
    IGroupChatValues | FieldValues
  >({ mode: 'all' });
  const onSubmit: SubmitHandler<IGroupChatValues | FieldValues> = async (
    data
  ) => {
    try {
      const channelType = 'group';
      const channelId = `${channelType}-${Date.now()}`;
      const channel = newClient.channel(channelType, channelId, {
        members: [...chosenMembers, currentUser?.data?.id?.toString()],
        name: data?.groupName,
        image: data?.image?.result
          ? `${process.env.REACT_APP_API_KEY}${data?.image?.result}`
          : null,
        activeCompanyId: currentUser?.data?.activeCompanyId ?? null,
      });
      await channel.watch();
      onClose();
      setShow('chatMembers');
    } catch (err) {
      console.log('err', err);
    }
  };
  const CurrentModalComponent = useMemo(() => {
    if (show === 'chatMembers') {
      return <ChoseChatMembers setShow={setShow} />;
    } else if (show === 'groupName') {
      return (
        <CreateGroupChat
          setShow={setShow}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          control={control}
          register={register}
        />
      );
    }
  }, [show, setShow]);

  return {
    filters,
    newClient,
    onClose,
    setShow,
    employees,
    CurrentModalComponent,
    currentUser,
    setChoosen,
    choosen,
    control,
    channel,
    screenType,
    channelFilterFunction,
    setScreenType,
    onChannelSelect,
    handleSubmit,
    onSubmit,
    register,
  };
};

export default useChatHooks;
