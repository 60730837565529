import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../API';
import {
  IAvailableStatusesResp,
  IChangeStatusData,
  ICreateDismissal,
  ICreateDismissalResp,
  IDismissalByIdResp,
  IDismissalListResp,
  IPaginationQuery,
} from '../responce_types';

export const dismissalApi = createApi({
  reducerPath: 'dismissalApi',
  tagTypes: ['Dismissal'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_KEY,
  }),
  endpoints: (builder) => ({
    createDismissal: builder.mutation<ICreateDismissalResp, ICreateDismissal>({
      query: (dismissalData) => ({
        url: '/dismissal',
        method: 'POST',
        data: dismissalData,
      }),
      invalidatesTags: ['Dismissal'],
    }),
    getDismissalById: builder.query<IDismissalByIdResp, number>({
      query: (id) => ({
        url: `/dismissal/${id}`,
        method: 'GET',
      }),
      providesTags: ['Dismissal'],
    }),
    getDismissalList: builder.query<IDismissalListResp, IPaginationQuery>({
      query: ({ page, offset }) => ({
        url: `/dismissal/${offset}/${page}`,
        method: 'GET',
      }),
      providesTags: ['Dismissal'],
    }),
    getUserDismissalList: builder.query<IDismissalListResp, IPaginationQuery>({
      query: ({ page, offset }) => ({
        url: `/dismissal/my_list/${offset}/${page}`,
        method: 'GET',
      }),
      providesTags: ['Dismissal'],
    }),
    getDismissalAvailableStatuses: builder.query<IAvailableStatusesResp, number>({
      query: (id) => ({
        url: `/dismissal/available_statuses/${id}`,
        method: "GET",
      }),
    }
  ),
  changeDismissalStatus: builder.mutation<number, IChangeStatusData>({
    query: (data) => ({
      url: `/dismissal/update_status/${data?.id}`,
      method: "PUT",
      data: {
        description: data.description,
        status_from_id: data.status_from_id,
        status_to_id: data.status_to_id,
        userId: data?.userId
      },
    }),
    invalidatesTags: ['Dismissal'],
  }),
  }),
});

export const {
  useCreateDismissalMutation,
  useGetDismissalByIdQuery,
  useGetDismissalListQuery,
  useGetUserDismissalListQuery,
  useChangeDismissalStatusMutation,
  useGetDismissalAvailableStatusesQuery,
} = dismissalApi;
