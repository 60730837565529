import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useGeneralHooks } from "Pages/general.hooks";
import { useDeleteEmployeeMutation } from "Services/employeesApi";
import makeEmployeeIcon from "../../Assets/Icons/make-employee.svg";
import styles from "./employeeProfile.module.scss";
import useRoleHooks from "Routers/role-hooks";

interface IProps {
  employeeId: number;
  employeeInfo: any;
  id: number | undefined
}

const EmployeeProfileButtons: React.FC<IProps> = (props) => {
  const { employeeId, employeeInfo, id } = props;
  const { t, navigate } = useGeneralHooks();
  const [deleteEmployee, { isSuccess }] = useDeleteEmployeeMutation();
  const {currentRole} = useRoleHooks()

  useEffect(() => {
    if (isSuccess) {
      navigate("/main/employees");
      toast.success(t("Employees.Employee_Deleted_Successfully"));
    }
  }, [isSuccess]);

  return (
    <>
      {/* <Button
        onClick={() => {
          navigate('markSuperior');
        }}
        buttonType={ButtonTypes.WithoutBg}
        title={t('Employees.Superior_Button')}
      /> */}
      {!employeeInfo.employee && currentRole === "CEO" && (
        <div
          onClick={() =>
            navigate(`/main/employees/${id}/make-employee`)
          }
          className={styles["make-employee-btn__container"]}
        >
          <img src={makeEmployeeIcon} alt="makeEmployeeIcon" />
          <span className={styles["make-employee-btn__text"]}>
            {t("Make_Employee.Title")}
          </span>
        </div>
      )}
      {/* {employeeInfo.employee && (
        <div
          onClick={() => deleteEmployee(employeeId!)}
          className={styles["remove-employee-btn__container"]}
        >
          <img src={fireEmployeeIcon} alt="fireEmployeeIcon" />
          <span className={styles["make-employee-btn__text"]}>
            {t("Employees.Fire_Button")}
          </span>
        </div>
      )} */}
    </>
  );
};
export default EmployeeProfileButtons;
