import { useEffect } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { t } from 'i18next';
import { DirectoryModalTypes, IDirectoryEditFormValues, IDirectoryModal } from "Pages/interfaces";
import { ButtonTypes } from "Components/interfaces";
import { InputField } from "Components/AuthComponents/AuthInputs";
import Button from "Components/Button/button";
import styles from './directoryModal.module.scss';
import { useAddOptionMutation, useDeleteDirectoryMutation, useDeleteOptionMutation, useEditOptionMutation } from "Services/employeesApi";
import { IAddOption, IEditOption } from "Services/responce_types";
import { Trans } from "react-i18next";
import { toast } from "react-toastify";

const useDirectoryModalHooks = (modal: IDirectoryModal, onClose: () => void) => {
    const { handleSubmit, register, reset, formState: { errors } } = useForm<IDirectoryEditFormValues>();
    const [deleteDiectory, { isLoading: deleteLoading, isError: deleteDirectoryError }] = useDeleteDirectoryMutation();
    const [editOption, { isLoading: editOptionLoading, isError: editOPtionError }] = useEditOptionMutation();
    const [deleteOption, { isLoading: deleteOptionLoading, isError: deleteOptionError }] = useDeleteOptionMutation();
    const [addNewOption, { isLoading: isAddOptionLoading, isError: addOptionError }] = useAddOptionMutation();

    useEffect(() => {
        reset()
    }, [modal]);


    const onDeleteDirectory: SubmitHandler<IDirectoryEditFormValues | FieldValues> = async (values) => {
        await deleteDiectory(modal.data?.dyrectoryid!);
        deleteDirectoryError || onClose()
    };

    const onAddOPtion: SubmitHandler<IDirectoryEditFormValues | FieldValues> = async (values) => {
        const payload: IAddOption = {
            directory_id: modal.data?.dyrectoryid!,
            name: values.newOption
        }
        await addNewOption(payload)
        !addOptionError && onClose()
    };

    const onDeleteOption: SubmitHandler<any | FieldValues> = async () => {
        await deleteOption(modal.data?.optionId!);
        !deleteOptionError && onClose()
    };

    const onEditOption: SubmitHandler<IDirectoryEditFormValues | FieldValues> = async (values) => {
        if (values.editOption !== modal.data?.optionName!) {
            const payload: IEditOption = {
                id: modal.data?.optionId!,
                data: {
                    name: values.editOption
                }
            }
            await editOption(payload)
            !editOPtionError ? onClose() : toast.error("Error")

        } else {
            onClose()
        }
        reset()
    };

    const MODAL_INNER = {
        [DirectoryModalTypes.ADD_OPTION]: {
            title: t('Directories.New_Option'),
            body: (
                <div className={styles.body}>
                    <InputField
                        register={register}
                        registerName='newOption'
                        label={t('Directories.Option')}
                        placeholder={t('Directories.Placeholder')}
                        required
                        error={errors.newOption}
                    />
                    <div className={styles.buttonsRow}>
                        <Button
                            buttonType={ButtonTypes.WithoutBg}
                            title={t('Employee_History.Cancele')}
                            buttonStyle={styles.button}
                            onClick={onClose}
                        />
                        <Button
                            buttonType={ButtonTypes.Primery}
                            title={t('Employee_History.Save')}
                            buttonStyle={styles.button}
                            type='submit'
                        />
                    </div>
                </div>
            ),
            submiteHandler: onAddOPtion,
            loading: isAddOptionLoading
        },
        [DirectoryModalTypes.DELETE_OPTION]: {
            title: t('Directories.Delete_Option'),
            body: (
                <div className={styles.body}>
                    <div className={styles.description}>
                        <Trans
                            components={{ span: <span className={styles.styledText} /> }}
                        >
                            {t('Directories.Delete_Option_Text', { optionName: modal.data?.optionName! })}
                        </Trans>
                    </div>
                    <div className={styles.buttonsRow}>
                        <Button
                            buttonType={ButtonTypes.WithoutBg}
                            title={t('Employee_History.Cancele')}
                            buttonStyle={styles.button}
                            onClick={onClose}
                        />
                        <Button
                            buttonType={ButtonTypes.Primery}
                            title={t('Edit_User.Change')}
                            buttonStyle={styles.button}
                            type='submit'
                        />
                    </div>
                </div>
            ),
            submiteHandler: onDeleteOption,
            loading: deleteOptionLoading
        },
        [DirectoryModalTypes.DELETE_DIRECTORY]: {
            title: t('Directories.Delete_Directory'),
            body: (
                <div className={styles.body}>
                    <div className={styles.description}>
                        <Trans
                            components={{ span: <span className={styles.styledText} /> }}
                        >
                            {t('Directories.Delete_Directory_Text', { directoryName: modal.data?.directoryName })}
                        </Trans>
                    </div>
                    <div className={styles.buttonsRow}>
                        <Button
                            buttonType={ButtonTypes.WithoutBg}
                            title={t('Employee_History.Cancele')}
                            buttonStyle={styles.button}
                            onClick={onClose}
                        />
                        <Button
                            buttonType={ButtonTypes.Primery}
                            title={t('Edit_User.Change')}
                            buttonStyle={styles.button}
                            type='submit'
                        />
                    </div>
                </div>
            ),
            submiteHandler: onDeleteDirectory,
            loading: deleteLoading
        },
        [DirectoryModalTypes.EDIT_OPTION]: {
            title: t('Directories.Edit_Option'),
            body: (
                <div className={styles.body}>
                    <InputField
                        register={register}
                        registerName='editOption'
                        defaultValue={modal.data?.optionName!}
                        label={t('Directories.Option')}
                        placeholder={t('Directories.Placeholder')}
                        required
                        error={errors.editOption}
                    />
                    <div className={styles.buttonsRow}>
                        <Button
                            buttonType={ButtonTypes.WithoutBg}
                            title={t('Employee_History.Cancele')}
                            buttonStyle={styles.button}
                            onClick={onClose}
                        />
                        <Button
                            buttonType={ButtonTypes.Primery}
                            title={t('Employee_History.Save')}
                            buttonStyle={styles.button}
                            type='submit'
                        />
                    </div>
                </div>
            ),
            submiteHandler: onEditOption,
            loading: editOptionLoading
        }
    }

    return {
        MODAL_INNER,
        handleSubmit
    }
}

export default useDirectoryModalHooks