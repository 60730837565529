import React, { useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useLoginMutation } from 'Services/authApi';
import { useGeneralHooks } from 'Pages/general.hooks';
import { LoginFormValues } from 'Pages/interfaces';
import { LoginForm, RightSide, LeftSide } from 'Components';
import Vector from 'Assets/Images/vector.svg';
import 'react-toastify/dist/ReactToastify.css';
import styles from './login.module.scss';

const LogInPage: React.FC = () => {
  const [searchParams, _] = useSearchParams();
  const email = searchParams.get('email');
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<LoginFormValues | FieldValues>();
  const { navigate, accessToken, t } = useGeneralHooks();
  const [login, { data: loginResponse, isLoading, isSuccess }] =
    useLoginMutation();
  const onSubmit: SubmitHandler<LoginFormValues | FieldValues> = (
    data: LoginFormValues | FieldValues
  ) => {
    const payload = {
      email: data.email.trim(),
      password: data.password.trim(),
    };
    login(payload);
  };

  useEffect(() => {
    if (email) {
      setValue('email', email);
    }
  }, [email]);

  useEffect(() => {
    if (email && !isSuccess) {
      localStorage.removeItem('access-token');
    } else if (isSuccess || accessToken) {
      navigate('/main/documents/applications/vacation');
      if (loginResponse?.result?.token) {
        localStorage.setItem('access-token', loginResponse?.result?.token);
        window.location.reload();
        navigate('/main/documents/applications/vacation');
      }
    }
  }, [isSuccess, accessToken]);

  return (
    <div className={styles.container}>
      <LeftSide
        title={t('Leftside_Texts.Title')}
        describtion={t('Leftside_Texts.Description')}
      >
        <img src={Vector} alt='vector' />
      </LeftSide>
      <RightSide>
        <LoginForm
          register={register}
          onSubmit={handleSubmit(onSubmit)}
          errors={errors}
          isLoading={isLoading}
          defaultEmail={email!}
        />
      </RightSide>
    </div>
  );
};

export default LogInPage;
