import { t } from "i18next";
import moment from "moment";
import { IAppDetailHistoryData } from "Components/interfaces";
import NoData from "Components/NoData/noData";
import { IAbsenseRespResultHistory} from "Services/responce_types";
import ApplicationDetailHistoryItem from "./applicationDetailHistoryItem";
import styles from "./applicationDetailHistory.module.scss";

interface Iprops {
  data: IAppDetailHistoryData;
};

const ApplicationDetailHistoryList: React.FC<Iprops> = (props) => {
  const historyItems = props?.data?.result?.history;
  const reversedHistoryItems = [...historyItems].reverse();
  
  return (
    <div className={styles["history-list__container"]}>
      {reversedHistoryItems.length !== 0 ? (
        reversedHistoryItems.map(
          (item: IAbsenseRespResultHistory, index: number) => {
            return (
              <ApplicationDetailHistoryItem
                key={index}
                name={item?.createdBy?.name!}
                surname={item?.createdBy?.surname!}
                status_from={item?.status_from?.name!}
                status_from_color={item?.status_from?.color!}
                status_to={item?.status_to?.name!}
                status_to_color={item?.status_to?.color!}
                time={moment(item?.createdAt!).fromNow()}
                img={item?.createdBy?.avatar!}
                description={item?.description!}
              />
            );
          }
        )
      ) : (
        <div style={{paddingTop: "20px"}}><NoData title={t("No_Data.No_History_Title")} description={t("No_Data.No_History_Description")} withButton={false} /></div>
      )}
    </div>
  );
};

export default ApplicationDetailHistoryList;
