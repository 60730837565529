import React, { useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { ToastContainer } from 'react-toastify';
import { useCreateCompanyMutation } from 'Services/authApi';
import { IFileResponse } from 'Services/responce_types';
import { CreateCompanyFormValues } from 'Pages/interfaces';
import { useGeneralHooks } from 'Pages/general.hooks';
import { HeaderTypes } from 'Components/interfaces';
import { Header, LeftSide, RightSide } from 'Components';
import CreateCompanyForm from 'Components/AuthComponents/AuthForms/CreateCompanyForm/createCompanyForm';
import { Vector } from 'Assets';
import styles from './createCompany.module.scss';
import { t } from 'i18next';
import { getMe } from 'Store/Slices/authSlice';

const CreateCompanyPage: React.FC = () => {
  const [createCompany, { isLoading, isSuccess }] = useCreateCompanyMutation();
  const { dispatch } = useGeneralHooks();
  const { mobileScreen, navigate } = useGeneralHooks();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm<CreateCompanyFormValues | FieldValues>({ mode: 'all' });

  const onSubmit: SubmitHandler<CreateCompanyFormValues | FieldValues> = (
    data: CreateCompanyFormValues | FieldValues
  ) => {
    const payload: CreateCompanyFormValues = {
      address: data.address.trim(),
      avatar: (data.avatar as IFileResponse)?.result || null,
      cityId: data.cityId.id,
      name: data.name.trim(),
      oltId: data.oltId.id,
      roleId: data.roleId.id,
      sphereOfActivityId: data.sphereOfActivityId.id,
      tin: data.tin.trim(),
      typeOfTaxId: data.typeOfTaxId.id,
    };
    createCompany(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(getMe());
      navigate('/invite');
    }
  }, [isSuccess]);

  return (
    <div className={styles.container}>
      {!mobileScreen ? (
        <LeftSide
          title={t('Leftside_Texts.Title')}
          describtion={t('Leftside_Texts.Description')}
        >
          <img src={Vector} alt='Vector_Image' />
        </LeftSide>
      ) : (
        <Header type={HeaderTypes.Logo} />
      )}
      <RightSide>
        <CreateCompanyForm
          register={register}
          control={control}
          errors={errors}
          watch={watch}
          onSubmit={handleSubmit(onSubmit)}
          isValid={isValid}
          isLoading={isLoading}
        />
      </RightSide>
      <ToastContainer />
    </div>
  );
};

export default CreateCompanyPage;
