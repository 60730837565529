import { t } from "i18next";
import { LeftSide, RightSide } from "Components";
import styles from "./confirmIdentity.module.scss";
import Vector from "Assets/Images/vector.svg";
import NeedPassword from "Components/SocialLogin/needPassword";
import UseSocialLoginHooks from "Components/AuthComponents/SocialLogin/socialLogin.hooks";

const ConfirmIdentity: React.FC = () => {
  const { socialUser } = UseSocialLoginHooks();

  return (
    <div className={styles.container}>
      <LeftSide
        title={t("Leftside_Texts.Title")}
        describtion={t("Leftside_Texts.Description")}
      >
        <img src={Vector} alt="vector" />
      </LeftSide>
      <RightSide>
        <NeedPassword
          src={socialUser?.result?.data?.avatar}
          name={`${socialUser?.result?.data?.name} ${socialUser?.result?.data?.surname}`}
          email={socialUser?.result?.data?.email}
        />
      </RightSide>
    </div>
  );
};

export default ConfirmIdentity;
