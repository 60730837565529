import { useGeneralHooks } from 'Pages/general.hooks';
import { keepGroupChatUsers } from 'Store/Slices/chatSlice';
import { useState } from 'react';

const UseChooseChatMembersHooks = () => {
  const [chosenMembers, setChosenMembers] = useState<string[]>([]);
  const { dispatch } = useGeneralHooks();
  const checkMembers = (id: string) => {
    const tmp = [...chosenMembers];
    if (chosenMembers.includes(id)) {
      const indexOfId = chosenMembers.indexOf(id);
      tmp.splice(indexOfId, 1);
    } else {
      tmp.push(id);
    }
    setChosenMembers(tmp);
    dispatch(keepGroupChatUsers(tmp));
  };

  return { chosenMembers, checkMembers };
};

export default UseChooseChatMembersHooks;
