import React from 'react';
import { IDirectoryModal } from 'Pages/interfaces';
import { CustomModal } from 'Components'
import useDirectoryModalHooks from './directoryModal-hooks';
import styles from './directoryModal.module.scss';

interface IProps {
  modal: IDirectoryModal,
  onClose: () => void,
}

const DyrectoryModal: React.FC<IProps> = (props) => {
  const { modal, onClose, } = props;
  const {MODAL_INNER,handleSubmit}  = useDirectoryModalHooks( modal, onClose);
  return (
    <div>
      <CustomModal
        open={modal.open}
        title={MODAL_INNER[`${modal.type!}`]?.title!}
        handleClose={onClose}
        dialogClassname={"dyrectoryModal"}
        loading={MODAL_INNER[`${modal.type!}`]?.loading!}
        children={
          <form onSubmit={handleSubmit(MODAL_INNER[`${modal.type!}`]?.submiteHandler!)} className={styles.form}>
            {MODAL_INNER[`${modal.type!}`]?.body!}
          </form>
        }
      />
    </div>
  )
}

export default DyrectoryModal
