import { useGeneralHooks } from 'Pages/general.hooks';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getMe } from 'Store/Slices/authSlice';
import { RootState, useAppSelector } from 'Store/store';
import { StreamChat } from 'stream-chat';

type Props = {
  children?: React.ReactNode;
};

const PrivatePage: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('access-token');
  const newClient = StreamChat.getInstance('upshy6pu477x');
  const { dispatch,currentUser } = useGeneralHooks();

  useEffect(() => {
    if (accessToken) {
      dispatch(getMe());
    } else {
      navigate('/login');
    }
  }, [accessToken]);

  async function connectToStream() {
    if (currentUser) {
      await newClient
        .connectUser(
          {
            id:
              (currentUser?.data?.id as any) &&
              (currentUser?.data?.id?.toString()! as any),
            image: `${process.env.REACT_APP_API_KEY}${currentUser?.data?.avatar}`,
            name: currentUser?.data?.name && currentUser?.data?.name,
          },
          currentUser?.data?.chat_token
        )
        .then(() => {})
        .catch((err) => {
          console.log(err, 'err');
        });
    }
  }

  useEffect(() => {
    currentUser && connectToStream();
  }, [currentUser]);

  return <div style={{ width: '100%' }}>{children}</div>;
};

export default PrivatePage;
