import React, { useEffect } from "react";
import { HeaderTypes } from "Components/interfaces";
import { CustomModal, Header, Languages, MobileNavDrawer } from "Components";
import BreadCrumbs from "Components/BreadCrumb/breadCrumbs";
import { SearchInput, TextArea } from "Components/AuthComponents/AuthInputs";
import { useAppPathsHooks } from "Components/BreadCrumb/breadCrumb.hooks";
import { useGeneralHooks } from "Pages/general.hooks";
import mekumLogo from "../../Assets/Icons/Mekum.logo.svg";
import arrow from "../../Assets/Icons/arrow.svg";
import enabled from "../../Assets/Icons/enabledIcon.svg";
import useSettingsHooks from "./settings.hooks";
import useRoleHooks from "Routers/role-hooks";
import styles from "./settings.module.scss";
import { MenuSVG } from "Assets";
import useMobileNavDrawerHooks from "Components/MobileNavDrawer/mobileNavDrawer-hooks";

const Settings: React.FC = () => {
  const { pathsToRender } = useAppPathsHooks();
  const { largeScreen, t, navigate } = useGeneralHooks();
  const { highPosition } = useRoleHooks();
  const { open, setOpen, onDrawerClose } = useMobileNavDrawerHooks();

  const {
    openModal,
    handleClose,
    handleOpen,
    register,
    errors,
    mekumId,
    handleSubmit,
    onSubmit,
    isLoading,
  } = useSettingsHooks();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [navigate]);

  return (
    <>
      {largeScreen ? (
        <div className={styles["settings-page"]}>
          <div className={styles["settings-page__heading"]}>
            <BreadCrumbs data={pathsToRender} />
            <SearchInput />
          </div>
          <div className={styles["settings-page__title"]}>
            {t("Settings.Page_Title")}
          </div>
          <div className={styles["settings-table"]}>
            <div className={styles["settings-table__row"]}>
              <div className={styles["settings-table__label"]}>
                {t("Settings.Languages")}
              </div>
              <div className={styles["settings-table__value"]}>
                <Languages />
              </div>
            </div>
            {mekumId ? (
              <div className={styles["settings-table__row"]}>
                <div className={styles["settings-table__connected"]}>
                  <img src={mekumLogo} alt="mekum" />
                  {t("Settings.Connected")}
                </div>
                <div className={styles["settings-table__value"]}>
                  <img src={enabled} alt="arrow" />
                </div>
              </div>
            ) : (
              <div
                className={styles["settings-table__row"]}
                onClick={handleOpen}
              >
                <div className={styles["settings-table__label"]}>
                  {t("Settings.Join_To")} <img src={mekumLogo} alt="mekum" />
                </div>
                <div className={styles["settings-table__value"]}>
                  <img src={arrow} alt="arrow" />
                </div>
              </div>
            )}
            {highPosition && (
              <>
                <div
                  className={styles["settings-table__row"]}
                  onClick={() => navigate("companyHierarchy")}
                >
                  <div className={styles["settings-table__label"]}>
                    {t("Hierarchy.Nav_Title")}
                  </div>
                  <div className={styles["settings-table__value"]}>
                    <img src={arrow} alt="arrow" />
                  </div>
                </div>
              </>
            )}
          </div>
          <CustomModal
            open={openModal}
            title={t("Settings.Join")}
            loading={isLoading}
            buttonTitle={t("Settings.Join_To")}
            onClick={handleSubmit(onSubmit)}
            handleClose={handleClose}
            dialogClassname={"settingsModal"}
          >
            <p className={styles.modalText}>{t("Settings.Join_Description")}</p>
            <form style={{ paddingBottom: "8px" }}>
              <TextArea
                label={t("Settings.Token_Label")}
                placeholder={t("Settings.Token_Placeholder")}
                register={register}
                registerName={"mekum_token"}
                id="token"
                error={errors.mekum_token}
                inputStyle={styles.dismissalField}
                rows={5}
              />
            </form>
          </CustomModal>
        </div>
      ) : (
        <div>
          <Header
            type={HeaderTypes.Navigation}
            navTitle={t("Render_path_Names.Settings")}
            icon={MenuSVG}
            iconOnClick={() => setOpen(!open)}
          />
          <MobileNavDrawer open={open} onClose={onDrawerClose} />
          <div className={styles["mobile-settings-page"]}>
            <div className={styles["settings-table"]}>
              <div className={styles["settings-table__row"]}>
                <div className={styles["settings-table__label"]}>
                  {t("Settings.Languages")}
                </div>
                <div className={styles["settings-table__value"]}>
                  <Languages />
                </div>
              </div>
              {mekumId ? (
                <div className={styles["settings-table__row"]}>
                  <div className={styles["settings-table__connected"]}>
                    <img src={mekumLogo} alt="mekum" />
                    {t("Settings.Connected")}
                  </div>
                  <div className={styles["settings-table__value"]}>
                    <img src={enabled} alt="arrow" />
                  </div>
                </div>
              ) : (
                <div
                  className={styles["settings-table__row"]}
                  onClick={handleOpen}
                >
                  <div className={styles["settings-table__label"]}>
                    {t("Settings.Join_To")}{" "}
                    <img src={mekumLogo} alt="mekumLogo" />
                  </div>
                  <div className={styles["settings-table__value"]}>
                    <img src={arrow} alt="arrow" />
                  </div>
                </div>
              )}
              {highPosition && (
                <>
                  <div
                    className={styles["settings-table__row"]}
                    onClick={() => navigate("employeeSettings")}
                  >
                    <div className={styles["settings-table__label"]}>
                      {t("Employee_Settings.Employees")}
                    </div>
                    <div className={styles["settings-table__value"]}>
                      <img src={arrow} alt="arrow" />
                    </div>
                  </div>
                  <div
                    className={styles["settings-table__row"]}
                    onClick={() => navigate("directories")}
                  >
                    <div className={styles["settings-table__label"]}>
                      {t("Directories.Title")}
                    </div>
                    <div className={styles["settings-table__value"]}>
                      <img src={arrow} alt="arrow" />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div>
              <CustomModal
                open={openModal}
                title={t("Settings.Join")}
                loading={isLoading}
                buttonTitle={t("Settings.Join_To")}
                onClick={handleSubmit(onSubmit)}
                handleClose={handleClose}
                dialogClassname={"settingsModal"}
              >
                <p className={styles.modalText}>
                  {t("Settings.Join_Description")}
                </p>
                <form style={{ paddingBottom: "8px" }}>
                  <TextArea
                    label={t("Settings.Token_Label")}
                    placeholder={t("Settings.Token_Placeholder")}
                    register={register}
                    registerName={"mekum_token"}
                    id="token"
                    error={errors.mekum_token}
                    inputStyle={styles.dismissalField}
                    rows={5}
                  />
                </form>
              </CustomModal>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Settings;
