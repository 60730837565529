import { useCallback, useEffect, useState } from 'react';
import { useGetAllUsersQuery, useGetEmpPropertiesQuery } from 'Services/employeesApi';
import { IAllUsersResp } from 'Services/responce_types';
import { IEmployeeProperty } from 'Pages/interfaces';

const UseEmployeesHooks = () => {
  const [page, setPage] = useState<number>(1);
  const { data: allUsersList, isLoading: getAllUserLoading } = useGetAllUsersQuery({
    page,
    offset: 12,
  });
  const [userList, setUserList] = useState<IAllUsersResp | undefined>(undefined);
  
useEffect(()=>{
  if(allUsersList?.status === 200){
    setUserList(allUsersList)
  }
},[allUsersList]);
  
  const loading = getAllUserLoading
  return {
    userList,
    page,
    setPage,
    loading,
  };
};

export default UseEmployeesHooks;
