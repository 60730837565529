import React from 'react';
import {
  FieldErrors,
  FieldValues,
  SubmitHandler,
  UseFormRegister,
} from 'react-hook-form';
import { useGeneralHooks } from 'Pages/general.hooks';
import { InputField } from 'Components/AuthComponents/AuthInputs';
import { UserInfoFormValues } from 'Components/interfaces';
import styles from './editUserInfo.module.scss';
import { ChangeEmail } from 'Assets';
import CustomModal from 'Components/CustomModal/customModal';
import { useCustomModalHooks } from 'Components/CustomModal/customModal.hooks';

export interface IEditUserInfoProps {
  register: UseFormRegister<FieldValues | UserInfoFormValues>;
  errors: FieldErrors<FieldValues | UserInfoFormValues>;
  name: string;
  surname: string;
  onChangeEmail?: SubmitHandler<UserInfoFormValues | FieldValues>;
  email: string;
  handleSubmit: any;
  registerEmail: string;
  registerForName: string;
  registerForSurname: string;
}

export interface IEditUserInfoData {
  data: IEditUserInfoProps;
}

const EditUserInfoForm: React.FC<IEditUserInfoData> = (props) => {
  const {
    name,
    email,
    errors,
    surname,
    handleSubmit,
    registerEmail,
    registerForName,
    registerForSurname,
    onChangeEmail,
    register,
  } = props.data;
  const { openModal, handleOpen, handleClose } = useCustomModalHooks();
  const { t } = useGeneralHooks();
  return (
    <div className={styles.container}>
      <div className={styles.emailInput}>
        <label htmlFor={'userEmail'} className={styles.label}>
          {t('Register.Email_label')}
        </label>
        <div
          className={`${styles.inputBox} ${styles.inputField}`}
          id='userEmail'
        >
          {email}
          <span
            onClick={() => {
              handleOpen();
            }}
            className={styles.editEmailIcon}
          >
            <img src={ChangeEmail} alt='changeEmail' />
          </span>
        </div>
      </div>
      <InputField
        label={t('Register.First_name')}
        id='userName'
        registerName={registerForName}
        register={register}
        inputStyle={styles.inputField}
        error={errors.name}
        defaultValue={name}
      />
      <InputField
        label={t('Register.Surname')}
        id='userSurname'
        register={register}
        registerName={registerForSurname}
        inputStyle={styles.inputField}
        error={errors.surname}
        defaultValue={surname}
      />
      <CustomModal
        open={openModal}
        title={t('Edit_User.Change_Email')}
        // loading={changePasswordLoading}
        buttonTitle={t('Edit_User.Change')}
        onClick={handleSubmit(onChangeEmail)}
        handleClose={handleClose}
        dialogClassname={'settingsModal'}
      >
        <form className={styles.emailForm}>
          <InputField
            inputStyle={styles.email}
            defaultValue={email}
            type={'text'}
            error={errors.email}
            placeholder={t('Register.Email_label')}
            label={t('Register.Email_placeholder')}
            register={register}
            registerName={registerEmail}
            message={t('Input_Errors.Email')}
            patternValue={
              /^\s*[A-ZA-z0-9._%+-]+@[A-Za-z0-9.-]+[A-Za-z]{2,}\s*$/i
            }
          />
        </form>
      </CustomModal>
    </div>
  );
};

export default EditUserInfoForm;
