import {  ChannelPreviewUIComponentProps,} from 'stream-chat-react';
import styles from './chatUser.module.scss'
import { RootState, useAppSelector } from 'Store/store';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

const ChatUserAvatar= (props:ChannelPreviewUIComponentProps<DefaultStreamChatGenerics>) => {
  const currentUser = useAppSelector((state: RootState) => state?.auth?.currentUser);
  const type = props?.channel?.data?.type!;
  if(type! === "messaging"){
    const chatMembers = props?.channel?.state?.members!;
    const getMemberId = ()=>{
      for(let value in chatMembers!){
        if(value !== String(currentUser?.data.id)){
          return value
        }
      }
    };
const memberID = getMemberId();
const member = chatMembers[`${memberID}`];
const image:string = member?.user?.image!;
const hasImage = !image?.includes("null")
const name = member?.user?.name!;
const online:boolean = member?.user?.online!
return (
  <div className={styles.container} key={props.channel.cid}>
     {hasImage ?
      <img className={styles.avatar} src={image!} alt='avatar'/>
      :
      <div className={styles.defAvatar}>{name?.[0]}</div>
    }
    {online && <div className={styles.onlineIndecator}></div>}
    </div>
)
  }else{
    const image =  props?.channel?.data?.image!;
    const name = props?.channel?.data?.name;

    return (
<div className={styles.container} key={props.channel.cid}>
      {image ?
      <img className={styles.avatar} src={image!} alt='avatar'/>
      :
      <div className={styles.defAvatar}>{name?.[0]}</div>
    }
    </div>
    )
  }
}

export default ChatUserAvatar
