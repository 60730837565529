import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitState {
  socialUser: any;
  access_token: string;
  platform: string
}

const initialState: IInitState = {
    socialUser: [],
    access_token: "",
    platform: ''
};

const socialUserSlice = createSlice({
  name: "keepSocialUser",
  initialState,
  reducers: {
    keepSocialUser(state, action: PayloadAction<any>) {
      state.socialUser = action.payload;
    },
    keepAccessToken(state, action: PayloadAction<any>) {
      state.access_token = action.payload;
    },
    keepPlatform(state, action: PayloadAction<any>) {
      state.platform = action.payload;
    },
  },
});

export const { keepSocialUser, keepAccessToken, keepPlatform } = socialUserSlice.actions;
export default socialUserSlice.reducer;