import { Link } from "react-router-dom";
import { t } from "i18next";
import personIcon from "../../Assets/Images/person-icon.svg";
import { useDeleteGroupMutation } from "Services/groupsApi";
import useRoleHooks from "Routers/role-hooks";
import styles from "./groups.module.scss";
import { useEffect } from "react";
import { toast } from "react-toastify";

interface IProps {
  groupName: string;
  groupMemberCount: number;
  // groupTLeads: any;
  id: number;
  handleOpen: (e: Event) => void;
  handleClose: () => void;
  open: boolean;
}

export const GroupItem: React.FC<IProps> = (props) => {
  const { groupName, groupMemberCount, id, handleClose, handleOpen, open } =
    props;
  const { currentRole } = useRoleHooks();

  return (
    <>
      <Link className={styles["group-item"]} to={`/main/groups/${id}`}>
        <div className={styles["group-item__circle-cont"]}>
          <div className={styles["group-item__circle"]}></div>
        </div>
        <div className={styles["first-row"]}>
          <div className={styles["group-name"]}>{groupName}</div>
          <div className={styles["members-count-cont"]}>
            <img
              src={personIcon}
              className={styles["person-icon"]}
              alt="personIcon"
            />
            <span className={styles["members-count"]}>
              {groupMemberCount ? groupMemberCount : 0}
            </span>
          </div>
        </div>
      </Link>
    </>
  );
};
