import React from 'react';
import { Drawer } from '@mui/material';
import styles from '../employeeProperties.module.scss'
import { IEmployeeTPropartyTemplate } from 'Pages/interfaces';
import { EmployeePropertyTypes } from 'Components/interfaces';

interface IProps {
    open:boolean,
    onClose:()=>void,
    propertyTypes: IEmployeeTPropartyTemplate[],
    checkedType: number | undefined,
    onTypeSelect: (type: EmployeePropertyTypes, index: number) => void
};

const PropertyTypesDrawer:React.FC<IProps> = (props) => {
    const {open,propertyTypes,checkedType,onClose,onTypeSelect} = props;
  return (
    <Drawer
    open={open}
    onClose={onClose}
    anchor='right'
    >
    <div
      className={styles.drawer}
     >
        <div className={styles.types}>
            {propertyTypes.map((item, index) => {
                return (
                    <div className={checkedType! === index ? styles.checkedTypeItem : styles.typeItem} key={item.name + index} onClick={() => onTypeSelect(item.type, index)}>
                        <div>{item.icon}</div>
                        <div>
                            <span>
                                {item.name}
                            </span>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>
</Drawer>
  )
}

export default PropertyTypesDrawer
