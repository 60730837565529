import { ReactNode, useCallback, useState } from "react";
import { TreeNode } from "react-organizational-chart";
import { HierarchyItemTypes, IHierarchyData } from "../../hierarchy-hooks";
import HierarchyRoleItem from "./HierarchyRoleItem/hierarchyRoleItem";
import HierarchySectionItem from "./HierarchySectionItem/hierarchySectionItem";

const useHierarchyTreeHooks = () => {
    const [expanded, setExpanded] = useState<string[] | []>([]);
    const checkExpanded = useCallback((id: string): boolean => {
        const isExpanded = expanded?.some(item => item === id);
        return isExpanded
    }, [expanded]);

    const addAsExpanded = (id: string) => {
        const data = [...expanded!];
        data.push(id);
        setExpanded(data)
    };

    const removeExpanded = (id: string) => {
        const data = [...expanded!];
        const index = data.indexOf(id);
        data.splice(index, 1);
        setExpanded(data)
    }

    const createHierarchyTree = (data: IHierarchyData, onCreate: () => void, onDelete: (id: string) => void, handleNewTabOpen: (id: string) => void, bgColor?: string,): ReactNode => {
        const backgroundColor = (data.type === HierarchyItemTypes.SECTION && !data.sectionColor) ? `#006667` : (data.sectionColor ?? bgColor);
        if (data.children.length === 0) {
            return null;
        } else if (data.children.length > 0) {
            const childNodes = data.children.map((child) => (
                <TreeNode key={child.id}
                    label={child.type === HierarchyItemTypes.ROLE
                        ?
                        <HierarchyRoleItem data={child} onCreateNew={onCreate} bgColor={backgroundColor} onDelete={onDelete} handleNewTabOpen={handleNewTabOpen} />
                        :
                        <HierarchySectionItem data={child} onCreateNew={onCreate} expanded={expanded} addAsExpanded={addAsExpanded} removeExpanded={removeExpanded} onDelete={onDelete} handleNewTabOpen={handleNewTabOpen} />
                    }
                >
                    {child.type === HierarchyItemTypes.SECTION && !checkExpanded(child.id!) ? null : createHierarchyTree(child, onCreate, onDelete, handleNewTabOpen, backgroundColor)}
                </TreeNode>
            ));
            return childNodes;
        }
    };

    return {
        createHierarchyTree,
    }
};

export default useHierarchyTreeHooks