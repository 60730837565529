import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import useHierarchyHooks from './hierarchy-hooks';
import NoData from 'Components/NoData/noData';
import { Slider } from '@mui/material';
import HierarchyModal from './Components/Modal/hierarchyModal';
import HierarchyTree from './Components/HierarchyTree/hierarchyTree';
import { HierarcyImg } from 'Assets';
import styles from './hierarchy.module.scss';

const CompanyHierarchy: React.FC = () => {
    const { hierarchyData, modal, onStart, onAdd, onDelete, onCloseModal, setHierarchyData, handleNewTabOpen, scaleRatio, onToggleBodySize, sliderMarks } = useHierarchyHooks();
    const [isPageVisible, setIsPageVisible] = useState<boolean>(true);
    const [isWindowFocused, setIsWindowFocused] = useState<boolean>(true);
    const containerRef = useRef<HTMLDivElement>(null);
    const containerWidth = containerRef.current?.offsetWidth!

    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsPageVisible(document.visibilityState === 'visible');
        };

        const handleFocus = () => {
            setIsWindowFocused(true);
        };

        const handleBlur = () => {
            setIsWindowFocused(false);
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };
    }, []);

    useEffect(() => {
        if (isPageVisible && isWindowFocused) {
            hierarchyData.id && setHierarchyData(JSON.parse(localStorage.getItem('hierarchyData')!))
        }
    }, [isPageVisible, isWindowFocused]);

    return (
        <div className={`${styles.page} ${"hierarchy"}`} ref={containerRef} style={{ maxWidth: containerWidth }} >
            {!hierarchyData.id
                ?
                <div className={styles.header}>
                    <div className={styles.title}>
                        {t('Hierarchy.Nav_Title')}
                    </div>
                    <div className={styles.describtion}>
                        {t('Hierarchy.Discription')}
                    </div>
                </div>
                :
                <div className={styles.sliderBox}>
                    <div className={styles.slider}>
                        <Slider defaultValue={100} aria-label="Custom marks" valueLabelDisplay="auto" min={10} onChange={(_, value) => onToggleBodySize(value as number)} marks={sliderMarks} />

                    </div>
                </div>
            }
            {hierarchyData.id
                ?
                <div className={styles.body} >
                    <div className={styles.hierarchyTree} style={{ transform: `scale(${scaleRatio})` }}>
                        <HierarchyTree
                            data={hierarchyData}
                            onCreateNew={onAdd}
                            onDelete={onDelete}
                            handleNewTabOpen={handleNewTabOpen}
                        />
                    </div>
                </div>
                :
                <div className={styles.noData}>
                    <NoData
                        image={HierarcyImg}
                        withButton
                        btnText={t('Hierarchy.Start')}
                        btnOnclick={onStart}
                        description={t('Hierarchy.No_Hierarchy')}
                    />
                </div>
            }
            <HierarchyModal
                modal={modal}
                onClose={onCloseModal}
                hierarchyData={hierarchyData}
                setHierarchyData={setHierarchyData}
            />
        </div>
    )
}

export default CompanyHierarchy
