import React, { useEffect, useState } from "react";
import { t } from "i18next";
import Employee from "Assets/Images/Employee.png";
import closeIcon from "Assets/Images/close-circle-svgrepo-com.svg";
import { useCustomModalHooks } from "Components/CustomModal/customModal.hooks";
import CustomModal from "Components/CustomModal/customModal";
import styles from "./employeeComponet.module.scss";
import { useDeleteUserFromGroupMutation } from "Services/groupsApi";
import { useGeneralHooks } from "Pages/general.hooks";

interface IProps {
  src?: string;
  name: string;
  role?: string;
  onClick?: (val?: number) => void;
  typeChecked?: boolean;
  checkSuperior?: boolean;
  deletable?: boolean;
}

const EmployeeItem: React.FC<IProps> = (props) => {
  const { src, name, role, onClick, typeChecked, checkSuperior, deletable } =
    props;
  const [checked, setChecked] = useState<boolean>(false);
  const { openModal, handleOpen, handleClose } = useCustomModalHooks();
  const [removeUserFromGroupReq, { isLoading, isSuccess }] =
    useDeleteUserFromGroupMutation();
  const { t, params } = useGeneralHooks();

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  return (
    <div
      className={`${styles["employee-item"]}  ${
        checked || checkSuperior ? styles.checked : ""
      } `}
      onClick={() => {
        typeChecked && setChecked(!checked);
        onClick && onClick();
      }}
    >
      {deletable && (
        <div className={styles["close-icon__container"]}>
          <img
            src={closeIcon}
            className={styles["close-icon"]}
            onClick={(e) => {
              e.stopPropagation();
              handleOpen();
            }}
          />
        </div>
      )}
      <img
        src={src ? `${process.env.REACT_APP_API_KEY}${src}` : Employee}
        alt="employeeItem"
        className={styles["employee-item__img"]}
      />
      <div className={`${styles["employee-content"]} `}>
        <div
          className={`${styles["employee-item__name"]} ${
            checked || checkSuperior ? styles.checkedName : ""
          } `}
        >
          {name}
        </div>
        <div className={styles["employee-item__role"]}>
          {role ? role : t("Employees.user")}
        </div>
      </div>
      <CustomModal
        open={openModal}
        title={t("Groups.Remove_User_From_Group_Title")}
        loading={isLoading}
        buttonTitle={t("Groups.Remove_Btn")}
        onClick={() => removeUserFromGroupReq(+params?.id!)}
        handleClose={(e: any) => {
          e.stopPropagation();
          handleClose();
        }}
        dialogClassname={"delete-group__modal"}
      >
        <div className={styles["delete-modal__content"]}>
          {t("Groups.Remove_User_From_Group_Content")}
        </div>
      </CustomModal>
    </div>
  );
};

export default EmployeeItem;
