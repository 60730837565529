import React, { Dispatch, SetStateAction } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { usePensionHooks } from './pension-hooks';
import { useGeneralHooks } from 'Pages/general.hooks';
import { UploadFile } from 'Components';
import Button from 'Components/Button/button';
import NoData from 'Components/NoData/noData';
import { ButtonTypes, IPensionData } from 'Components/interfaces';
import { AutoComplete, InputField } from 'Components/AuthComponents/AuthInputs';
import Arrow from 'Assets/Icons/arrow_back.svg';
import styles from './pension.module.scss';
import applicationStyles from '../appliacationItems.module.scss';

interface IPensionForm {
  onSubmit: (data: IPensionData | FieldValues) => void;
  setShowDataTable?: Dispatch<SetStateAction<boolean>>;
}

export const PensionForm: React.FC<IPensionForm> = (props) => {
  const { onSubmit, setShowDataTable } = props;
  const { t, largeScreen } = useGeneralHooks();
  const {
    currentRole,
    errors,
    minDate,
    control,
    isLoading,
    employees,
    selectedFiles,
    onChangeFile,
    handleSubmit,
    deleteFile,
    register,
    watch,
  } = usePensionHooks();
  const employeeList = employees?.result.map((item) => {
    return {
      id: item.userId,
      name: `${item?.user?.name} ${item?.user?.surname}`,
    };
  });
  return (
    <div className={styles.container}>
      {employees?.result.length! > 0 ? (
        <>
          <div className={styles.formBox}>
            <div className={applicationStyles.header}>
              {!largeScreen && (
                <div className={applicationStyles.title}>
                  {t('Aplication_Page.Tab_Labels.Pension')}
                </div>
              )}
              <div className={applicationStyles.describtion}>
                {t('Aplication_Page.Pension.Description')}
              </div>
            </div>
            <form
              className={styles.pensionform}
              onSubmit={handleSubmit(onSubmit)}
              id='dismissalForm'
            >
              <div className={styles.inputsContainer}>
                <div className={styles.inputWitdth}>
                  {currentRole === 'HR' || currentRole === 'CEO' ? (
                    <Controller
                      control={control}
                      name='employee'
                      rules={{
                        validate: () =>
                          watch('employee') !== undefined ||
                          t('Input_Errors.Required'),
                      }}
                      render={({ field: { onChange, name, value } }) => {
                        return (
                          <AutoComplete
                            value={value}
                            name={name}
                            onChange={onChange}
                            id='employeeId'
                            data={employeeList}
                            label={t('Aplication_Page.Desmissal.Employee')}
                            placeholder={t(
                              'Aplication_Page.Desmissal.Employee_placeholder'
                            )}
                            error={errors.employee}
                          />
                        );
                      }}
                    />
                  ) : (
                    <InputField
                      type='text'
                      disabled={true}
                      id='worker'
                      label={t('Aplication_Page.Desmissal.Employee')}
                      register={register}
                      registerName='user_id'
                      inputStyle={styles.inputField}
                    />
                  )}
                </div>
              </div>
              <div className={styles.inputsContainer}>
                <InputField
                  type='date'
                  minDate={minDate}
                  id='dateStart'
                  label={t('Aplication_Page.Pension.Start_Date')}
                  register={register}
                  registerName='start_date'
                  error={errors.start_date}
                  validation={(value) =>
                    value >= minDate || t('Input_Errors.Invalid_Date')
                  }
                  inputStyle={styles.inputField}
                />
                <InputField
                  type='date'
                  minDate={minDate}
                  id='dateEnd'
                  label={t('Aplication_Page.Pension.End_Date')}
                  register={register}
                  registerName='end_date'
                  validation={(value) =>
                    value > watch('start_date') ||
                    t('Input_Errors.Invalid_Date')
                  }
                  error={errors.end_date}
                  inputStyle={styles.inputField}
                />
              </div>
              <Controller
                control={control}
                name='files'
                rules={{ required: t('Input_Errors.Required') }}
                render={() => (
                  <UploadFile
                    error={errors.files}
                    onChangeFile={onChangeFile}
                    deleteFile={deleteFile}
                    selectedFiles={selectedFiles}
                  />
                )}
              />
            </form>
          </div>
          <div className={applicationStyles.buttonContent}>
            <Button
              title={t('Aplication_Page.Button')}
              type='button'
              buttonStyle={applicationStyles.withoutBtn}
              buttonTitleStyle={applicationStyles.withoutBtnTitle}
              buttonType={ButtonTypes.WithoutBg}
              onClick={() => {
                setShowDataTable!(true);
              }}
            />
            <Button
              type='submit'
              rightIcon={Arrow}
              buttonTitleStyle={applicationStyles.BtnTitle}
              isLoading={isLoading}
              title={t('Aplication_Page.Vacation.Buuton_Apply')}
              buttonType={ButtonTypes.ButtonWithIcon}
              buttonStyle={applicationStyles.btn}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </>
      ) : (
        <NoData withButton={false} title={t('Toastify_error.noEmployee')} />
      )}
    </div>
  );
};

export default PensionForm;
