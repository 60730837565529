import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AuthNavigation from 'Routers/authNavigation';
import './App.scss';

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('currentLang') ?? 'am');
  }, [i18n]);

  return (
    <>
      <AuthNavigation />
    </>
  );
}

export default App;
