import React from "react";
import { Outlet } from "react-router";
import { useGeneralHooks } from "Pages/general.hooks";
import { Layout } from "Components/Layout/layout";
import styles from "./mainPage.module.scss";

const MainPage: React.FC = () => {
  const { largeScreen } = useGeneralHooks();
  
  return (
    <div className={styles.container}>
      {largeScreen && <Layout />}
      <Outlet />
    </div>
  );
};

export default MainPage;
