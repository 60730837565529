import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import useRoleHooks from 'Routers/role-hooks';
import {
  WhiteNotifications,
  GrayNotifications,
  WhiteDocuments,
  WhiteEmployees,
  GrayEmployees,
  GrayDocuments,
  WhiteSettings,
  GraySettings,
  GrayChatIcon,
  WhiteGroups,
  GrayGroups,
  GrayNotics,
  Invitation,
} from 'Assets/Icons/LayoutIcons';
import { useGeneralHooks } from 'Pages/general.hooks';
import { ILayoutBiRoles, ILayoutData } from 'Components/interfaces';
import { RootState, useAppSelector } from 'Store/store';

export const useLayoutHooks = () => {
  const { t, navigate } = useGeneralHooks();
  const CurrentUser = useAppSelector(
    (state: RootState) => state?.auth?.currentUser
  );
  const { highPosition, isVisitor } = useRoleHooks();
  const location = useLocation();
  const path = location.pathname.split('/')[2];
  const [expanded, setExpanded] = useState<string | false>(path);
  const { currentRole } = useRoleHooks();

  useEffect(() => {
    if (currentRole) {
      localStorage.setItem('hr-role', currentRole!);
    }
    if (currentRole && CurrentUser?.data?.employee) {
      setExpanded('documents');
    } else {
      // navigate('/main/userProfile');
      setExpanded(false);
    }
    if (
      CurrentUser?.data?.name === null ||
      CurrentUser?.data?.name === undefined
    ) {
      // navigate('/main/userProfile');
    }
  }, [currentRole]);

  const handleChange =
    (panel: ILayoutData) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel.activeMenu : false);
      panel.link && navigate(panel.link);
    };

  const sx = { marginBottom: '15px', boxShadow: 'none', border: 'none' };

  const layoutMenu: ILayoutBiRoles = {
    highPosition: [
      {
        title: t('LayoutMenu.Title_Documents'),
        grayIcon: GrayDocuments,
        whiteIcon: WhiteDocuments,
        activeMenu: 'documents',
        available: true,
        list: [
          {
            link: '/main/documents/applications/vacation',
            title: t('LayoutMenu.NavList.Applications'),
            available: true,
          },
          {
            link: '/main/documents/my_applications/vacation',
            title: t('LayoutMenu.NavList.My_Applications'),
            available: true,
          },
          {
            link: '/main/documents/paySheet',
            title: t('LayoutMenu.NavList.PaySheet'),
            available: true,
          },
          {
            link: '/main/documents/orders',
            title: t('LayoutMenu.NavList.Orders'),
            available: true,
          },
          {
            link: '/main/documents/tabel',
            title: t('LayoutMenu.NavList.Tabel'),
            available: true,
          },
        ],
      },
      {
        title: t('LayoutMenu.Title_Employees'),
        grayIcon: GrayEmployees,
        whiteIcon: WhiteEmployees,
        activeMenu: 'employees',
        link: '/main/employees',
        available: true,
      },
      {
        title: t('LayoutMenu.Title_Groups'),
        grayIcon: GrayGroups,
        whiteIcon: WhiteGroups,
        activeMenu: 'groups',
        link: '/main/groups',
        available: true,
      },
      {
        title: t('LayoutMenu.Title_Notices'),
        grayIcon: GrayNotifications,
        whiteIcon: WhiteNotifications,
        activeMenu: 'notifications',
        link: '/main/notifications',
        available: true,
      },
      {
        title: t('LayoutMenu.Title_My_Invitations'),
        grayIcon: GrayNotics,
        whiteIcon: Invitation,
        activeMenu: 'my-invitations',
        link: '/main/my-invitations',
        available: true,
      },
      {
        title: t('LayoutMenu.Chat'),
        grayIcon: GrayChatIcon,
        whiteIcon: GrayChatIcon,
        activeMenu: 'chat',
        link: '/chat',
        available: true,
      },
      {
        title: t('LayoutMenu.Title_Settings'),
        grayIcon: GraySettings,
        whiteIcon: WhiteSettings,
        activeMenu: 'settings',
        link: '/main/settings',
        available: true,
      },
    ],
    employee: [
      {
        title: t('LayoutMenu.Title_Documents'),
        grayIcon: GrayDocuments,
        whiteIcon: WhiteDocuments,
        activeMenu: 'documents',
        available: true,
        list: [
          {
            link: '/main/documents/my_applications/vacation',
            title: t('LayoutMenu.NavList.My_Applications'),
            available:
              CurrentUser?.data?.employee && currentRole === 'EMP'
                ? true
                : false,
          },
          {
            link: '/main/documents/paySheet',
            title: t('LayoutMenu.NavList.PaySheet'),
            available: true,
          },
          {
            link: '/main/documents/orders',
            title: t('LayoutMenu.NavList.Orders'),
            available: true,
          },
          {
            link: '/main/documents/tabel',
            title: t('LayoutMenu.NavList.Tabel'),
            available: true,
          },
        ],
      },
      {
        title: t('LayoutMenu.Title_Employees'),
        grayIcon: GrayEmployees,
        whiteIcon: WhiteEmployees,
        activeMenu: 'employees',
        link: '/main/employees',
        available: true,
      },
      {
        title: t('LayoutMenu.Title_Groups'),
        grayIcon: GrayGroups,
        whiteIcon: WhiteGroups,
        activeMenu: 'groups',
        link: '/main/groups',
        available: true,
      },
      {
        title: t('LayoutMenu.Title_Notices'),
        grayIcon: GrayNotifications,
        whiteIcon: WhiteNotifications,
        activeMenu: 'notifications',
        link: '/main/notifications',
        available: true,
      },
      {
        title: t('LayoutMenu.Title_My_Invitations'),
        grayIcon: GrayNotics,
        whiteIcon: Invitation,
        activeMenu: 'my-invitations',
        link: '/main/my-invitations',
        available: true,
      },
      {
        title: t('LayoutMenu.Chat'),
        grayIcon: GrayChatIcon,
        whiteIcon: GrayChatIcon,
        activeMenu: 'chat',
        link: '/chat',
        available: true,
      },
      {
        title: t('LayoutMenu.Title_Settings'),
        grayIcon: GraySettings,
        whiteIcon: WhiteSettings,
        activeMenu: 'settings',
        link: '/main/settings',
        available: true,
      },
    ],
    visitor: [
      {
        title: t('LayoutMenu.Title_Documents'),
        grayIcon: GrayDocuments,
        whiteIcon: WhiteDocuments,
        activeMenu: 'documents',
        available: false,
        list: [
          {
            link: '/main/documents/applications',
            title: t('LayoutMenu.NavList.Applications'),
            available: false,
          },
          {
            link: '/main/documents/my_applications',
            title: t('LayoutMenu.NavList.My_Applications'),
            available: false,
          },
          {
            link: '/main/documents/paySheet',
            title: t('LayoutMenu.NavList.PaySheet'),
            available: false,
          },
          {
            link: '/main/documents/orders',
            title: t('LayoutMenu.NavList.Orders'),
            available: false,
          },
          {
            link: '/main/documents/tabel',
            title: t('LayoutMenu.NavList.Tabel'),
            available: false,
          },
        ],
      },
      {
        title: t('LayoutMenu.Title_Employees'),
        grayIcon: GrayEmployees,
        whiteIcon: WhiteEmployees,
        activeMenu: 'employees',
        link: '/main/employees',
        available: false,
      },
      {
        title: t('LayoutMenu.Title_Groups'),
        grayIcon: GrayGroups,
        whiteIcon: WhiteGroups,
        activeMenu: 'groups',
        link: '/main/groups',
        available: false,
      },
      {
        title: t('LayoutMenu.Title_Notices'),
        grayIcon: GrayNotifications,
        whiteIcon: WhiteNotifications,
        activeMenu: 'notifications',
        link: '/main/notifications',
        available: true,
      },
      {
        title: t('LayoutMenu.Title_My_Invitations'),
        grayIcon: GrayNotics,
        whiteIcon: Invitation,
        activeMenu: 'my-invitations',
        link: '/main/my-invitations',
        available: true,
      },
      {
        title: t('LayoutMenu.Chat'),
        grayIcon: GrayChatIcon,
        whiteIcon: GrayChatIcon,
        activeMenu: 'chat',
        link: '/chat',
        available: false,
      },
      {
        title: t('LayoutMenu.Title_Settings'),
        grayIcon: GraySettings,
        whiteIcon: WhiteSettings,
        activeMenu: 'settings',
        link: '/main/settings',
        available: true,
      },
    ],
  };

  const renderMenu =
    highPosition
      ? layoutMenu.highPosition
      : isVisitor
      ? layoutMenu.visitor
      : layoutMenu.employee;

  return {
    handleChange,
    CurrentUser,
    setExpanded,
    renderMenu,
    expanded,
    sx,
  };
};
