import React, { useEffect } from 'react';
import { SearchInput } from 'Components/AuthComponents/AuthInputs';
import useRoleHooks from 'Routers/role-hooks';
import { useAppPathsHooks } from 'Components/BreadCrumb/breadCrumb.hooks';
import useUserProfileHooks from './userProfile.hooks';
import EmployeeInfo from 'Pages/EmployeeProfile/employeeInfo';
import BreadCrumbs from 'Components/BreadCrumb/breadCrumbs';
import {
  ButtonTypes,
  HeaderTypes,
  UserProfilePages,
} from 'Components/interfaces';
import {
  ChangePasswordForm,
  EditUserInfoForm,
} from 'Components/AuthComponents/AuthForms';
import { IEditUserInfoProps } from 'Components/AuthComponents/AuthForms/EditUserInfo/editUserInfoForm';
import { CustomModal, Header, Loading, MobileNavDrawer } from 'Components';
import useMobileNavDrawerHooks from 'Components/MobileNavDrawer/mobileNavDrawer-hooks';
import Button from 'Components/Button/button';
import { useCustomModalHooks } from 'Components/CustomModal/customModal.hooks';
import { BlueLockSVG, EditSVG, MenuSVG } from 'Assets';
import EmployeeImage from 'Assets/Images/Employee.png';
import styles from './userProfile.module.scss';
import { getMe } from 'Store/Slices/authSlice';

const UserProfile: React.FC = () => {
  const {
    t,
    largeScreen,
    UserData,
    isSuccess,
    isLoading,
    editLoading,
    currentPage,
    changePasswordLoading,
    setCurrentPage,
    handleSubmit,
    onEditSubmit,
    onChangePasswordSubmit,
    editFormProps,
    changePasswordProps,
    dispatch,
  } = useUserProfileHooks();
  const { currentRole } = useRoleHooks();
  const { open, setOpen, onDrawerClose } = useMobileNavDrawerHooks();
  const { openModal, handleOpen, handleClose } = useCustomModalHooks();
  const { pathsToRender } = useAppPathsHooks();

  useEffect(() => {
    isSuccess && setCurrentPage(UserProfilePages.Main);
    dispatch(getMe());
  }, [isSuccess]);

  return (
    <div className={styles.container}>
      {largeScreen ? (
        <div className={styles.headerContainer}>
          <div className={styles.breadCrumbBox}>
            <BreadCrumbs data={pathsToRender} />
            <SearchInput />
          </div>
        </div>
      ) : (
        <div className={styles.mobileBox}>
          <Header
            type={HeaderTypes.Navigation}
            navTitle={`${UserData?.data?.name} ${UserData?.data?.surname}`}
            icon={MenuSVG}
            iconOnClick={() => {
              currentPage === 'main'
                ? setOpen(true)
                : setCurrentPage(UserProfilePages.Main);
            }}
          />
          <MobileNavDrawer open={open} onClose={onDrawerClose} />
        </div>
      )}

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {currentPage === 'main' && (
            <div className={styles.userBox}>
              <h2 className={styles.title}>
                {`${UserData?.data?.name} ${UserData?.data?.surname}`}
              </h2>
              <div className={styles.userProfile}>
                <div className={styles.userInfo}>
                  <EmployeeInfo
                    type='user'
                    image={UserData?.data?.avatar}
                    role={currentRole}
                    name={`${UserData?.data?.name} ${UserData?.data?.surname}`}
                    email={UserData?.data?.email}
                  />
                </div>
                <div className={styles.buttonBox}>
                  <div
                    className={styles.editButtons}
                    onClick={() => setCurrentPage(UserProfilePages.Edit)}
                  >
                    <img src={EditSVG} alt='editIcon' />
                    <span>{t('Edit_User.Edit')}</span>
                  </div>
                  <div
                    className={styles.editButtons}
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    <img src={BlueLockSVG} alt='editIcon' />
                    {t('Edit_User.Change_Password')}
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentPage === 'edit' && (
            <form onSubmit={handleSubmit(onEditSubmit)}>
              <div className={styles.userBox}>
                <h2 className={styles.title}>{t('Edit_User.Edit_Profile')}</h2>
                <div className={styles.userEditBox}>
                  <div className={styles.userEdit}>
                    <div className={styles.userImageBox}>
                      <img
                        src={
                          UserData?.data?.avatar
                            ? `${process.env.REACT_APP_API_KEY}${UserData?.data?.avatar}`
                            : EmployeeImage
                        }
                        className={styles.userImage}
                        alt='Employee'
                      />
                    </div>
                    <EditUserInfoForm
                      data={editFormProps as IEditUserInfoProps}
                    />
                  </div>
                  <div className={styles.buttonContent}>
                    <Button
                      title={t('Aplication_Page.Button')}
                      type='button'
                      buttonStyle={styles.withoutBtn}
                      buttonTitleStyle={styles.withoutBtnTitle}
                      buttonType={ButtonTypes.WithoutBg}
                      onClick={() => {
                        setCurrentPage(UserProfilePages.Main);
                      }}
                    />
                    <Button
                      type='submit'
                      buttonTitleStyle={styles.BtnTitle}
                      title={t('Edit_User.Save')}
                      buttonType={ButtonTypes.Primery}
                      isLoading={editLoading}
                      buttonStyle={styles.btn}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
          <CustomModal
            open={openModal}
            title={t('Edit_User.Change_Password')}
            loading={changePasswordLoading}
            buttonTitle={t('Edit_User.Change')}
            onClick={handleSubmit(onChangePasswordSubmit)}
            handleClose={handleClose}
            dialogClassname={'settingsModal'}
          >
            <ChangePasswordForm data={changePasswordProps} />
          </CustomModal>
        </>
      )}
    </div>
  );
};

export default UserProfile;
