import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { t } from "i18next";
import styles from "./bottomLink.module.scss";

interface IBottomLink {
  text?: string;
  linkTitle?: string;
  link?: string;
  onClick?: () => void;
}

const BottomLink: React.FC<IBottomLink> = (props) => {
  const { text, link, linkTitle, onClick } = props;
  return (
    <div className={styles.container}>
      <p> {text} </p>
      {link && <Link to={link}>{linkTitle}</Link>}
      {onClick && (
        <a
          className={styles["send-code-again"]}
          onClick={() => {
            onClick();
            toast.success(t("Second_page.Resend_Successfully"));
          }}
        >
          {linkTitle}
        </a>
      )}
    </div>
  );
};
export default BottomLink;
