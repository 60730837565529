import React from 'react';
import useEmployeeSettigsHooks from './employeeProperties-hooks';
import EmployeePropertiesLarge from './Screens/employeePropertiesLarge';
import EmployeePropertiesMobile from './Screens/employeePropertiesMobile';


const EmployeeSettings: React.FC = () => {
    const {
        propertyTypes,
        currentTemplate,
        properties,
        total,
        activePage,
        checkedType,
        expanded,
        modal,
        DeleteLoading,
        loading,
        largeScreen,
        openDrawer,
        isFormHidden,
        setActivePage,
        navigate,
        onOpenModal,
        onCloseModal,
        handleAccordion,
        onTypeSelect,
        onTemplateDelete,
        createTemlateConfigForm,
        onOpenDrawer,
        onCloseDrawer,
    } = useEmployeeSettigsHooks();

    return (
        <>
            {largeScreen
                ?
                <EmployeePropertiesLarge
                    properties={properties}
                    modal={modal}
                    expanded={expanded}
                    checkedType={checkedType}
                    loading={loading}
                    currentTemplate={currentTemplate}
                    total={total}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    DeleteLoading={DeleteLoading}
                    propertyTypes={propertyTypes}
                    onTypeSelect={onTypeSelect}
                    onOpenModal={onOpenModal}
                    onCloseModal={onCloseModal}
                    handleAccordion={handleAccordion}
                    onTemplateDelete={onTemplateDelete}
                    createTemlateConfigForm={createTemlateConfigForm}
                />
                :
                <EmployeePropertiesMobile
                    properties={properties}
                    modal={modal}
                    expanded={expanded}
                    checkedType={checkedType}
                    loading={loading}
                    currentTemplate={currentTemplate}
                    total={total}
                    activePage={activePage}
                    isFormHidden={isFormHidden}
                    setActivePage={setActivePage}
                    DeleteLoading={DeleteLoading}
                    propertyTypes={propertyTypes}
                    onTypeSelect={onTypeSelect}
                    onOpenModal={onOpenModal}
                    onCloseModal={onCloseModal}
                    handleAccordion={handleAccordion}
                    onTemplateDelete={onTemplateDelete}
                    createTemlateConfigForm={createTemlateConfigForm}
                    openDrawer={openDrawer}
                    onOpenDrawer={onOpenDrawer}
                    onCloseDrawer={onCloseDrawer}
                    navigate={navigate}
                />
            }
        </>
    )
}

export default EmployeeSettings

