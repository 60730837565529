import { InputField } from 'Components/AuthComponents/AuthInputs';
import { t } from 'i18next';
import {
  Control,
  Controller,
  FieldValues,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import MediaPicker from 'Components/AuthComponents/MediaPicker/mediaPicker';
import Button from 'Components/Button/button';
import { ButtonTypes } from 'Components/interfaces';
import styles from './groupChat.module.scss';
import { IGroupChatValues } from 'Pages/interfaces';

interface IProps {
  setShow: (val: string) => void;
  handleSubmit: UseFormHandleSubmit<FieldValues | IGroupChatValues>;
  onSubmit: SubmitHandler<IGroupChatValues | FieldValues>;
  control: Control<IGroupChatValues | FieldValues, any>;
  register: UseFormRegister<FieldValues | IGroupChatValues>;
}

const CreateGroupChat: React.FC<IProps> = (props) => {
  const { handleSubmit, onSubmit, control, register, setShow } = props;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.mediaPickerContainer}>
          <div className={styles.mediaPicker}>
            <Controller
              name='image'
              control={control}
              render={({ field: { onChange } }) => {
                return <MediaPicker id='image' onChange={onChange} />;
              }}
            />
          </div>
        </div>
        <InputField
          type='text'
          id='groupName'
          label={t('Chat.group_chat_name')}
          register={register}
          registerName='groupName'
          inputStyle={styles.inputField}
        />
        <div className={styles['buttons-container']}>
          <Button
            title={t('Aplication_Page.Button')}
            onClick={() => setShow('chatMembers')}
            buttonType={ButtonTypes.Primery}
          />
          <Button
            type='submit'
            title={t('Groups.Create')}
            buttonType={ButtonTypes.Primery}
          />
        </div>
      </form>
    </div>
  );
};

export default CreateGroupChat;
