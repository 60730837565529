import React, { useEffect, useState } from 'react';
import useRoleHooks from 'Routers/role-hooks';
import { useAppPathsHooks } from 'Components/BreadCrumb/breadCrumb.hooks';
import UseEmployeesHooks from 'Components/Employees/employees.hooks';
import { SearchInput } from 'Components/AuthComponents/AuthInputs';
import BreadCrumbs from 'Components/BreadCrumb/breadCrumbs';
import { useGeneralHooks } from 'Pages/general.hooks';
import { HeaderTypes } from 'Components/interfaces';
import { ButtonTypes } from 'Components/interfaces';
import Button from 'Components/Button/button';
import { EmployeeList, MobileNavDrawer } from 'Components';
import { Header } from 'Components';
import useMobileNavDrawerHooks from 'Components/MobileNavDrawer/mobileNavDrawer-hooks';
import { MenuSVG } from 'Assets';
import styles from './employees.module.scss';
import { IAllUsersResp } from 'Services/responce_types';

const Employees: React.FC = () => {
  const { userList, page, setPage, loading } = UseEmployeesHooks();
  const { pathsToRender } = useAppPathsHooks();
  const [filteredUsers,setFilteredUsers] = useState<IAllUsersResp>(userList!)
  const { navigate, t, largeScreen } = useGeneralHooks();
  const { hasHighAccess } = useRoleHooks();
  const { open, setOpen, onDrawerClose } = useMobileNavDrawerHooks();

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, []);

  useEffect(() => {
   userList !== undefined
   && 
   setFilteredUsers(userList)
  }, [userList]);

  return (
    <div className={styles.container}>
      {largeScreen ? (
        <div>
          <div className={styles.searchContainer}>
            <BreadCrumbs data={pathsToRender} />
            <SearchInput />
          </div>
          <div className={styles.textbtn}>
            <p className={styles.emptext}>{t('Employees.Header')}</p>
            <div className={styles.btn}>
              {
                hasHighAccess &&
                <Button
                  buttonType={ButtonTypes.Primery}
                  buttonStyle='width: 28px;'
                  title={t('Employees.Add_Employee_Title')}
                  type='submit'
                  onClick={() => navigate('AddEmployee')}
                />
              }
            </div>
          </div>
          <EmployeeList
            userList={filteredUsers}
            loading={loading}
            page={page}
            setPage={setPage}
            onClick={(id) => navigate(`/main/employees/${id}`)}
          />
        </div>
      ) : (
        <div className={styles.mobileContainer}>
          <div>
            <Header
              type={HeaderTypes.Navigation}
              navTitle={t('Employees.Header')}
              icon={MenuSVG}
              iconOnClick={() => setOpen(!open)}
            />
            <MobileNavDrawer open={open} onClose={onDrawerClose} />
            <div className={styles.itemcontainer}>
              <EmployeeList
                userList={filteredUsers}
                loading={loading}
                page={page}
                setPage={setPage}
                onClick={(id) => navigate(`/main/employees/${id}`)}
              />
            </div>
          </div>
          <div className={styles.btn}>
            {hasHighAccess && <Button
              buttonType={ButtonTypes.Primery}
              buttonStyle='width: 28px;'
              title={t('Employees.Add_Employee_Title')}
              type='submit'
              onClick={() => navigate('AddEmployee')}
            />}
          </div>
        </div>
      )}
    </div>
  );
};

export default Employees;
