import React, { Dispatch, SetStateAction } from 'react';
import { Controller } from 'react-hook-form';
import {
  AutoComplete,
  InputField,
  TextArea,
} from 'Components/AuthComponents/AuthInputs';
import Button from 'Components/Button/button';
import NoData from 'Components/NoData/noData';
import { ButtonTypes } from 'Components/interfaces';
import { useDismissalHooks } from './dismissal-hooks';
import { useGeneralHooks } from 'Pages/general.hooks';
import Arrow from 'Assets/Icons/arrow_back.svg';
import styles from '../appliacationItems.module.scss';

interface IDismissalForm {
  onSubmit: (data: any) => void;
  setShowDataTable?: Dispatch<SetStateAction<boolean>>;
}
const DismissalForm: React.FC<IDismissalForm> = (props) => {
  const { t, largeScreen, mobileScreen } = useGeneralHooks();
  const { onSubmit, setShowDataTable } = props;
  const {
    watch,
    errors,
    control,
    register,
    isLoading,
    currentRole,
    employees,
    minDate,
    handleSubmit,
  } = useDismissalHooks();
  const employeeList = employees?.result.map((item) => {
    return {
      id: item.userId,
      name: `${item?.user?.name} ${item?.user?.surname}`,
    };
  });

  return (
    <div className={styles.container}>
      {employees?.result.length! > 0 ? (
        <>
          <div className={styles.box}>
            <div className={styles.header}>
              {!largeScreen && (
                <div className={styles.title}>
                  {t('Aplication_Page.Tab_Labels.Release')}
                </div>
              )}
              <div className={styles.describtion}>
                {t('Aplication_Page.Desmissal.Description')}
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.formBox}>
              <div style={{ width: '80%' }}>
                <div className={styles.form} id='dismissalForm'>
                  {currentRole === 'HR' || currentRole === 'CEO' ? (
                    <Controller
                      control={control}
                      name='employee'
                      rules={{
                        validate: () =>
                          watch('employee') !== undefined ||
                          t('Input_Errors.Required'),
                      }}
                      render={({ field: { onChange, name, value } }) => {
                        return (
                          <AutoComplete
                            value={value}
                            name={name}
                            onChange={onChange}
                            id='employeeId'
                            data={employeeList}
                            label={t('Aplication_Page.Desmissal.Employee')}
                            placeholder={t(
                              'Aplication_Page.Desmissal.Employee_placeholder'
                            )}
                            error={errors.employee}
                          />
                        );
                      }}
                    />
                  ) : (
                    <InputField
                      type='text'
                      minDate={minDate}
                      disabled={true}
                      id='worker'
                      label={t('Aplication_Page.Desmissal.Employee')}
                      register={register}
                      registerName='employee'
                      inputStyle={styles.inputField}
                    />
                  )}
                  <InputField
                    type='date'
                    minDate={minDate}
                    id='endDate'
                    label={t('Aplication_Page.Desmissal.Date')}
                    register={register}
                    registerName='start_date'
                    error={errors.start_date}
                    validation={(value) =>
                      value >= minDate || t('Input_Errors.Invalid_Date')
                    }
                    inputStyle={
                      mobileScreen ? styles.inputHalfWidth : styles.inputField
                    }
                  />
                </div>
                <div>
                  <TextArea
                    label={t('Aplication_Page.Desmissal.Reason')}
                    placeholder={t(
                      'Aplication_Page.Desmissal.Textarea_PlaceHolder'
                    )}
                    register={register}
                    registerName='grounds'
                    id='dismissal'
                    error={errors.grounds}
                    inputStyle={styles.dismissalField}
                    rows={5}
                  />
                </div>
              </div>
            </form>
            <div className={styles.buttonContent}>
              <Button
                title={t('Aplication_Page.Button')}
                type='button'
                buttonStyle={styles.withoutBtn}
                buttonTitleStyle={styles.withoutBtnTitle}
                buttonType={ButtonTypes.WithoutBg}
                onClick={() => {
                  setShowDataTable!(true);
                }}
              />
              <Button
                type='submit'
                onClick={handleSubmit(onSubmit)}
                rightIcon={Arrow}
                buttonTitleStyle={styles.BtnTitle}
                isLoading={isLoading}
                title={t('Aplication_Page.Desmissal.Button_Apply')}
                buttonType={ButtonTypes.ButtonWithIcon}
                buttonStyle={styles.btn}
              />
            </div>
          </div>
        </>
      ) : (
        <NoData withButton={false} title={t('Toastify_error.noEmployee')} />
      )}
    </div>
  );
};

export default DismissalForm;
