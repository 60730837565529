import { FieldValues } from 'react-hook-form';
import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './API';
import {
  IResponse,
  IChangePassowrdResp,
  IForgetPassword,
  IChangeUserDataResp,
} from './responce_types';

import {
  CreateCompanyFormValues,
  LoginFormValues,
  RegisterFormValues,
  RegisterResponse,
} from 'Pages/interfaces';
import {
  INewPassword,
  ISendCodeToEmailResp,
  IChangePassword,
  InviteRequestData,
  IChangeUserInfoData,
  IChangeUserAvatar,
  IChangeEmail,
} from 'Components/interfaces';

export const authApi = createApi({
  reducerPath: 'authApi',
  tagTypes: ['Auth', 'User'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_KEY,
  }),
  endpoints: (builder) => ({
    login: builder.mutation<LoginFormValues, FieldValues>({
      query: (credentialsLogin) => ({
        url: '/users/login',
        method: 'POST',
        data: credentialsLogin,
      }),
    }),
    registerQuery: builder.mutation<RegisterResponse, RegisterFormValues>({
      query: (credentialsRegister) => ({
        url: '/users/register',
        method: 'POST',
        data: credentialsRegister,
      }),
    }),
    sendCodeToEmail: builder.mutation<ISendCodeToEmailResp, FieldValues>({
      query: (email) => ({
        url: '/users/send-code',
        method: 'POST',
        data: email,
      }),
    }),
    forgetPassword: builder.mutation<any, FieldValues>({
      query: (data: IForgetPassword) => ({
        url: '/users/forget-password',
        method: 'POST',
        data: data,
      }),
    }),
    resetPassword: builder.mutation<INewPassword, FieldValues>({
      query: (newPassword) => ({
        url: `/reset`,
        method: 'PUT',
        data: newPassword,
      }),
    }),
    createCompany: builder.mutation<CreateCompanyFormValues, FieldValues>({
      query: (companyFields) => ({
        url: '/company',
        method: 'POST',
        data: companyFields,
      }),
      invalidatesTags: ['User'],
    }),
    getActivities: builder.query<IResponse, void>({
      query: () => ({
        url: '/sphere-of-activities/100/0',
        method: 'GET',
      }),
    }),
    getTaxTypes: builder.query<IResponse, void>({
      query: () => ({
        url: '/types-of-tax/100/0',
        method: 'GET',
      }),
    }),
    getOrganizationTypes: builder.query<IResponse, void>({
      query: () => ({
        url: '/org-legal-types/100/0',
        method: 'GET',
      }),
    }),
    getRoles: builder.query<IResponse, void>({
      query: () => ({
        url: '/roles/100/0',
        method: 'GET',
      }),
    }),
    getCities: builder.query<IResponse, void>({
      query: () => ({
        url: '/cities/1000/0',
        method: 'GET',
      }),
    }),
    changeUserData: builder.mutation<IChangeUserDataResp, FieldValues>({
      query: (changeUserData: IChangeUserInfoData) => ({
        url: `/user/${changeUserData.id}`,
        method: 'PUT',
        data: changeUserData.data,
      }),
      invalidatesTags: ['User'],
    }),
    changeUserAvatar: builder.mutation<IChangeUserDataResp, FieldValues>({
      query: (changeUserAvatar: IChangeUserAvatar) => ({
        url: `/users/update_avatar/${changeUserAvatar.id}`,
        method: 'PUT',
        data: { avatar: changeUserAvatar.avatar },
      }),
      invalidatesTags: ['User'],
    }),

    changePassword: builder.mutation<IChangePassowrdResp, FieldValues>({
      query: (changePasswordData: IChangePassword) => ({
        url: '/users/change-password',
        method: 'POST',
        data: changePasswordData,
      }),
    }),
    changeEmail: builder.mutation<IChangePassowrdResp, FieldValues>({
      query: (changeEmailData: IChangeEmail) => ({
        url: '/users/change-email',
        method: 'POST',
        data: changeEmailData,
      }),
    }),
    inviteRequest: builder.mutation<InviteRequestData[], any>({
      query: (inviteData) => ({
        url: '/users/invite',
        method: 'POST',
        data: { invites: inviteData },
      }),
    }),
    verifyCode: builder.mutation<any, any>({
      query: (data) => ({
        url: '/users/verify_code',
        method: 'POST',
        data: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterQueryMutation,
  useSendCodeToEmailMutation,
  useResetPasswordMutation,
  useCreateCompanyMutation,
  useChangePasswordMutation,
  useGetActivitiesQuery,
  useGetTaxTypesQuery,
  useGetOrganizationTypesQuery,
  useGetRolesQuery,
  useGetCitiesQuery,
  useForgetPasswordMutation,
  useInviteRequestMutation,
  useChangeUserAvatarMutation,
  useChangeUserDataMutation,
  useVerifyCodeMutation,
} = authApi;
