import { useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { RootState, useAppSelector } from 'Store/store';
import { useGoogleSignInMutation } from 'Services/socialLoginApi';
import { keepSocialUser } from 'Store/Slices/socialLoginSlice';
import { INeedPassword } from 'Components/interfaces';
import { useGeneralHooks } from 'Pages/general.hooks';

const UseSocialLoginHooks = () => {
  const socialUser = useAppSelector(
    (state: RootState) => state?.socialLogin?.socialUser
  );
  const socialUserAcessToken = useAppSelector(
    (state: RootState) => state?.socialLogin?.access_token
  );
  const currentPlatform = useAppSelector(
    (state: RootState) => state?.socialLogin?.platform
  );

  const [
    signInWithGoogle,
    { data: socialSigninResponse, isSuccess: googleSignedIn, isLoading },
  ] = useGoogleSignInMutation();
  const { navigate, dispatch } = useGeneralHooks();

  useEffect(() => {
    if (socialSigninResponse?.result?.token) {
      localStorage.setItem('access-token', socialSigninResponse?.result?.token);
      navigate('/main/documents/applications/vacation');
    } else if (
      socialSigninResponse?.result?.message === 'need password' ||
      socialSigninResponse?.result?.message === 'need email'
    ) {
      navigate('/confirm-identity');
    }
  }, [socialSigninResponse]);

  useEffect(() => {
    if (googleSignedIn) {
      dispatch(keepSocialUser(socialSigninResponse));
    }
  }, [googleSignedIn, socialSigninResponse]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<INeedPassword | FieldValues>();

  const onSubmit: SubmitHandler<INeedPassword | FieldValues> = (
    data: INeedPassword | FieldValues
  ) => {
    if (data?.email) {
      signInWithGoogle({
        access_token: socialUserAcessToken,
        platform: currentPlatform,
        email: data?.email,
      });
    } else if (data?.password) {
      signInWithGoogle({
        access_token: socialUserAcessToken,
        platform: currentPlatform,
        password: data?.password,
      });
    }
  };

  return {
    signInWithGoogle,
    handleSubmit,
    onSubmit,
    register,
    errors,
    isLoading,
    socialUser,
    socialSigninResponse,
  };
};

export default UseSocialLoginHooks;
