import { Dispatch, SetStateAction, useEffect } from 'react';
import AppHistory from 'Components/Application/AppHistory/appHistory';
import { useVacationHooks } from './vacation-hooks';
import VacationForm from './vacationForm';
import { ApplicationTypes, HistoryTypes } from 'Components/interfaces';
import { AppScreenType } from 'Pages/interfaces';

export interface IApplicationComponentProps {
  ordered_by: ApplicationTypes,
  screenType?: AppScreenType,
  setScreenType?: Dispatch<SetStateAction<AppScreenType>>
}
const VacationComponent: React.FC<IApplicationComponentProps> = (props) => {
  const { ordered_by } = props;
  const {
    errors,
    isValid,
    total,
    showDataTable,
    setShowDataTable,
    handleSubmit,
    register,
    onSubmit,
    watch,
    employeeList,
    control,
    currentRole,
    availableVacationDays,
    mekumSalary
  } = useVacationHooks();

  useEffect(() => {
    setShowDataTable(true)
  }, [ordered_by]);

  return (
    <>
      {
        ordered_by === ApplicationTypes.Company_Applications
          ?
          <AppHistory
            type={HistoryTypes.Vacation}
            ordered_by={ordered_by}
            setShow={setShowDataTable}
          />
          :
          showDataTable ? (
            <AppHistory
              type={HistoryTypes.Vacation}
              ordered_by={ordered_by}
              setShow={setShowDataTable}
            />
          )
            :
            (
              <VacationForm
                isValid={isValid}
                register={register}
                errors={errors}
                total={total}
                watch={watch}
                setShowDataTable={setShowDataTable}
                onSubmit={handleSubmit(onSubmit)}
                employeeList={employeeList}
                control={control}
                role={currentRole}
                availableVacationDays={availableVacationDays}
                mekumSalary={mekumSalary?.result}
              />
            )
      }
    </>
  );
};

export default VacationComponent;
