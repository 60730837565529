import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IInitState {
  usersToConnectGroupChat: any[];
}

const initialState: IInitState = {
  usersToConnectGroupChat: [],
};

const chatSlice = createSlice({
  name: 'keepGroupChatUsers',
  initialState,
  reducers: {
    keepGroupChatUsers(state, action: PayloadAction<any>) {
      state.usersToConnectGroupChat = action.payload;
    },
  },
});

export const { keepGroupChatUsers } = chatSlice.actions;
export default chatSlice.reducer;
