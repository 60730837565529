import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { IMekumToken } from 'Pages/interfaces';
import { useCustomModalHooks } from 'Components/CustomModal/customModal.hooks';
import { useConnectMekumMutation } from 'Services/mekumApi';
import { useGeneralHooks } from 'Pages/general.hooks';
import { getMe } from 'Store/Slices/authSlice';
import { RootState, useAppSelector } from 'Store/store';

const useSettingsHooks = () => {
  const { openModal, handleOpen, handleClose } = useCustomModalHooks();
  const [connectMekum, { data: connectMekumResult, isSuccess, isLoading }] =
    useConnectMekumMutation();
  const { dispatch } = useGeneralHooks();
  const currentUser = useAppSelector(
    (state: RootState) => state?.auth?.currentUser
  );

  const mekumId = currentUser?.data?.activeCompany?.mekumId;
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IMekumToken | FieldValues>({ mode: 'all' });

  const onSubmit: SubmitHandler<IMekumToken | FieldValues> = (data) => {
    connectMekum(data);
  };

  useEffect(() => {
    if (isSuccess && connectMekumResult) {
      handleClose();
      toast.success(t('Settings.Connected_Mekum_Successfully'));
      reset();
      dispatch(getMe());
    }
  }, [isSuccess, connectMekumResult]);

  return {
    errors,
    mekumId,
    openModal,
    isLoading,
    isSuccess,
    currentUser,
    register,
    onSubmit,
    handleOpen,
    handleClose,
    handleSubmit,
  };
};

export default useSettingsHooks;
